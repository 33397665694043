import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Skeleton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useCustomMutation, useGetIdentity, useModal, useNavigation, useNotification, useOne } from "@refinedev/core";
import React, { useEffect } from 'react';

import { useTranslation } from "react-i18next";
import { LocalStorageProvider } from "shared-libs/src/providers";
import './TotalWhtex.scss';
import { NumberField } from "./fields";
import { useCurrencyStore } from "hooks/useCurrency";
import { LoadingButton } from "@mui/lab";

const mapFieldToValue = (data: { available, committed, fees_due }, name) => {
    switch (name) {
        case 'available':
            return data.available;
        case 'committed':
            return data.committed;
        case 'owned':
            return data.available + data.committed;
        default:
            return data[name]

    }
}

const dataToFields = (data: { available, committed, fees_due }, fields) => {
    return fields.reduce((acc, curr) => {
        return {...acc, [curr]: mapFieldToValue(data, curr)}
    }, {});
}

export default function TotalWhtex({
                                       data,
                                       isSuccess,
                                       fields = ["amount_held", "amount_committed", "fees_due", "balance_held", "available"],
                                       enableBuy = true,
                                       enableSell = true,
                                       title = "",
                                       showLongButtonBuyLabel = false,
                                       flexAlignment = "",
                                       fontSize = ""
                                   }) {
    const {visible: openSell, show: showSell, close: closeSell} = useModal();
    const {visible: openBuy, show: showBuy, close: closeBuy} = useModal();
    const {data: user} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    console.log(`user = `, user)
    const { open } = useNotification();
    const {create} = useNavigation();
    const {t} = useTranslation()
    const handleSellClick = () => {
        showSell()
    }
    const handleBuyClick = () => {
        showBuy()
    }
    const handleConfirmSell = () => {
        sellWht({
            url: '/bank/sell_wht',
            method: 'post',
            values: {
                bcid: LocalStorageProvider.getSessionData('bcid'),
                amount: valueToSell,
                otp
            }
        }, {
            onSuccess: () => {
                open?.({
                    message: "Request sent",
                    type: "success"
                });
                closeSell()
            }
        })
    }
    const handleConfirmBuy = () => {
        buyWht({
            url: '/bank/buy_wht',
            method: 'post',
            values: {
                bcid: LocalStorageProvider.getSessionData('bcid'),
                amount: valueToBuy,
                otp,
                reason: reasonResponse.data?.data.toString(),
            }
        }, {
            onSuccess: () => {
                open?.({
                    message: "Request sent",
                    type: "success"
                });
                closeBuy()
            }
        })
    }
    const handleOpenTicket = () => {
        create('messages')
    }

    let buttonLabel = 'BUY'

    if (showLongButtonBuyLabel) {
        buttonLabel = 'BUY WHTEXs';
    }

    const [reason, setReason] = React.useState()
    const [reasonEnabled, setReasonEnabled] = React.useState(false)
    const reasonResponse = useOne({
        resource: 'buy_wht_reason',
        id: '',
        queryOptions: {
            enabled: reasonEnabled
        }
    })
    React.useEffect(() => {
        if (openBuy && !reason) {
            setReasonEnabled(true)
        }
        if (!openBuy && reasonEnabled) {
            reasonResponse.refetch()
        }
    }, [openBuy])

    // sell methods:

    const [valueToSell, setValueToSell] = React.useState<number | undefined>();
    const [valueToBuy, setValueToBuy] = React.useState<number | undefined>();
    const [valueToSellEur, setValueToSellEur] = React.useState('');
    const [valueToBuyEur, setValueToBuyEur] = React.useState('');

    const [skipNext, setSkipNext] = React.useState(false);

    console.log("valueToSell", valueToSell)


    // WHT VALUE
    useEffect(() => {
        const convertedValue = convert(valueToSell)
        if (skipNext) {
            setSkipNext(false)
            return;
        }

        if (valueToSell && valueToSell > 0) {

            if (valueToSellEur != convertedValue.toFixed(2)) {
                setSkipNext(true);
                setValueToSellEur(convertedValue.toFixed(2))
            }
        } else {
            setSkipNext(true)
            setValueToSellEur('')
        }
    }, [valueToSell])

    useEffect(() => {
        const convertedValue = convert(valueToBuy)
        if (skipNext) {
            setSkipNext(false)
            return;
        }

        if (valueToBuy && valueToBuy > 0) {

            if (valueToBuyEur != convertedValue.toFixed(2)) {
                setSkipNext(true);
                setValueToBuyEur(convertedValue.toFixed(2))
            }
        } else {
            setSkipNext(true)
            setValueToBuyEur('')
        }
    }, [valueToBuy])



    // EUR VALUES

    useEffect(() => {
        const convertedValue = Math.round(convertFromEur(valueToSellEur, 'WHT'))
        if (skipNext) {
            setSkipNext(false)
            return;
        }

        if (valueToSellEur?.length == 0) {
            setSkipNext(true)
            setValueToSell(undefined)
            return
        }

        if (valueToSell !== convertedValue) {
            setSkipNext(true)
            setValueToSell(convertedValue);
        }


    }, [valueToSellEur])
    useEffect(() => {
        const convertedValue = Math.round(convertFromEur(valueToBuyEur, 'WHT'))
        if (skipNext) {
            setSkipNext(false)
            return;
        }

        if (valueToBuyEur?.length == 0) {
            setSkipNext(true)
            setValueToBuy(undefined)
            return
        }

        if (valueToBuy !== convertedValue) {
            setSkipNext(true)
            setValueToBuy(convertedValue);
        }
    }, [valueToBuyEur])

    const [otp, setOtp] = React.useState('')

    const { mutate: sellWht, isLoading: isLoadingSell } = useCustomMutation({})
    const { mutate: buyWht, isLoading: isLoadingBuy } = useCustomMutation({})

    const { getValue, convert, convertFromEur } = useCurrencyStore()





    return (
        <React.Fragment>

            <Card sx={{mb: 2}}>
                <CardHeader className="panel-header" title="WHTEXs">
                    {title && <Box className="whtex-total-title"><Typography>{title}</Typography></Box>}
                </CardHeader>
                <CardContent>
                    <Box className={flexAlignment} sx={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                        <Box className="tot-whtx-12" sx={{display: 'flex', flex: 1, justifyContent: 'space-evenly', gap: '20px'}}>
                            {Object.entries(dataToFields(data?.data ?? {}, fields)).map((value: [any, any]) => {
                                return (
                                    (fields.includes('all') || (fields.includes(value[0]))) &&
                                    <Box sx={{textAlign: 'center'}} key={value[0]}>
                                        <Tooltip className={`column-label ${fontSize}`} sx={{pb: 1}}
                                                 title={t('whtex.' + value[0] + "_tooltip")}
                                                 placement="top">
                                            <Box className="whtex-total-box-title">{t('whtex.' + value[0])}</Box>
                                        </Tooltip>
                                        <Box className={`whtex-total-box-value ${fontSize}`}>
                                                {!isSuccess ? <Skeleton /> : <Typography className={`${fontSize}`}>{getValue(value[1] || 0)}</Typography>}
                                        </Box>
                                    </Box>
                                )
                            })
                            }
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', gap: '20px'}}>
                            {enableSell ? <Box className={`whtex-total-box-action ${fontSize}`}><Button variant='sell'
                                                                                          onClick={handleSellClick}>SELL</Button></Box> : <></>}
                            {enableBuy ? <Box className="whtex-total-box-action"><Button variant='buy'
                                                                                         onClick={handleBuyClick}>{buttonLabel}</Button></Box> : <></>}
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <Dialog open={openSell} onClose={closeSell} className={'whtex-total-modal sell'}>
                <DialogTitle>WHTEXCH PLATFORM  | NEW WHT SELL REQUEST</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" variantMapping={{body1: "p"}}>
                        <p>Dear estimated Participant,</p>
                        <p></p>
                        <p>The registered IBAN for your account is: <strong>{user?.profile_data?.iban}</strong></p>
                        <p></p>
                        <strong>Transaction Details</strong>
                    </Typography>

                    <Box className='whtex-total-modal-sell-table'>
                        <Grid justifyContent={'center'} alignContent={'center'} className='' container>
                            <Grid className='' item xs={2}>WHTEXs</Grid>
                            <Grid item xs={3}>

                                <TextField variant={"outlined"} size={"small"}
                                    inputProps={{
                                        type: 'number',
                                        pattern: '[0-9]*',
                                    }}
                                    value={valueToSell ?? ''}
                                    onChange={(event) => {
                                        const value = parseInt(event?.target?.value)
                                        const valueToSet = isNaN(value) ? undefined : Math.max(value, 0)
                                        setValueToSell(valueToSet)

                                    }} /></Grid>

                        </Grid>

                        <Grid style={{ marginTop: '10px' }} justifyContent={'center'} alignContent={'center'} className='' container>
                            <Grid className='' item xs={2}>EUR</Grid>
                            <Grid item xs={3}>

                                <TextField variant={"outlined"} size={"small"}
                                    inputProps={{
                                        type: 'number',
                                        step: 0.01,
                                        pattern: '[0-9\,\.]*',
                                    }}

                                    onChange={(event) => {
                                        setValueToSellEur(event?.target?.value)
                                    }}
                                    value={valueToSellEur} /></Grid>

                        </Grid>
                        <Grid style={{ marginTop: '10px' }} justifyContent={'center'} alignContent={'center'} className='' container>
                            <Grid className='' item xs={2}>OTP</Grid>
                            <Grid className='' item xs={3}>
                                <TextField variant={"outlined"} size={"small"}
                                    inputProps={{
                                        type: 'number',
                                        pattern: '[0-9]*',
                                    }}
                                    onChange={(event) => {
                                        event?.target?.value && setOtp(event?.target?.value)
                                    }} />
                            </Grid>

                        </Grid>
                    </Box>
                    <Typography variant="body2" variantMapping={{body1: "p"}}>
                        Kindly click "Confirm" in order to proceed with the sell request. Your balance will be credited to the registered IBAN. If you wish to amend the registered IBAN, kindly click "Open Ticket" to start the procedure. Please note that the change of IBAN is not authomatic and it is subject to WHTEXCH approval.                     </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <LoadingButton variant="contained" onClick={handleConfirmSell} loading={isLoadingSell} disabled={isLoadingSell} color={'info'}>Confirm</LoadingButton>
                        <Button onClick={handleOpenTicket} color={'info'}>Open Ticket</Button>
                    </Box>
                    <Box>
                        <Button onClick={closeSell} variant="dialogClose">Close</Button>
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog open={openBuy} onClose={closeBuy} className={'whtex-total-modal buy'}>
                <DialogTitle>WHTEXCH PLATFORM | NEW WHT BUY REQUEST</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" variantMapping={{body1: "p"}}>
                        <p>Dear estimated Participant,</p>
                        <p></p>
                        <p>Kindly proceed with the bank transfer in accordance with the instructions received by email using the following Bank Transfer Reference: {reasonResponse?.data?.data?.toString()}</p>
                        <p>Transaction Details:</p>
                    </Typography>
                    <Box className='whtex-total-modal-buy-table'>
                        <Grid justifyContent={'center'} alignContent={'center'} className='' container>

                            <Grid className='' item xs={2}>WHTEXs</Grid>
                            <Grid item xs={3}>

                                <TextField variant={"outlined"} size={"small"}
                                           inputProps={{
                                               type: 'number',
                                               pattern: '[0-9]*',
                                           }}
                                    value={valueToBuy ?? ''}
                                    onChange={(event) => {
                                        const value = parseInt(event?.target?.value)
                                        const valueToSet = isNaN(value) ? undefined : Math.max(value, 0)
                                        setValueToBuy(valueToSet)
                                    }} />
                            </Grid>
                        </Grid>


                        <Grid style={{ marginTop: '10px' }} justifyContent={'center'} alignContent={'center'} className='' container>

                            <Grid className='' item xs={2}>EUR</Grid>
                            <Grid item xs={3}>

                                <TextField variant={"outlined"} size={"small"}
                                    inputProps={{
                                        type: 'number',
                                        step: 0.01,
                                        pattern: '[0-9\.\,]*',
                                    }}
                                    onChange={(event) => {
                                        setValueToBuyEur(event?.target?.value)
                                    }}
                                    value={valueToBuyEur}
                                />
                            </Grid>
                        </Grid>



                        <Grid style={{ marginTop: '10px' }} justifyContent={'center'} alignContent={'center'} className='' container>

                            <Grid className='' item xs={2}>OTP</Grid>
                            <Grid item xs={3}>

                                <TextField variant={"outlined"} size={"small"}
                                    inputProps={{
                                        type: 'number',
                                        pattern: '[0-9]*',
                                    }}
                                    onChange={(event) => {
                                        setOtp(event?.target?.value)
                                    }} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Typography variant="body2" variantMapping={{body1: "p"}}>
                        Kindly click "Confirm" in order to proceed with the buy request. Shall you need any assistance, please click on "Open Ticket"
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <LoadingButton variant="contained" onClick={handleConfirmBuy} loading={isLoadingBuy} disabled={isLoadingBuy} color={'info'}>Confirm</LoadingButton>
                        <Button onClick={handleOpenTicket} color={'info'}>Open Ticket</Button>
                    </Box>
                    <Box>
                        <Button onClick={closeBuy} variant="dialogClose">Close</Button>
                    </Box>
                </DialogActions>
            </Dialog>

        </React.Fragment>

    )
}