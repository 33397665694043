import { Box, Skeleton, Stack, Card, CardHeader, CardContent } from "@mui/material";
import './TradeStats.scss'
import React from "react";
import {NumberField} from "../fields";
import { useCurrencyStore } from "hooks/useCurrency";

export default function TradeStats({loading, data}) {
    const { getValue } = useCurrencyStore()
    return (
        <Card sx={{mb: 2}}>
            <CardHeader className="panel-header" title="Trade State"/>
            <CardContent sx={{pl: '20px', pr: '20px'}}>
                <Box className={"trade-stats"}>
                    <Stack className='trade-stats-row-container'>
                        <Box className='trade-stats-row-first-second'>
                            <Box className={"trade-stats-row first"}>
                                <Box>Highest Bid x Quantity</Box>
                                <Box>
                                    {loading ?
                                        <Skeleton /> : data?.highest_bid_price && data?.highest_bid_quantity ? <>
                                            {getValue(data?.highest_bid_price || 0)}&nbsp;x&nbsp;
                                            <NumberField display={"inline"} variant={"inherit"}
                                                value={data?.highest_bid_quantity || 0} />
                                        </> : '-'
                                    }
                                </Box>
                            </Box>
                            <Box className={"trade-stats-row second"}>
                                <Box>Lowest Ask x Quantity</Box>
                                <Box>
                                    {loading ?
                                        <Skeleton /> : data?.lowest_ask_price && data?.lowest_ask_quantity ? <>
                                            {getValue(data?.lowest_ask_price || 0)}&nbsp;x&nbsp;<NumberField
                                            display={"inline"} variant={"inherit"}
                                                value={data?.lowest_ask_quantity} />
                                        </> : '-'
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box className='trade-stats-row-third-fourth'>
                            <Box className={"trade-stats-row third"}>
                                <Box>Daily Volume</Box>
                                <Box>{loading ? <Skeleton/> :
                                    <NumberField variant={"inherit"} value={data?.daily_volume || 0}/>}</Box>
                            </Box>
                            <Box className={"trade-stats-row fourth"}>
                                <Box>Average Daily Volume</Box>
                                <Box>{loading ? <Skeleton/> :
                                    <NumberField variant={"inherit"} value={data?.average_daily_volume || 0}/>}</Box>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}
