import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { CircularDeterminate, DateField, List, NumberField, useDataGrid } from "@refinedev/mui";
import React, { useEffect } from "react";

import { Done, DoneAll, RemoveDone } from '@mui/icons-material';
import { CircularProgress, IconButton, Typography } from "@mui/material";
import { useCustomMutation, useNotification } from "@refinedev/core";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { User } from "shared-libs/src/interfaces";
import { useCurrencyStore } from "hooks/useCurrency";
import { isLabeledStatement } from "typescript";


const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.value} style={{ marginBottom: 0 }} />;
}

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}

const BCID_START_SEQUENCE = "KZ"
const BCID_END_SEQUENCE = "X"

const LENGTH = 8
const PART_LENGTH = 4

export const renderSeedFromBcid = (seed, bcid: string) => {

    console.log(`seed = ${seed}, bcid = ${bcid}`)

    const sid = bcid.padStart(LENGTH, "0")
    const first_bcid_char = sid.slice(0, PART_LENGTH)
    const first_last_char = sid.slice(-PART_LENGTH)

    return `${BCID_START_SEQUENCE}-${seed}-${first_bcid_char}-${first_last_char}-${BCID_END_SEQUENCE}`
}


export const BankAuditLog = () => {

    const { getValue } = useCurrencyStore()

    const { open } = useNotification()

    useEffect(() => {
        document.title = 'Whtexch - Bank';
    }, []);
    const { dataGridProps, tableQueryResult: { refetch } } = useDataGrid({
        pagination: {
            mode: "off"
        },
        resource: 'bank_logs',
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            queryKeyHashFn: () => `bank_audit_log`
        },
    });

    const columns = React.useMemo<GridColumns<{
        bcid_user_data?: User,
        audit_type: string,
        rejected: string,
        is_executed: number,
        profile_data: string,
        seed: string,
        bcid: number,
    }>>(() => [
        { field: "audit_id", headerName: "ID", flex: 0.5, headerAlign: "left" },
        { field: "user", headerName: "User", flex: 1, valueGetter: ({ row }) => `${row.bcid_user_data?.profile_data?.firstname} ${row.bcid_user_data?.profile_data?.lastname}` },
        { field: "bcid", headerName: "BCID", flex: 0.5 },
        { field: "seed", headerName: "Seed", flex: 1, valueGetter: ({ row: { seed, bcid } }) => seed && bcid ? renderSeedFromBcid(seed, `${bcid}`) : '-' },
        { field: "movement_type", headerName: "Type", flex: 0.5 },
        { field: 'audit_status', headerName: "Status", flex: 1 },
        { field: "timestamp", headerName: "Date & time (UTC)", type: "date", flex: 1.5, renderCell: dateRender },
        { field: "audit_type", headerName: "action type", flex: 1 },
        { field: "wht_amount", headerName: "Amount", flex: 1.5, renderCell: ({ value }) => { return getValue(value) } },


    ], [dataGridProps]);


    return (
        <List>
            <DataGrid getRowId={({ movement_id, audit_type, audit_id }) => `${movement_id}${audit_type}${audit_id}`}  {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};

