import { LocalStorageProvider, RequestProvider } from "shared-libs/src/providers";
import BCIDHistory from "./BCIDHistory";
import {CustomersCard} from "./CustomersCard";
import {OrdersTable} from "./OrdersTable";
import { Stack } from "@mui/material";
import React from "react";


export const CustomersShow = () => {
    return (
        <Stack>
            <CustomersCard/>
            <BCIDHistory bcid={LocalStorageProvider.getSessionData('bcid')} />
            <OrdersTable/>
        </Stack>
    )
}