import { List } from "@refinedev/mui";
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useCustomMutation } from "@refinedev/core";
import { LoadingButton } from "@mui/lab";

const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    //@ts-ignore
    a.style = "display: none";
    return function (blob, fileName) {
        // const blob = strToBlob(data, 'image/png'),// new Blob([data], { type: 'image/jpeg' }),
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());
export default function Decrypt() {

    // Upload the file to server for decrypt

    // Decrypt the file and download it to client's computer


    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setError,
        setValue
    } = useForm();


    const { mutate, isLoading } = useCustomMutation()





    return <List title={<><NoEncryptionGmailerrorredIcon />&nbsp;Decrypt</>} >
        <FormControl className='decrypt-form'>
            <Box component={"form"} onSubmit={(e) => {
                handleSubmit((e) => {
                    // e.preventDefault();
                    const { password, confirm, file } = getValues();

                    const formData = new FormData();

                    formData.append('file', file);
                    formData.append('password', password);

                    const downloadFileName = file.name?.replace('.aes', '') + '.jpg'

                    // check if file is selected
                    if (!file) {
                        setError("file", {
                            type: "validate",
                            message: "File is required"
                        })
                        return;
                    }

                    // check password and confirm are the same
                    if (password !== confirm) {
                        setError("confirm", {
                            type: "validate",
                            message: "Password and confirm must be the same"
                        })
                        return;
                    }

                    mutate({
                        url: '/utility/decrypt',
                        method: 'post',
                        values: {
                            password, file
                        },
                    }, {
                        onSuccess: (data) => {
                            saveData(data.data, downloadFileName)
                        }
                    })


                })(e)
            }}>
                <Grid>
                    <Grid padding={1}>
                        <Typography>Please use this form to decrypt the file using the password</Typography>
                    </Grid>

                        <Controller control={control} name="password" rules={{ required: true }} render={({ field }) => (

                        <Grid padding={1}><TextField fullWidth label="Password" type="password" placeholder="Insert password" {...field} /></Grid>

                        )} />
                    {errors.password && <span>This field is required</span>}

                        <Controller control={control} name="confirm" rules={{ required: true }} render={({ field }) => (
                        <Grid padding={1}><TextField fullWidth label="Confirm" type="password" placeholder="Confirm password" {...field} /></Grid>
                        )} />
                    {errors.confirm && <span>This field is required</span>}
                        <Controller control={control} name="file" rules={{ required: true }} render={({ field: { value, ...field } }) => (
                        <Grid padding={1}><TextField fullWidth placeholder="Select file" type="file" {...field} onChange={(e) => {
                                // @ts-ignore
                                field.onChange(e.target?.files[0]);
                            // @ts-ignore
                            console.log(e.target?.files[0]?.name)
                            // @ts-ignore
                            console.log(e.target?.value)
                        }} /></Grid>
                        )} />
                        {errors.file && <span>This field is required</span>}

                    <Grid padding={1}><LoadingButton loading={isLoading} disabled={isLoading} variant="contained" type="submit">Decrypt</LoadingButton></Grid>
                </Grid>
            </Box>
        </FormControl>

    </List>
}