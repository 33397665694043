import React, { useEffect, useRef, useState } from 'react'
import {ShowButton, useDataGrid} from "@refinedev/mui";
import { DataGrid, getGridStringOperators, GridColumns } from "@mui/x-data-grid";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Card,
    CardHeader,
    CardContent,
    Tooltip,
    Typography,
} from "@mui/material";

import { IAft, IAftLite, IAftLiteExtended } from 'shared-libs/src/interfaces';

import {
    useGetIdentity,
    useNavigation,
    useTranslate,
    useModal,
    useCustomMutation,
    useNotification,
} from "@refinedev/core";

import './AftPreMarket.scss'
import './AftTable.scss'
import {NumberField} from "../fields";
import { useTradeOpen } from 'components/trade/useTradeOpen';
import { useCurrencyStore } from 'hooks/useCurrency';
import Download from '@mui/icons-material/Download'
import OTPModal from 'components/OTPModal';

const TIMEOUT_EVENT = 30 * 1000

export default function AftPreMarket({balance}) {
    const { dataGridProps, tableQueryResult: { refetch, isLoading, isRefetching } } = useDataGrid<IAft>({
        resource: "premarket",
        queryOptions: {
            queryHash: 'premarket-open',
        },
        syncWithLocation: false,
        liveMode: 'auto',
        filters: {
            permanent: [
                {
                    field: "is_premarket",
                    operator: "eq",
                    value: true
                },
                {
                    field: "show_artwork",
                    operator: 'eq',
                    value: true
                },
                // { field: 'show_premarket', operator:'eq',value:true},
                { field: 'show_originator', operator: 'eq', value: true },
                { field: 'show_last_orders', operator: 'eq', value: true },
                // { field: 'show_price_history', operator:'eq',value:true},
                { field: 'show_artwork', operator: 'eq', value: true },
                { field: 'show_average_purchase_price', operator: 'eq', value: true }
                // { field: 'show_user_orders', operator:'eq',value:true},

            ]
        }
    });

    const t = useTranslate();
    const {data: user} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const {push} = useNavigation()
    const {open} = useNotification()
    const {visible, show, close} = useModal();
    const [quantity, setQuantity] = React.useState(0)
    const [inputError, setInputError] = React.useState(false)
    const [inputErrorMessage, setInputErrorMessage] = React.useState("")
    const [buyLoading, setBuyLoading] = React.useState(false)
    const [buyDisabled, setBuyDisabled] = React.useState(true)
    const [currentRecord, setCurrentRecord] = React.useState<any>()
    const [currentAmountHeld, setCurrentAmountHeld] = React.useState(-1)
    const {mutate: custom} = useCustomMutation()
    const inputElement = useRef<any>()

    const [waitingForEvent, setWaitingForEvent] = useState(false)

    useEffect(() => {
        if (waitingForEvent) {
            setTimeout(() => {
                setWaitingForEvent(false);
            }, TIMEOUT_EVENT)
        }
    }, [waitingForEvent])
    useEffect(() => {
        if (waitingForEvent) {
            // this means that while waiting for an event the event actually arrived
            // no need to wait for the event anymore
            setWaitingForEvent(false)
        }
    }, [dataGridProps.rows])


    const otpModal = useModal()
    const { isTradeOpen } = useTradeOpen()

    useEffect(() => {
        if (balance && balance?.data?.available >= 0) {
            setCurrentAmountHeld(balance?.data?.available) 
        }
    }, [balance])
    useEffect(() => {
        if (currentRecord && quantity != currentRecord?.min_token_quantity) {
            setBuyDisabled(false)
            setQuantity(currentRecord?.min_token_quantity)
        }
    }, [currentRecord])


    const handleCancel = () => {
        setBuyDisabled(false)
        setInputErrorMessage('')
        setInputError(false);
        close()
    }

    const columns = React.useMemo<GridColumns<IAftLite & IAftLiteExtended>>(
        () => [
            {
                field: "aftcontract_id",
                headerName: t("aft_id"),
                description: t("aft_id_tooltip"),
                headerAlign: "center",
                align: 'left',
                minWidth: 160,
                flex: 3,
                valueGetter: ({ row: { aftcontract_id, artwork: { work_of_ingenuity } } }) => {
                    return work_of_ingenuity || aftcontract_id;
                },
                renderCell: ({ value }) => {
                    return <Tooltip title={value}><Typography style={{ textOverflow: 'ellipsis' }}>{value}</Typography></Tooltip>
                }

            },
            {
                field: "price",
                headerName: t("price_per_unit"),
                // description: t("price_per_unit_tooltip"), NOTE: tolto su richiesta del cliente
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                renderCell: function render({ value }) {
                    return getValue(value)
                }
            },
            {
                field: "number_of_tokens",
                headerName: t("total_units_deposited"),
                // description: t("total_units_deposited_tooltip"),  NOTE: tolto su richiesta del cliente
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens } } }) => number_of_tokens,
                renderCell: function render({value}) {
                    return Number.isInteger(value) ? <NumberField value={value}/> : ""
                }
            },
            {
                field: "original_deposit_value",
                headerName: t("original_deposit_value"),
                // description: t("original_deposit_value_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens }, price } }) => {
                    return number_of_tokens * price
                },
                renderCell: function render({value}) {
                    return Number.isInteger(value) ? <NumberField value={value}/> : ""
                }
            }, 
            {
                field: "tokens_bought_by_mkt",
                headerName: t("total_qty_purchased_by_mkt"),
                description: t("total_qty_purchased_by_mkt_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens }, originator: { committed, available } } }) => {
                    return number_of_tokens - (committed + available)
                },
                renderCell: function render({value}) {
                    return Number.isInteger(value) ? <NumberField value={value}/> : ""
                }
            },
            {
                field: "tokens_residual_available",
                headerName: t("residual_qty_available"),
                description: t("residual_qty_available"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { originator: { committed, available } } }) => {
                    return (committed + available)
                },
                renderCell: function render({value}) {
                    return Number.isInteger(value) ? <NumberField value={value}/> : ""
                }
            },
            {
                field: "deposit_business_days_left",
                headerName: t("deposit_business_days_left"),
                description: t("deposit_business_days_left_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { premarket_date_limit } }) => {
                    // calculate days from premarket_date_limit
                    const deadlineDate = new Date(premarket_date_limit.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/, "$1-$2-$3 $4:$5:$6"))
                    return (((deadlineDate.getTime() - new Date().getTime()) / 1000 / 86400) >> 0)


                },
                renderCell: function render({value}) {
                    // premarket_date_limit
                    return Number.isInteger(value) ? <NumberField value={value}/> : ""
                },
            },
            {
                field: "client_number_of_tokens",
                headerName: t("own_balance"),
                headerClassName: 'table-header-bg',
                description: t("own_balance_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { client: { available } } }) => {
                    return available
                },
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "client_aft_pieces",
                headerName: t("own_units_value"),
                headerClassName: 'table-header-bg',
                description: t("own_units_value_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { client: { available }, price } }) => {
                    return (available || 0) * price
                },
                renderCell: function render({ value }) {
                    return getValue(value)
                }
            },
            {
                field: "views",
                type: "actions",
                headerName: "VIEW MASTERPIECE DETAILS",
                headerClassName: "center",
                sortable: false,
                disableColumnMenu: true,
                minWidth: 80,
                renderCell: function ({row}) {

                    const downloadButton = row?.client?.available > 0 && <ShowButton startIcon={null} variant='actionTable' color='info'
                        resource='download' recordItemId={row?.aftcontract_id}
                    ><Download /></ShowButton>


                    return <>{downloadButton}<ShowButton variant="actionTable" color="info" hideText recordItemId={row?.aftcontract_id} resource={'aft'} /></>;
                }
            },
            {
                field: "actions",
                type: "actions",
                minWidth: 80,
                renderCell: function render({row}) {
                    const isOriginator = row?.client.account_id == row?.originator?.account_id

                    return (
                        <Button disabled={isOriginator || (isTradeOpen === false)} variant={isOriginator ? 'outlined' : 'buy'} size="small" onClick={() => {
                            setCurrentRecord(row)
                            show()
                        }}>BUY</Button>
                    );
                },
            },
        ], [isTradeOpen]
    );

    const { getValue, convert } = useCurrencyStore()


    const handlerProceed = otp => {
        setBuyLoading(true)
        open?.({
            type: "success",
            message: "Processing and Waiting to be Settled"
        })

        custom({
            url: `/trading/client/aftcontract/buy`,
            method: "post",
            values: {
                aftcontract_id: currentRecord?.aftcontract_id,
                quantity: quantity,
                price_for_unit: parseInt(currentRecord?.price),
                otp
            }
        }, {
            onSuccess: (data, variables, context) => {
                open?.({
                    type: "success",
                    message: "Trasmitted and Settled"
                })
                setQuantity(0)
                setBuyLoading(false)
                const localMessage = currentRecord?.aftcontract_id != null ? "MASTERPIECE " + currentRecord?.aftcontract_id + " purchased" : "Token"
                open?.({
                    message: localMessage,
                    type: "success"
                });
                setWaitingForEvent(true)
                // !isRefetching && refetch()
                close()
            },
            onError: (error, variables, context) => {
                setBuyDisabled(false)
                setInputErrorMessage('')
                setInputError(false);
                close()
                setQuantity(0)
                setBuyLoading(false);
                close()
            },
        })
    }

    return <>
        <Card sx={{mb: '30px'}}>
            <CardHeader className="panel-header" title="Primary Market"/>
            <CardContent sx={{pl: '20px', pr: '20px'}}>
                <DataGrid
                    {...dataGridProps}
                    loading={dataGridProps.loading || isLoading || isRefetching || waitingForEvent}
                    getRowClassName={({ row }) => {
                        const isOriginator = row?.client.account_id == row?.originator?.account_id
                        return isOriginator ? 'originator-row-bg' : ''
                    }}
                    getRowId={(node) => node.aftcontract_id}
                    columns={columns}
                    disableColumnSelector={true}
                    sortingMode='client'
                    filterMode='client'
                    hideFooterPagination={true}
                    hideFooter={true}
                    className='buy'
                    headerHeight={80}
                    getRowSpacing={(params) => {
                        return {top: 4, bottom: 4}
                    }}
                    rowHeight={52}
                    sx={{
                        height: '236px',
                        border: 'none',
                        '& .MuiDataGrid-cell:not([data-field="aftcontract_id"]), & .MuiDataGrid-cell:not([data-field="aftcontract_id"]) p': {
                            fontSize: '0.8rem',
                        }
                    }}
                />
            </CardContent>
        </Card>
        <Dialog PaperProps={{ style: { maxWidth: '800px' } }} open={visible} onClose={close}>
            <DialogTitle className='price_qty_counter_title'>{`MASTERPIECE "${currentRecord?.artwork?.work_of_ingenuity || currentRecord?.aftcontract_id}"`}</DialogTitle>
            <DialogContent className='price_qty_counter_container'>
                <Grid className='price_qty_counter_grid' container spacing={2}>
                    <Grid item xs={12}>
                        <p>Dear estimated Participant,</p>
                        <p></p>
                        <p>You are requiring to buy on the primary market the Masterpiece <strong>{currentRecord?.artwork?.work_of_ingenuity || currentRecord?.aftcontract_id}</strong>.</p>

                        <p>Transaction Details:</p>
                    </Grid>
                    <Grid className='price_qty_counter_grid_item_title' item xs={3}>Units Price (WHTEXs)</Grid>
                    <Grid className='price_qty_counter_grid_item_title' item xs={3}>Units Quantity</Grid>
                    <Grid className='price_qty_counter_grid_item_title' item xs={3}>Units Countervalue (WHT)</Grid>
                    <Grid className='price_qty_counter_grid_item_title' item xs={3}>Units Countervalue (EUR)</Grid>

                    <Grid item xs={3}><NumberField value={currentRecord?.price || ""} /></Grid>
                    <Grid item xs={3}>
                        <TextField variant={"outlined"} size={"small"}
                                   ref={inputElement}
                                   error={inputError}
                                   inputProps={{
                                       type: 'number',
                                       pattern: '[0-9]*',
                                       min: currentRecord?.min_token_quantity,
                                       step: (currentRecord?.min_token_increment ?? currentRecord?.min_token_quantity),
                                       defaultValue: currentRecord?.min_token_quantity
                                   }}
                                   onChange={(event) => {
                                       const value = parseInt(event?.target?.value)
                                       const valueToSet = isNaN(value) ? 0 : Math.max(value, 0)
                                       if (valueToSet < currentRecord?.min_token_quantity) {
                                           setInputError(true)
                                           setBuyDisabled(true)
                                           setInputErrorMessage("Minimum quantity: " + currentRecord?.min_token_quantity)
                                       } else if (valueToSet % (currentRecord?.min_token_increment ?? currentRecord?.min_token_quantity) !== 0) {
                                           setInputError(true)
                                           setBuyDisabled(true)
                                           setInputErrorMessage("Minimum quantity increment: " + (currentRecord?.min_token_increment ?? currentRecord?.min_token_quantity))
                                       } else if (valueToSet * currentRecord?.price > currentAmountHeld) {
                                           setInputError(true)
                                           setBuyDisabled(true)
                                           setInputErrorMessage("You don't have enough WHTEX's")
                                       } else if (valueToSet > currentRecord?.originator?.committed) {
                                           setInputError(true)
                                           setBuyDisabled(true)
                                           setInputErrorMessage("Maximum available: " + currentRecord?.originator?.committed)
                                       } else {
                                           setInputError(false)
                                           setBuyDisabled(false)
                                           setInputErrorMessage("")
                                       }
                                       setQuantity(valueToSet)
                                   }}/>
                    </Grid>
                    <Grid item xs={3}><NumberField value={quantity * currentRecord?.price} /></Grid>
                    <Grid item xs={3}><NumberField value={convert(quantity * currentRecord?.price, 'EUR')} /></Grid>
                    <Grid item xs={4}>&nbsp;</Grid>
                    {inputErrorMessage !== "" &&
                        <Grid item xs={8} className={"price_qty_counter_grid_item_error_message"}>
                            {inputErrorMessage}
                        </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions className='space_between_button'>
                <Button color='info' onClick={handleCancel} autoFocus>Cancel</Button>
                <Button variant="buy" size="large" disabled={buyDisabled || isRefetching} onClick={() => {
                    if (!buyLoading) {
                        otpModal.show()
                    }
                }}>
                    {!buyLoading ? "BUY" : <CircularProgress/>}
                </Button>
            </DialogActions>
        </Dialog>

        <OTPModal modal={otpModal} confirmText="SUBMIT" message={<><p>To ensure the security of your account, please enter the 6-digit Two-Factor Authentication (2FA) One-Time Password (OTP) code available on the Google Authenticator App<br /></p>

        </>} onConfirm={async (otp) => {
                handlerProceed(otp)
            }} title="OTP" />


    </>
}