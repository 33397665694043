import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { BankSellList } from "./sell-list";
import { BankBuyList } from "./buy-list";
import { BankAuditLog } from "./logs-list";
import './Bank.scss'


export function BankTabs() {
    const [value, setValue] = useState('1');

    const handleChange = (_evt, value ) => {
        setValue(value);
    }
    return <Box className='bank-tab-container' sx={{ width: '100%', typography: 'body1' }}>

        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList className="tabs" onChange={handleChange} aria-label="lab API tabs example">
                    <Tab className='bank-tab bank-tab-buy' label="Buy request" value="1" />
                    <Tab className='bank-tab bank-tab-sell' label="Sell request" value="2" />
                    <Tab className='bank-tab bank-tab-audit' label="Audit log" value="3" />
                </TabList>
            </Box>
            <TabPanel value="1"><BankBuyList movement_type="buy" /></TabPanel>
            <TabPanel value="2"><BankSellList movement_type="sell" /></TabPanel>
            <TabPanel value="3"><BankAuditLog /></TabPanel>
        </TabContext>
    </Box>
}