import { NumberField } from "@refinedev/mui";
import { Box, Skeleton, Card, CardHeader, CardContent } from "@mui/material";
import './TradeOrderBook.scss'
import { useCurrencyStore } from "hooks/useCurrency";

export default function TradeOrderBook({loading, data}) {
    const emptyObj = {
        outstanding_orders: "",
        price: "",
        tokens: ""
    }
    const maxAskRows = data?.ask?.data?.length
    const maxBidRows = data?.bid?.data?.length

    const buy_data = data?.bid?.data?.sort((a, b) => b.price - a.price)
    const sell_data = data?.ask?.data?.sort((a, b) => a.price - b.price)

    const { getValue } = useCurrencyStore()


    const rows: { ask: any; bid: any, key: number }[] = []
    for (let i = 0; i < Math.max(maxAskRows, maxBidRows); i++) {
        let askData = (i <= maxAskRows) ? sell_data?.[i] : emptyObj
        let bidData = (i <= maxBidRows) ? buy_data?.[i] : emptyObj
        rows.push({'ask': askData, 'bid': bidData, 'key': i})
    }

    let percValueBid = (data?.bid?.totals?.outstanding_orders / (data?.bid?.totals?.outstanding_orders+data?.ask?.totals?.outstanding_orders))*100;
    let percValueAsk = (100-percValueBid);
    let classOverRed = '';
    let classOverGreen = '';

    if (percValueBid > percValueAsk) {
            classOverGreen = 'over'
    } else {
        classOverRed = 'over'
    }
    return (
        <Card>
            <CardHeader className="panel-header" title="Order Book" />
            <CardContent sx={{pl:'20px', pr: '20px'}}>
                <Box className='order-book-container'>
                    <Box className='order-book-title-data'>
                        <Box className='order-book-tab'>
                            <Box className='order-book-data-row order-book-data-title-row'>
                                <Box className='order-book-data order-book-data-title'># {/* Number of Outstanding Orders */}</Box>
                                <Box className='order-book-data order-book-data-title'>Units</Box>
                                <Box className='order-book-data order-book-data-title'>Bid Price</Box>
                                <Box className='order-book-data order-book-data-title'>Ask Price</Box>
                                <Box className='order-book-data order-book-data-title'>Units</Box>
                                <Box className='order-book-data order-book-data-title'># {/* Number of Outstanding Orders */}</Box>
                            </Box>
                            {
                                loading ? <Skeleton/> : rows.map((item) => {
                                    return (
                                        <Box key={item.key} className='order-book-data-row'>
                                            <Box className='order-book-data'><NumberField variant={"inherit"}
                                                                                        value={item?.bid?.outstanding_orders || 0}/></Box>
                                            <Box className='order-book-data'><NumberField variant={"inherit"}
                                                                                        value={item?.bid?.tokens || 0}/></Box>
                                            <Box className='order-book-data order-book-data-buy'>
                                                {getValue(item?.bid?.price || 0)}</Box>
                                            <Box className='order-book-data order-book-data-sell'>
                                                {getValue(item?.ask?.price || 0)}</Box>
                                            <Box className='order-book-data'><NumberField variant={"inherit"}
                                                                                        value={item?.ask?.tokens || 0}/></Box>
                                            <Box className='order-book-data'><NumberField variant={"inherit"}
                                                                                        value={item?.ask?.outstanding_orders || 0}/></Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                    <Box className='order-book-data-row order-book-total-data-row'>
                        <Box className='order-book-data order-book-data-buy'>
                            {loading ? <Skeleton/> :
                                <NumberField variant={"inherit"} value={data?.bid?.totals?.outstanding_orders || 0}/>}
                        </Box>
                        <Box className='order-book-data order-book-data-buy-contract'>
                            {loading ? <Skeleton/> : <NumberField variant={"inherit"} value={data?.bid?.totals?.tokens || 0}/>}
                        </Box>
                        <Box className='order-book-data order-book-data-buy-contract order-book-data-buy-contract-empty'></Box>
                        <Box
                            className='order-book-data order-book-data-sell-contract order-book-data-sell-contract-empty'></Box>
                        <Box className='order-book-data order-book-data-sell-contract '>
                            {loading ? <Skeleton/> : <NumberField variant={"inherit"} value={data?.ask?.totals?.tokens || 0}/>}
                        </Box>
                        <Box className='order-book-data order-book-data-sell'>
                            {loading ? <Skeleton/> :
                                <NumberField variant={"inherit"} value={data?.ask?.totals?.outstanding_orders || 0}/>}
                        </Box>
                        <Box className='order-book-total-data-row-color'>
                            <Box className={`box-color green ${classOverGreen}`} sx={{width:'calc('+ percValueBid + '% + 15px)'}}></Box>
                            <Box className={`box-color red ${classOverRed}`} sx={{width:'calc('+ percValueAsk + '% + 15px)'}}></Box>
                        </Box>

                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}