
import React from "react";
import PropTypes from "prop-types";

import { format } from "d3-format";
import { timeFormat } from "d3-time-format";

import { ChartCanvas, Chart } from "react-stockcharts";
import { LineSeries, AreaSeries } from "react-stockcharts/lib/series";

import { Label } from 'react-stockcharts/lib/annotation'

import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY,
} from "react-stockcharts/lib/coordinates";

import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import {
	OHLCTooltip,
} from "react-stockcharts/lib/tooltip";
import { last } from "react-stockcharts/lib/utils";
import { timeParse } from "d3-time-format";
export const parseDate = timeParse("%Y-%m-%d");

const LineAndScatterChartGrid = (props) => {

	const { width, ratio, type, data: initialData, interpolation } = props;
	const { gridProps, seriesType } = props;
	const margin = { left: 0, right: 60, top: 20, bottom: 30 };
	// const [width, ratio, node, setNode, setContext] = useFitWidth({})

	const height = width / 1.5;
	const gridHeight = height - margin.top - margin.bottom;
	const gridWidth = width - margin.left - margin.right - 10;

	const showGrid = true;
	const yGrid = showGrid ? { innerTickSize: -1 * gridWidth, tickStrokeOpacity: .3 } : {};
	const xGrid = showGrid ? { innerTickSize: -1 * gridHeight, tickStrokeOpacity: .3 } : {};

	const xScaleProvider = discontinuousTimeScaleProvider
		.inputDateAccessor(d => d.date);

	let fake_data = [

		{
			"date": new Date("Sun May 19 2024 00:00:00 GMT+0200 (Ora legale dell’Europa centrale)"),
			"fulldate": "20240519005503",
			"open": 1005,
			"high": 1100,
			"low": 1000,
			"close": 1095,
			"volume": 140000
		},

		{
			"date": new Date("Sun May 26 2024 00:00:00 GMT+0200 (Ora legale dell’Europa centrale)"),
			"fulldate": "20240526005503",
			"open": 1375,
			"high": 1450,
			"low": 1350,
			"close": 1420,
			"volume": 160000
		},
		{
			"date": new Date("Mon Jun 03 2024 00:00:00 GMT+0200 (Ora legale dell’Europa centrale)"),
			"fulldate": "20240526005503",
			"open": 1375,
			"high": 1450,
			"low": 1350,
			"close": 1420,
			"volume": 160000
		},

	];

	console.log('initialData', initialData);

	const {
		data,
		xScale,
		xAccessor,
		displayXAccessor,
		// } = xScaleProvider(fake_data)
	} = xScaleProvider(initialData);

	const start = xAccessor(last(data));
	const end = xAccessor(data[Math.max(0, data.length - 150)]);
	const xExtents = [start, end];
	const dateFormatter = (index) => {
		return data[index].date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' }).replace('.', '');
	}

	var [yAxisLabelX, yAxisLabelY] = [10, margin.top + (height - margin.top - margin.bottom) / 2]

	const Series = seriesType === "line"
		? LineSeries
		: AreaSeries;
	return (
		<ChartCanvas height={height}
			ratio={ratio}
			width={width}
			margin={{ left: 20, right: 60, top: 10, bottom: 30 }}
			type={type}
			seriesName="MSFT"
			data={data}
			xScale={xScale}
			xAccessor={xAccessor}
			ref={(node) => {
				if (node) {
					console.log("ref", node)
				}
			}}
			displayXAccessor={displayXAccessor}
			xExtents={xExtents}
		>
			<Label x={yAxisLabelX} y={yAxisLabelY}
				canvasOriginX={0}
				rotate={-90}
				fontSize="12" text="Last Price Per Unit" />


			<Chart id={1}
				yExtents={d => [0, d.close * 1.1]}
				origin={[0, 0]}
				padding={{ top: 0, bottom: 0 }}
			>
				<XAxis
					axisAt="bottom"
					orient="bottom"
					{...gridProps}
					{...xGrid}
					tickFormat={dateFormatter}
				/>
				<YAxis
					axisAt="right"
					orient="right"
					ticks={5}
					{...gridProps}
					{...yGrid}
				/>
				<MouseCoordinateX
					at="bottom"
					orient="bottom"
					displayFormat={timeFormat("%Y-%m-%d")} />
				<MouseCoordinateY
					at="right"
					orient="right"
					displayFormat={format(".2f")} />

				<Series
					yAccessor={d => d.close}
					interpolation={interpolation}
					stroke="rgb(130, 202, 157)"
					fill="#82CA9D"
				/>
				<OHLCTooltip origin={[-20, -7]} />
			</Chart>

			<CrossHairCursor />


		</ChartCanvas>

	);
}



export function parseData(parse) {
	return function (d) {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;

		return d;
	};
}



export default LineAndScatterChartGrid
