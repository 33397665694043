import { Box, Button, Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { useList } from "@refinedev/core";
import { useEffect } from "react";
import React from 'react'
import { IStaticLink } from "shared-libs/src/interfaces";

export function TermsAndConditionsPage({ }) {
    useEffect(() => {
        document.title = 'Whtexch - Terms & Conditions';
    }, []);

    const { data } = useList<IStaticLink>({
        resource: 'staticlink'
    })


    return <React.Fragment>
        <Box sx={{ displax: 'flex' }} className="page-title-container">
            <Box>
                <Typography variant="h1">Market Information</Typography>
            </Box>
        </Box>
        <Card sx={{ mb: 2 }}>
            <CardHeader className="panel-header" title="Downloads"></CardHeader>
            <CardContent className="card-whtex">
                {data?.data?.map(({ text, button_text, button_url }) => {
                    return <>
                        <p>{text}</p>
                        <Button target="_blank" download={true} href={button_url}>{button_text}</Button>&nbsp;
                    </>
                })}
            </CardContent>

        </Card>
    </React.Fragment>

}