import { Box, Card, CardActionArea, CardActions, CardContent, CardHeader, LinearProgress, Typography } from '@mui/material';
import { useOne } from '@refinedev/core';
import { useTradeDownloadButton } from 'components/trade/useTradeDownloadButton';
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { IAftTrade } from 'shared-libs/src/interfaces';

export const Download: React.FC = () => {

    const params = useParams()

    const aftcontractId = params.id;
    const { data: dataClient, isSuccess: isSuccessClientData, refetch: refetchClientData } = useOne<IAftTrade>({
        resource: 'trade',
        id: aftcontractId || "",
        liveMode: "auto",
        errorNotification: false,
        queryOptions: {
            retry: false,
            // refetchInterval: 10000
        }
    })
    const [pendingBuy, setPendingBuy] = React.useState(0)
    React.useEffect(() => {
        if (dataClient?.data?.client_active_orders) {
            const { buy } = (dataClient?.data?.client_active_orders as { type, quantity, init_quantity }[]).reduce((acc, curr, index) => {
                acc[curr.type] = (acc[curr.type] || 0) + curr.quantity;
                return acc;
            }, { buy: 0, sell: 0 })
            setPendingBuy(buy);
        }
    }, [dataClient?.data?.client_active_orders])

    const { preview, original } = useTradeDownloadButton({ pendingBuy, aftcontractId, dataClient })
    const aftName = dataClient?.data.work_of_ingenuity ? dataClient?.data.work_of_ingenuity : aftcontractId


    return <React.Fragment>
        <Box sx={{ displax: 'flex' }} className="page-title-container">
            <Box>
                <Typography variant="h1">Download</Typography>
            </Box>
        </Box>
        <Card>
            <CardHeader title={'Preview'} />
            <CardContent>

                <Typography> The preview functionality allows you to download a Proxy of the <strong>{aftName}</strong>.</Typography>
                <Typography> For the sake of clarity, the Proxy does not grant you any title or right with respect to the <strong>{aftName}</strong>.</Typography>
                <Typography><i>Kindly be aware that the preview is only available for the units not committed.</i></Typography>
                {preview.data?.data?.data?.tokenizer_status_percentage && <LinearProgress variant='determinate' aria-label='' color='info' value={preview.data.data.data.tokenizer_status_percentage} />}
                <CardActions style={{ marginBottom: '5px' }}>{preview.button}</CardActions>
            </CardContent>

        </Card>
        <p></p>
        <Card>
            <CardHeader title='Original: Digitalised Intellectual Property Masterpiece' />
            <CardContent>
                <Typography>
                    <Typography>By proceeding with the download, you accept and acknowledge that the download of the Digitalised Intellectual Property Masterpiece in accordance with the contractual provisions and the Rules, will entail, pursuant to Article 1411 of the Italian Civil Code, the acquisition of the ownership of the Digitalised Intellectual Property Masterpiece and of the rights, including reproduction rights, in accordance with the Whitepaper. You therefore declares your intention to take advantage of the stipulation in your favour. </Typography>
                    <Typography>Kindly be aware that the download of the Digitalised Intellectual Property Masterpiece will result in the impossibility of further trading the Units in accordance with the Rules.</Typography>
                </Typography>
                <Typography>
                    {original.data?.data?.tokenizer_status_percentage ? <LinearProgress variant='determinate' color='info' value={original.data?.data.tokenizer_status_percentage} /> : null}
                </Typography>
                <CardActions style={{ marginBottom: '5px' }}>
                    {original.button}
                </CardActions>
            </CardContent>


        </Card>

    </React.Fragment>
}