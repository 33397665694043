import { DateField, NumberField } from "@refinedev/mui";
import { Box, Card, CardContent, CardHeader, Collapse, Grid, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import "./AftOverview.scss"
import {AftActionButton} from "./AftActionButton";
import { CrudFilters, useList, useShow } from "@refinedev/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCurrencyStore } from "hooks/useCurrency";

const getLabel = type => {
    switch (type) {
        case 'whitepaper': return 'Whitepaper';
        case 'valuation': return 'Valuation & Expert Report';
        default: return type;
    }
}
const getType = filename => {
    return filename.split('.')[0];
}


export const AftOverview = ({aft_id}) => {
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const {queryResult} = useShow({
        resource: 'aft',
        id: aft_id
    });

    const filters: CrudFilters = [
        {
            field: "aftcontract_id",
            operator: "eq",
            value: aft_id,
        }
    ];
    const { data: dataDoc, refetch } = useList({
        resource: 'documents',
        queryOptions: { enabled: !!aft_id },

        filters
    });


    const {data, isLoading} = queryResult;
    const record = data?.data;
    const rowLabelXs = 8
    const rowValueXs = 2

    const conversion_unit = .01

    const { getValue } = useCurrencyStore();
    return (
        <Card sx={{mb: 2}}>
            <CardHeader className="panel-header-light" title={record?.work_of_ingenuity || aft_id}>
            </CardHeader>
            <CardContent>
                <Typography sx={{ fontSize: '1.3rem' }}>Documentation</Typography>
                <Stack>
                {dataDoc?.data.map(el => {
                    const type = getType(el.filename);
                    return <AftActionButton aft_id={aft_id} type={type} label={getLabel(type)} />
                })}
                </Stack>


                <Box className="box-collapse" onClick={handleToggle}>
                    <Typography className="column-label">Overview </Typography> {open ? <KeyboardArrowDownIcon/> :
                    <KeyboardArrowUpIcon/>}
                </Box>
                <Collapse className="box-collapse-in" in={open}>
                    {!isLoading ? <>
                        <Grid container spacing={2}>
                            <Grid item xs={rowLabelXs}>
                                <Typography>DATE:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <DateField value={record?.creation_date} format={"YYYY/MM/DD"}/>
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>MASTERPIECE ID:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>{record?.work_of_ingenuity || aft_id}</Typography>
                            </Grid>

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>Value:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.number_of_tokens * record?.price}
                                             options={{style: 'currency', currency: "EUR"}}/>
                            </Grid> */}

                            <Grid item xs={12}>
                            </Grid>

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>Micro-fractions:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.tokens_deposited * microfractions_constant}/>
                            </Grid> */}

                            {/* <Grid item xs={rowLabelXs}>
                                <Typography>1 Unit:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.min_token_quantity}/>
                            </Grid> */}

                            <Grid item xs={rowLabelXs}>
                                <Typography>PRICE PER UNIT:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>{getValue(record?.price)}</Typography>
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>TOTAL UNITS DEPOSITED:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField locale={'en-US'} value={record?.number_of_tokens} />
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>UNITS ORIGINAL DEPOSITED VALUE:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>{getValue(record?.number_of_tokens * record?.price)}</Typography>
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>AVERAGE PURCHASE PRICE:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>{getValue(record?.client_avg_price)}</Typography>
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>UNITS TOTAL QTY BOUGHT BY PARTICIPANTS:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.tokens_bought_by_mkt} locale={'en-US'} />
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>UNITS RESIDUAL AVAILABLE IN MKT:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.tokens_residual_available} locale={'en-US'} />
                            </Grid>

                            <Grid item xs={rowLabelXs}>
                                <Typography>OWN UNITS QUANTITY:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <NumberField value={record?.client_aft_owned} locale={'en-US'} />
                            </Grid>
                            <Grid item xs={rowLabelXs}>
                                <Typography>OWN UNITS VALUE:</Typography>
                            </Grid>
                            <Grid item xs={rowValueXs}>
                                <Typography>{getValue(record?.client_aft_owned * record?.price)}</Typography>
                            </Grid>
                        </Grid>
                    </> : <Skeleton/>}
                </Collapse>
            </CardContent>
        </Card>
    )
}