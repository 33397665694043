import { Box, Card, CardContent, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useList, useOne, useTranslate } from "@refinedev/core";
import { useEffect, useState } from 'react';
import { NumberField } from "../fields";
import './TotalToken.scss';
import { useCurrencyStore } from "hooks/useCurrency";

export default function TotalToken() {
    const {data: dataAft, isSuccess: isSuccessAft} = useList({
        resource: "onmarket",
        filters: [
            {
                field: "is_premarket",
                operator: "eq",
                value: false,
            },
            {
                field: 'show_artwork',
                operator: 'eq',
                value: true
            },
            {
                field: 'show_average_purchase_price',
                operator: 'eq',
                value: true
            }, 
            { 
                field: 'has_sold_tokens_in_premarket', 
                operator: 'eq', 
                value: true 
            },

        ],
        queryOptions: {
            cacheTime: 0,
            staleTime: 0
        }
    })

    const { getValue } = useCurrencyStore()

    const { data: dataAftAll, isSuccess: isSuccessAftAll } = useOne({
        resource: 'balance_aft',
        id: "",
        liveMode: 'auto',
        queryOptions: {
            cacheTime: 0,
            staleTime: 0,
        }
    })
    const [expectedProfitLoss, setExpectedProfitLoss] = useState(0)
    useEffect(() => {
        if (isSuccessAft && Array.isArray(dataAft?.data)) {
            let _exp = 0
            dataAft?.data.forEach((item) => {
                console.log("exp", item, item?.client.wht_expected_profit_loss, item?.id)
                _exp += item?.client.wht_expected_profit_loss
            })
            setExpectedProfitLoss(_exp)
        }
    }, [isSuccessAft, dataAft])

    const t = useTranslate();
    return (
        <Stack sx={{mb: 4}} justifyContent="center" direction="row" className="aft-totals">
            <Box className="aft-total-value aft-total-box">
                <Card sx={{minWidth: 275}}>
                    <CardContent>
                        <Box className="aft-total-box-value">
                            {!isSuccessAftAll ? <Skeleton /> : <Typography>{getValue(dataAftAll?.data?.available_value + dataAftAll?.data?.committed_value)}</Typography>}
                        </Box>
                        <Box className="aft-total-box-title">
                            <Tooltip title={t('token.total_tooltip')} placement="top">
                                <Box>{t('token.total')}</Box>
                            </Tooltip>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box className="aft-total-exp-pl aft-total-box">
                <Card sx={{minWidth: 275}}>
                    <CardContent>
                        <Box className="aft-total-box-value">
                            {!isSuccessAft ? <Skeleton /> : <Typography>{getValue(expectedProfitLoss)}</Typography>}

                        </Box>
                        <Box className="aft-total-box-title">
                            <Tooltip title={t('token.exp_pl_tooltip')} placement="top">
                                <Box className={t('token.exp_pl') == '0' ? 'green' : 'red'}>{t('token.exp_pl')}</Box>
                            </Tooltip>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Stack>
    )
}