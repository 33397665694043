import React, {useEffect, useState} from 'react'
import './Dashboard.scss'
import { Authenticated, useCustom, useOne } from "@refinedev/core";
import { Alert, Box, Stack } from "@mui/material";
import TotalToken from "../components/dashboard/TotalToken";
import TotalWhtex from "../components/TotalWhtex";
import AftTrade from "../components/dashboard/AftTrade";
import AftPreMarket from "../components/dashboard/AftPreMarket";
import AftPreMarketClosed from 'components/dashboard/AftPreMarketClosed';
import { useTradeOpen } from 'components/trade/useTradeOpen';

export function Dashboard() {
    useEffect(() => {
        document.title = 'Whtexch - Dashboard';
    }, []);
    const [dataBalance, setDataBalance] = useState<any>({});
    const [isSuccessBalance, setIsSuccessBalance] = useState(false);
    const {data, isSuccess} = useOne({
        resource: "balance_wht",
        id: "",
        liveMode: "auto",
    })
    useEffect(() => {
        if(isSuccess) {
            setDataBalance(data)
            setIsSuccessBalance(isSuccess)
        }
    }, [data, isSuccess])

    const { isTradeOpen } = useTradeOpen()
    return (
        <Authenticated v3LegacyAuthProviderCompatible>
            <Box component="main">
                <Stack className="main-header">
                    <TotalToken/>
                    <TotalWhtex fields={["owned", "committed", "available"]} data={dataBalance}
                                isSuccess={isSuccessBalance}/>
                </Stack>
                {isTradeOpen === false && <Alert severity="warning">The market is closed!</Alert>}

                <AftPreMarket balance={dataBalance}/>
                <AftTrade/>
                <AftPreMarketClosed balance={dataBalance} />
            </Box>
        </Authenticated>
    )
}

