import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import './CustomersCard.scss'
import { useGetIdentity, useNavigation } from "@refinedev/core";

export const CustomersCard = () => {
    const {data: user} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const {edit} = useNavigation()
    return (
        <Card sx={{mb: 2}} className='customers-card'>
            <CardHeader className="panel-header" title={'Customer'}
                        action={<Button className={'btn primary'}
                                        onClick={() => edit('customers', "")}>Reset Password</Button>}/>
            <CardContent>
                <Grid container spacing={2} rowSpacing={6}>
                    <Grid item xs={4}>
                        <CardHeader className="panel-header customers" title={'Name'} subheader={user?.profile_data.firstname} />
                    </Grid>
                    <Grid item xs={6}>
                        <CardHeader className="panel-header customers" title={'Last Name'} subheader={user?.profile_data.lastname} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <CardHeader className="panel-header customers" title={'Telephone'} subheader={user?.profile_data.phone} />
                    </Grid>
                    <Grid item xs={4}>
                        <CardHeader className="panel-header customers" title={'Email'} subheader={user?.email}/>
                    </Grid>
                    <Grid item xs={4}>
                        <CardHeader className="panel-header customers" title={'Iban'} subheader={user?.profile_data.iban} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <CardHeader className="panel-header customers" title={'Company Name'}
                            subheader={user?.profile_data?.company_name} />
                    </Grid>
                    <Grid item xs={4}>
                        <CardHeader className="panel-header customers" title={'Company Website'}
                            subheader={user?.profile_data?.company_website} />
                    </Grid>
                    <Grid item xs={4}>
                        <CardHeader className="panel-header customers" title={'Company Registration / Tax Number'}
                            subheader={user?.profile_data?.tax_number} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}