import React, {ReactNode, useEffect} from 'react'
import { Box, Link } from "@mui/material";
import './Fullpage.scss'
import Logo from '../assets/logo-whtexch.png'

type LayoutProps = {
    Header?: React.FC;
    Footer?: React.FC;
    children?: ReactNode;
};

export const FullPage: React.FC<LayoutProps> = ({ Header, Footer, children }) => {

    const isStaging = process.env.REACT_APP_API_URL?.toLowerCase()?.includes('nohup') || process.env.REACT_APP_API_URL?.toLowerCase()?.includes('localhost')
    const isQA = process.env.REACT_APP_API_URL?.toLowerCase()?.includes('-qa');
    const additionalStyle = isStaging || isQA ? { borderTop: "10px solid " + (isQA ? "rgb(237,173,0)" : "rgb(173, 1,0)") } : {}
    return (
        <Box className="full-page" style={additionalStyle}>
            {Header && <Header/>}
            <Box component="main" className="main">
                <Link href="/">
                    <img className='logo' src={Logo} alt="whtexch"/>
                </Link>
                {children}
            </Box>
            {Footer && <Footer/>}
        </Box>
    );
};