import {DateField, useDataGrid} from "@refinedev/mui";
import {DataGrid, GridColumns, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Modal, Typography } from "@mui/material";
import React, { useEffect, useState, useSyncExternalStore } from "react";
import { IAft, IAftLite, IMatch, ITradingAudit } from "shared-libs/src/interfaces";
import {NumberField} from "../../components/fields";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";
import { useModal, useTranslate } from "@refinedev/core";
import { OrderMatchesDialog } from "./OrderMatchesDialog";
import { useCurrencyStore } from "hooks/useCurrency";

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{justifyContent: 'space-between'}}>
            <Box sx={{fontSize: '1.5rem'}}>History</Box>
            <Box>History Report<GridToolbarExport aria-label={'Download'} sx={{marginLeft: '16px'}}/></Box>
        </GridToolbarContainer>
    );
}


export const AftHistory = ({aftcontract_id}) => {
    const t = useTranslate()
    const { dataGridProps } = useDataGrid<ITradingAudit>({
        resource: "trading_audit",

        syncWithLocation: false,
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.queryOptions,
            queryKeyHashFn: (data) => {
                return [...data.slice(0, 3), aftcontract_id].join('')
            },
            cacheTime: 0,
            staleTime: 0,
        },
        filters: {
            permanent: [
                {
                    field: "aftcontract_id",
                    operator: "eq",
                    value: aftcontract_id,
                }
            ]
        }
    });


    const { toggleCurrency, currency, getValue } = useCurrencyStore()


    const columns = React.useMemo<GridColumns<ITradingAudit & { closed_reason: string }>>(
        () => [
            {
                field: "id",
                headerName: ("ID"),
                headerAlign: "center",
                align: 'center',
                type: 'int',
                flex: 1,
            },
            {
                field: "timestamp",
                headerName: ("DATE & TIME (UTC)"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                flex: 2,
                valueFormatter: ({value}) => {
                    return value && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8)
                },
                renderCell: ({ value }) => value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"} /> : ""
            },
            {
                field: "aftcontract_id",
                headerName: ("Masterpiece Id"),
                headerAlign: "center",
                align: 'center',
                valueGetter: ({ row }) => row.artwork?.work_of_ingenuity || row.aftcontract_id,
                minWidth: 160,
                flex: 1
            },
            {
                field: "market",
                headerName: ("market"),
                headerAlign: "center",
                align: 'center',
                minWidth: 50,
                flex: 1.25,
            },
            {
                field: "order.type",
                headerName: ("order type"),
                headerAlign: "center",
                align: 'center',
                minWidth: 50,
                flex: 1,
                valueGetter: (params) => {
                    return params.row.order.type
                }
            },
            {
                field: "entry_type",
                headerName: ("order status"),
                headerAlign: "center",
                align: "center",
                flex: 2,
                // valueGetter: ({ row: { order_status, closed_reason, not_executed, order_size } }) => {
                //     switch (order_status) {
                //         case 'o': return order_size != not_executed ? "Partially Executed" : "Open";
                //         case 'c': return closed_reason === 'retired' ? 'Cancelled' : "Closed";
                //         case 'a': return "Archived";
                //     }
                // }
            },
            {
                field: "order.init_quantity",
                headerName: ("order size"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                valueGetter: (params) => {
                    return params.row.order.init_quantity
                },
                flex: 1,
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "quantity",
                headerName: ("executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "not_executed",
                headerName: ("not executed"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: (params) => {
                    return params.row.order.quantity
                },
                renderCell: function render({ value }) {
                    return value ? <NumberField value={value} /> : "-"
                }
            },
            {
                field: "avg_price",
                headerName: ("Average Purchase Price"),
                headerAlign: "center",
                align: 'center',
                type: 'int',
                flex: 1.25,
                valueGetter: (params) => {
                    return getValue(params.row.order.avg_price)
                }
            },
            {
                field: "price_for_unit",
                headerName: ("limit price"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row }) => {
                    return getValue(row.order.price_for_unit)
                },
                renderCell: function render({ value }) {
                    return value
                }
            },
            {
                field: "expiration_date",
                headerName: ("validity period"),
                headerAlign: "center",
                align: 'center',
                type: 'dateTime',
                minWidth: 50,
                flex: 2,
                valueFormatter: ({ value }) => {
                    return value && value != 'GTC' && value.substring(0, 4) + '/' + value.substring(4, 6) + '/' + value.substring(6, 8) + " " + [value.substring(8, 10), value.substring(10, 12), value.substring(12, 14),].join(':')
                },
                valueGetter: ({ row: { order: { expiration_date } } }) => {
                    return expiration_date?.length ? expiration_date : "GTC"
                },
                renderCell: ({ value }) => value === 'GTC' ? 'GTC' : (value ? <DateField value={value} format={"YYYY/MM/DD HH:mm:ss"} /> : "")
            },
            // {
            //     field: "last_price",
            //     headerName: ("Execution price"),
            //     headerAlign: "center",
            //     align: 'center',
            //     type: 'number',
            //     minWidth: 50,
            //     flex: 1,
            //     renderCell: function render({ value }) {
            //         return value ? <NumberField value={value} /> : ""
            //     }
            // },
        ], [currency]
    );
    return (
        <Card sx={{ mb: '30px' }}>
            <CardContent sx={{pl: '20px', pr: '20px'}}>
                Currency: <Button variant="text" color="info" onClick={toggleCurrency}>{currency}</Button>

                <DataGrid
                    {...dataGridProps}
                    columns={columns}
                    autoHeight
                    className='aft-history'
                    headerHeight={80}
                    components={{Toolbar: CustomToolbar}}
                    {...GlobalConfig.dataGridConfig}
                />
            </CardContent>
        </Card>
    )
}