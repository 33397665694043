import { useCustomMutation } from "@refinedev/core";
import { useEffect, useState } from "react";


export function useTradeOpen() {
  // Definire uno stato iniziale utilizzando useState
  const [isTradeOpen, setTradeOpen] = useState();

  // Funzione per aggiornare lo stato
  const updateState = (newValue) => {
    setTradeOpen(newValue);
  };

  const url = '/exchange/square/is_open_now'

  const { mutate, isLoading, mutateAsync, ...data } = useCustomMutation({ mutationOptions: {} });
  const [bump, setBump] = useState(+new Date)


  useEffect(() => {
    mutateAsync({ method: 'post', url, values: { square_id: 'main' }, })
      .then(({ data }) => {
        updateState(data.result === true);
        setTimeout(() => {
          setBump(+new Date)
        }, 5000)
      })
  }, [bump])

  return {
    isTradeOpen
  };
}