import React from 'react'
import { Box, Tabs, Tab } from "@mui/material";
import {useTranslation} from "react-i18next";
import TradeTabBuy from "./TradeTabBuy";
import TradeTabSell from "./TradeTabSell";
import './TradeTab.scss'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default function TradeTab({aft_data, balance_data, is_reloaded, reloadPage}) {
    const {t} = useTranslation();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box className='trade-tab-container'>
            <Tabs value={value} onChange={handleChange}>
                <Tab className='trade-tab trade-tab-buy' label={t("Buy")} {...a11yProps(0)}
                     disabled={!aft_data || !balance_data}/>
                <Tab className='trade-tab trade-tab-sell' label={t("Sell")} {...a11yProps(1)}
                    disabled={!aft_data || !balance_data || parseInt(aft_data?.client_aft_available) <= 0} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <TradeTabBuy aft_data={aft_data} balance_data={balance_data} reloadPage={reloadPage}
                             is_reloaded={is_reloaded}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TradeTabSell aft_data={aft_data} balance_data={balance_data} reloadPage={reloadPage}
                              is_reloaded={is_reloaded}/>
            </TabPanel>
        </Box>
    )
}