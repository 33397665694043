import { FullPage } from "layouts";

export function Contacts() {

    return <FullPage>
        <p><h1>ADDRESS AND CONTACTS</h1></p>
        <p>WHITE EXCHANGE SPA</p>
        <p>Via Durini 25, Milan, 20122, Italy</p>
        <p></p>
        <p><strong>Phone:</strong>  +39 02124127677</p>
        <p><strong>Email:</strong> <a href="mailto:info@whtexch.com">info@whtexch.com</a></p>
        <p><strong>Pec:</strong> <a href="mailto:whtexch@legalmail.it">whtexch@legalmail.it</a></p>
    </FullPage>
}