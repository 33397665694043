import React, {useEffect, useState} from 'react'
import {FullPage} from "../layouts";

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";

import './Login.scss'
import {FullPageFooterLinks} from "../components";
import {useForm} from "@refinedev/react-hook-form";
import {Controller} from "react-hook-form";
import {useLogin, useNavigation, usePermissions, useNotification} from "@refinedev/core";
import {RequestProvider} from "shared-libs/src/providers/RequestProvider";
import {Link} from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { LoadingButton } from '@mui/lab';

type LoginVariables = {
    email: string;
    password: string;
    otp_now: string;
};

export const FormAuthCode = ({setMessageType}) => {
    const {open} = useNotification();
    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues
    } = useForm();
    const onFinish = () => {
        let values = getValues()
        //console.log(values)
        RequestProvider.emailVerification(values.authentication_code).then((response) => {
            //console.log('emailVerification')
            //console.log(response)
            if (response.data) {
                setMessageType(3)
            } else {
                if (open) {
                    open({
                        type: 'error',
                        message: response?.data?.message ?? 'Error on sending verification email. Please, contact our customer care'
                    });
                }
            }
        }).catch((response) => {
            //console.log(response)
            if (open) {
                open({
                    type: 'error',
                    message: response?.message ?? response?.data?.message ?? 'Error on sending verification email. Please, contact our customer care'
                });
            }
        })
    };
    return (
        <Box className='login-form-email-code' component={"form"} onSubmit={handleSubmit(onFinish)}>
            <FormControl className='login-form'>
                <Typography className='login-form-code-input'>
                    Insert the authentication code received by email
                </Typography>
                <Controller
                    name="authentication_code"
                    control={control}
                    rules={{required: true}}
                    render={({field}) => {
                        let error = (errors.authentication_code) ? {error: true, helperText: "required field"} : {}
                        return (
                            <TextField fullWidth
                                       id="login-authentication_code"
                                       label="Authentication Code"
                                       variant="outlined"
                                       {...error}
                                       {...field}
                            />
                        )
                    }}
                />
                <FormControl>
                    <Button color='info' type="submit">Verify</Button>
                </FormControl>
            </FormControl>

        </Box>

    )
}

export function Login({ onLoginComplete }) {
    useEffect(() => {
        document.title = 'Whtexch - Login';
    });
    const [messageType, setMessageType] = useState(0)
    const [loggedin, setLoggedin] = useState(false)
    const [notificationSent, setNotificationSent] = useState(false)
    const [notificationClick, setNotificationClick] = useState(false)
    const {data: permissionsData} = usePermissions({
        options: {
            notifyOnChangeProps: ['data']
        },
        v3LegacyAuthProviderCompatible: true
    });
    const { mutate: login, isLoading } = useLogin<LoginVariables>({
        v3LegacyAuthProviderCompatible: true
    });
    const {open} = useNotification();
    const {push} = useNavigation();
    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues
    } = useForm();
    const onFinish = () => {
        //console.log('finish')
        let values = getValues()
        login({
            email: values.email,
            password: values.password,
            otp_now: values.otp_now
        }, {
            onSuccess: () => {
                RequestProvider.fetchWsToken().then(() => {
                    setLoggedin(true)
                })
            }
        })
    };

    useEffect(() => {
        console.log('useEffect')
        console.log(permissionsData)
        console.log(loggedin)
        if (!loggedin && (!permissionsData && !permissionsData?.loggedin)) {
            return
        }
        if (!permissionsData.valid_email) {
            //console.log('not valid email')
            setMessageType(2)
        } else if (permissionsData.valid_pending_user) {
            //console.log('pengind user')
            setMessageType(3)
        } else if (permissionsData.valid && !permissionsData.admin) {
            //console.log('ok!')
            push('/')
            onLoginComplete();
        } else {
            RequestProvider._clearAccessData()
            if (open) {
                open({type: "error", message: "Invalid credential"})
            }
        }
    }, [loggedin])
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    return (
        <FullPage Footer={FullPageFooterLinks}>
            {messageType === 0 && <Box component={"form"} onSubmit={(e) => {
                handleSubmit(onFinish)(e)
                    // you will have to catch those error and handle them
                    .catch(() => {
                        //console.log('submit error')
                    });
            }}>
                <FormControl className='login-form'>
                    <Controller
                        name="email"
                        control={control}
                        rules={{required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/}}
                        render={({field}) => {
                            let error = (errors.email) ? {error: true, helperText: "required field"} : {}
                            return (
                                <TextField fullWidth
                                           id="login-email"
                                           label="Email"
                                           variant="outlined"
                                           {...error}
                                           {...field}
                                />
                            )
                        }}
                    />
                    <Controller
                        name="password"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            let error = (errors.password) ? {
                                error: true,
                                helperText: 'required field'
                            } : {}
                            return (
                                <TextField fullWidth
                                           id="login-password"
                                           label="Password"
                                           type={showPassword ? 'text' : 'password'}
                                           variant="outlined"
                                           InputProps={{ // <-- This is where the toggle button is added.
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={handleClickShowPassword}
                                                           onMouseDown={handleMouseDownPassword}
                                                       >
                                                           {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                       </IconButton>
                                                   </InputAdornment>
                                               )
                                           }}                                           {...error}
                                           {...field}
                                />
                            )
                        }}
                    />
                    <Controller
                        name="otp_now"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => {
                            let error = (errors.otp_now) ? {error: true, helperText: "required field"} : {}
                            return (
                                <TextField fullWidth
                                           id="login-otp_now"
                                           label="Insert OTP"
                                           variant="outlined"
                                           {...error}
                                           {...field}
                                />
                            )
                        }}
                    />
                    <FormControl>
                        <LoadingButton loading={isLoading} variant='contained' color='info' type="submit">Login</LoadingButton>
                    </FormControl>
                    <FormControl className={"back-bottom-container"}>
                        <Button className='button-link' variant={"text"}>
                            <Link className='link' to={"/forgot"}>Forgot Password? </Link>
                        </Button>
                    </FormControl>
                </FormControl>
            </Box>}
            {messageType === 2 &&
                <Box>
                    <FormAuthCode setMessageType={setMessageType}/>
                    {!notificationSent && <Card className='login-card'>
                        <CardContent>
                            <Typography>
                                If you haven't received the verification email yet,
                                use the button below to resend it
                            </Typography>
                        </CardContent>
                        <CardActions className='resend-button-card'>
                            <Button color={"secondary"} onClick={(event) => {
                                if (!notificationClick) {
                                    RequestProvider.requestEmailVerification().then(() => {
                                        //console.log('requestEmailVerification ok')
                                        setNotificationSent(true)
                                    }).catch((response) => {
                                        if (open) {
                                            open({
                                                type: 'error',
                                                message: response.data.message ?? 'Error on sending verification email. Please, contact our customer care'
                                            });
                                        }
                                    })
                                    setNotificationClick(true)
                                }

                            }}>RESEND</Button>
                        </CardActions>
                    </Card>}
                    {notificationSent && <Card className='login-card'>
                        <CardContent>
                            <Typography>
                                Please check your email to verify your account. Once verified, our team will contact you
                                shortly
                                to start the registration process.
                            </Typography>
                        </CardContent>
                    </Card>}
                </Box>
            }
            {messageType === 3 && <Card className='login-card'>
                <CardContent>
                    <Typography>
                        Verification account in progress. You will be contacted as soon as possible
                    </Typography>
                </CardContent>
            </Card>}
        </FullPage>
    );
}

