import { Box, Stack, Typography } from "@mui/material";
import {BarChart, Bar, Tooltip, ResponsiveContainer, Legend, CartesianGrid, XAxis, YAxis} from "recharts";
import './TradeChart.scss'
import { useEffect, useRef, useState } from "react";
import Chart, { parseData, parseDate } from "./Chart";
import { timeParse } from "d3-time-format";
import moment from 'moment'

const FILL_MISSING_DATES = false;
export default function TradeChart({loading, data}) {
    // const data = [{"name": "Page A","uv": 4000,"pv": 2400}]
    let dataChart = data || []
    // useEffect(() => {
    //     if (!loading && data) {
    //         dataChart = data.map(item => {
    //             item.date = item.date.substring(2, 4) + '/' + item.date.substring(4, 6) + '/' + item.date.substring(6, 8);
    //             return item
    //         })
    //     }
    // }, [loading, data])
    const [parsedData, setParsedData] = useState<any>()
    const parseDate = timeParse("%Y%m%d%H%M%S");
    const p = parseData(timeParse("%Y%m%d"))
    const windowSize = useRef([window.innerWidth, window.innerHeight]);


    useEffect(() => {
        if (data) {
            if (data?.length > 1) {
                setParsedData(Object.values(getReducedData(data)).map(p));
            } else {
                setParsedData(Object.values(getReducedData([{ price: data[0].price, date: data[0].date }, { price: data[0].price, date: moment().format('YYYYMMDDhhmmss') }])).map(p))
            }
        }
    }, [data])


    return (
        <Box className={"trade-chart"}>
            {parsedData?.length > 1 ? <Chart type={'svg'} data={parsedData} width={windowSize.current[0] * .4} ratio={1.0} /> : null}
        </Box>
    )
}

const getReducedData = data => {

    const _temp = data.reduce((acc, curr) => {

        const d = curr.date.substring(0, 8)
        const v = curr.price

        if (d > acc.maxDate) {
            acc.lastPrice = v
            acc.maxDate = d
        }


        if (!acc[d]) {
            acc[d] = {
                date: d,
                fulldate: curr.date,
                high: v,
                low: v,
                open: v,
                close: v,
                volume: 0
            }
        } else {
            if (d < acc[d].fulldate) {
                acc[d].open = v
            }
            if (d > acc[d].fulldate) {
                acc[d].close = v
            }
            if (v > acc[d].high) {
                acc[d].high = v
            }
            if (v < acc[d].low) {
                acc[d].low = v
            }
        }

        return acc;
    }, { maxDate: "", lastPrice: "" })




    _temp[getCurrentDay()] = {
        date: getCurrentDay(),
        fulldate: _temp.maxDate + getCurrentTime(),
        high: _temp.lastPrice,
        low: _temp.lastPrice,
        open: _temp.lastPrice,
        close: _temp.lastPrice,
        volume: 0
    }

    delete _temp.maxDate
    delete _temp.lastPrice
    return FILL_MISSING_DATES ? fillMissingDates(_temp) : _temp;
}

function getCurrentTime(): string {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}${minutes}${seconds}`;
}

function getCurrentDay(): string {
    const now = new Date();
    const year = now.getFullYear().toString().padStart(4, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
}


interface DateObject {
    [key: string]: object;
}

function fillMissingDates(data: DateObject): DateObject {
    const filledData: DateObject = {};
    const dates = Object.keys(data).sort();

    if (dates.length === 0) {
        return filledData;
    }

    const startDate = new Date(dates[0].slice(0, 4) + '-' + dates[0].slice(4, 6) + '-' + dates[0].slice(6, 8));
    const endDate = new Date(dates[dates.length - 1].slice(0, 4) + '-' + dates[dates.length - 1].slice(4, 6) + '-' + dates[dates.length - 1].slice(6, 8));

    let previousDateValue = data[dates[0]];

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const formattedDate = d.toISOString().split('T')[0].replace(/-/g, '');
        if (data[formattedDate]) {
            previousDateValue = data[formattedDate];
        }
        previousDateValue['date'] = formattedDate
        filledData[formattedDate] = { ...previousDateValue, date: formattedDate };
    }


    return filledData;
}
