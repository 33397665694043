import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useCustomMutation, useList } from "@refinedev/core"
import { useEffect, useState } from "react"

export function Announcements({ }) {

    const { data, refetch } = useList<{ announcement_id: number, title: string, content: string }>({
        resource: 'announcements',
    })

    const { mutate } = useCustomMutation({})
    const [open, setOpen] = useState<boolean>();

    useEffect(() => {
        if (data?.data.length) {
            setOpen(true)
        }
    }, [data])

    const handleClose = announcement_id => () => {
        setOpen(false);
        mutate({
            url: '/announcements/set_user_viewed_announcement',
            values: {
                announcement_id
            },
            method: 'post'
        }, {
            onSuccess: () => {
                refetch()

            }
        })
    }



    return <>{
        data?.data.slice(0, 1).map(({ announcement_id, title, content }) => {
            return <Dialog open={open == true} onClose={((announcement_id) => {
                return handleClose(announcement_id)
            })(announcement_id)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box dangerouslySetInnerHTML={{ __html: content }} /></DialogContent>
                <DialogActions>
                    <Button onClick={handleClose(announcement_id)}>OK</Button>
                </DialogActions>
            </Dialog>
        })
    }</>
}