import React from 'react'
import { Box, Skeleton, Stack } from "@mui/material";
import './AftSummary.scss'
import {NumberField} from "./fields";

export default function AftSummary({loading, data}) {
    return (
        <Stack className='aft-column-container' spacing={2} direction={"row"}>
           {/*  <Box className='aft-column'>
                <Box className='aft-column-title'>AFT Market</Box>
                <Box className='aft-column-data first'>{loading ? <Skeleton/> : data?.token ?? ""}</Box>
            </Box> */}
            {/*<Box className='aft-column'>*/}
            {/*    <Box className='aft-column-title'>Contract ID</Box>*/}
            {/*    <Box className='aft-column-data'>*/}
            {/*        {loading ? <Skeleton/> : data?.contract_id ?? ""}*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <Box className='aft-column'>
                <Box className='aft-column-title'>Last Price</Box>
                <Box className='aft-column-data last'>
                    {loading ? <Skeleton/> : <NumberField value={data?.last_price ?? 0}/>}
                </Box>
            </Box>
            <Box className='aft-column'>
                <Box className='aft-column-title'>Own Units</Box>
                <Box className='aft-column-data'>
                    {loading ? <Skeleton /> : <NumberField value={(data?.available ?? 0) + (data?.committed ?? 0)} />}
                </Box>
            </Box>
            <Box className='aft-column'>
                <Box className='aft-column-title'>Pending Units to Buy</Box>
                <Box className='aft-column-data'>
                    {loading ? <Skeleton /> : <NumberField value={(data?.pending_buy || 0)} />}
                </Box>
            </Box>
            <Box className='aft-column'>
                <Box className='aft-column-title'>Pending Units to Sell</Box>
                <Box className='aft-column-data'>
                    {loading ? <Skeleton /> : <NumberField value={(data?.pending_sell || 0)} />}
                </Box>
            </Box>

            <Box className='aft-column'>
                <Box className='aft-column-title'>Max Units Purchasable</Box>
                <Box className='aft-column-data'>
                    {loading ? <Skeleton /> : <NumberField value={data?.max_units_purchasable ?? 0} />}
                </Box>
            </Box>
            <Box className='aft-column'>
                <Box className='aft-column-title'>Max Units to sell</Box>
                <Box className='aft-column-data'>
                    {loading ? <Skeleton /> : <NumberField value={data?.available ?? 0} />}
                </Box>
            </Box>
            <Box className='aft-column'>
                <Box className='aft-column-title'>Daily Volume</Box>
                <Box className='aft-column-data'>
                    {loading ? <Skeleton/> : <NumberField value={data?.day_volume ?? 0}/>}
                </Box>
            </Box>
        </Stack>
    )
}