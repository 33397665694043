import {Box, Button, CardActions} from "@mui/material";
import React, {useEffect, useState} from "react";
import DownloadIcon from '@mui/icons-material/Download';
import {useCustomMutation, useList} from "@refinedev/core";

const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    //@ts-ignore
    a.style = "display: none";
    return function (data, fileName) {
        const blob = new Blob([data]),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());

export const AftActionButton = ({aft_id, label, type}) => {
    const [currentFilename, setCurrentFilename] = useState("");
    const {mutate: custom} = useCustomMutation();
    const {data: dataDoc, isLoading: isLoadingDoc} = useList({
        resource: "documents",
        filters: [
            {
                field: "aftcontract_id",
                operator: "eq",
                value: aft_id,
            },
        ],
        pagination: {
            mode: "off"
        }
    });
    const handleDownload = (evt) => {
        custom({
            url: `/trading/aftcontract/document/download`,
            method: "post",
            values: {
                aftcontract_id: aft_id,
                filename: currentFilename
            }
        }, {
            onSuccess: ({data}) => {
                saveData(data, currentFilename)
            }
        })
    };
    useEffect(() => {
        if (!isLoadingDoc && currentFilename === "" && dataDoc?.data.length) {
            dataDoc?.data.forEach((file) => {
                if(file?.filename.includes(type)) {
                    setCurrentFilename(file?.filename);
                }
            })
        }
    }, [currentFilename, dataDoc, isLoadingDoc])
    return (
        <Box sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px'
        }}>

            <Box className="label">{label}</Box>
            <CardActions>
                <Button color={"info"} disabled={isLoadingDoc || currentFilename === ""} onClick={handleDownload}
                        className={'action-button'} startIcon={<DownloadIcon/>}>
                    <Box>Download</Box>
                </Button>
            </CardActions>
        </Box>
    )
}