import React from "react";
import {createRoot} from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "./i18n";
import { Box } from "@mui/material";
import LogoMini from "./assets/logo-whtexch-mini.png";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <React.Suspense
            fallback={<Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
                <img src={LogoMini} alt="Whtexch" className="logo"/>
            </Box>}>
            <App/>
        </React.Suspense>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
