import React, {useEffect, useState} from "react";
import './edit.scss'
import {ResetPasswordForm} from "./ResetPasswordForm";
import { Edit } from "@refinedev/mui";
import { Typography } from "@mui/material";
import { useGetIdentity, useOne } from "@refinedev/core";

export const CustomersEdit = () => {
    const {data: user} = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    return <Edit dataProviderName={'dummy'} isLoading={true} resource={'dummy'} breadcrumb={<></>} title={<Typography variant={"h5"}>NEW PASSWORD REQUEST</Typography>}
                 footerButtons={<></>} headerButtons={<></>}
                 wrapperProps={{
                     sx: {
                         maxWidth: "1100px",
                         margin: '0 auto'
                     },
                 }}>
        <ResetPasswordForm user={user}/>
    </Edit>
}