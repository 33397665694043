import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useModal, useModalReturnType, useNotification } from '@refinedev/core';
import React, { useState } from 'react';
interface OTPModalPropsInterface {
    onConfirm: (otp: string) => Promise<void>;
    title: string;
    message: string | React.ReactNode;
    confirmText: string,
    modal: useModalReturnType
}
export default function OTPModal({ onConfirm, title, message, confirmText, modal }: OTPModalPropsInterface) {


    const { open } = useNotification();
    const [otp, setOtp] = useState<string>()

    const handleConfirm = () => {
        if (!otp) {
            open?.({ message: 'OTP Field required', type: 'error' });
            return
        } else {
            onConfirm(otp).then(() => {
                modal.close();
            }).catch(err => {
                setOtp('');
            });
        }
    }





    return <Dialog onClose={() => modal.close()} open={modal.visible}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
            <Grid justifyContent={'center'} alignContent={'center'} direction={'column'} className='' container>

                <Grid alignContent={'start'} className='' item xs={6}>
                    {message}
                </Grid>
                <Grid direction={'row'} container>
                    <Grid className='' direction={'column'} item xs={5}></Grid>
                    <Grid className='' alignContent={'center'} item xs={2}>
                    <TextField label='OTP' variant={"outlined"} size={"small"}
                        inputProps={{
                            type: 'number',
                            pattern: '[0-9]*',
                        }}
                        sx={{
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none'
                            },
                            '& input[type=number]': {
                                MozAppearance: 'textfield'
                            },
                        }
                        }
                        onChange={(event) => {
                            event?.target?.value && setOtp(event?.target?.value)
                        }} />
                    </Grid>
                </Grid></Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={modal.close}>Cancel</Button>
            <Button onClick={handleConfirm}>{confirmText}</Button>
        </DialogActions>
    </Dialog>
};