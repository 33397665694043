import React, {useEffect, useState} from 'react'
import { Box, FormControl, MenuItem, Switch, Select, TextField, Typography, Button, Tooltip, FormHelperText, Alert, styled, TooltipProps, tooltipClasses } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import {useTranslation} from "react-i18next";
import './TradeTabForm.scss'
import { useModal } from "@refinedev/core";
import TradeTabModal from "./TradeTabModal";
import useDateTime from 'shared-libs/src/hooks/DateTime';
import {NumberField} from "../fields";
import { useTradeOpen } from './useTradeOpen';



const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'red',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid rgb(247, 134, 134)',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgb(247, 134, 134)',

        // backgroundColor: 'red'
    },

}));


export default function TradeTabForm({type, aft_data, balance_data, is_reloaded, reloadPage}) {
    const {t} = useTranslation();
    const {visible, show, close} = useModal();
    const [showStopLossOption, setShowStopLossOption] = useState(false)
    const [showProfitTakerOption, setShowProfitTakerOption] = useState(false)
    const [showValidityPeriodDate, setShowValidityPeriodDate] = useState(false)
    const [stopLossDisabled, setStopLossDisabled] = useState(true)
    const [profitTakerMinPrice, setProfitTakerMinPrice] = useState(0)
    const [stopLossMaxPrice, setStopLossMaxPrice] = useState(0)
    const [submitData, setSubmitData] = useState<any>()
    const { todayDate, dateFormat, tomorrowDateFormat, endOfDayFormat } = useDateTime()
    const tomorrowFormat = tomorrowDateFormat("-")


    console.log('aft_dataaaaa', aft_data, balance_data)

    const { isTradeOpen } = useTradeOpen()

    const maxQuantitySell = (type === 'sell') ? {
        rule: {
            max: {
                value: aft_data?.client?.available,
                message: "Maximum value: " + aft_data?.client?.available
            }
        },
        max: {
            max: aft_data?.client?.available
        }
    } : {

        rule: {
            max: {
                value: aft_data?.artwork?.number_of_tokens - (aft_data?.client?.available + aft_data?.client?.committed) - aft_data?.pending_buy,
                message: "Maximum value: " + (aft_data?.artwork?.number_of_tokens - (aft_data?.client?.available + aft_data?.client?.committed) - aft_data?.pending_buy)
            }
        },
        max: {
            max: aft_data?.artwork?.number_of_tokens - (aft_data?.client?.available + aft_data?.client?.committed) - aft_data?.pending_buy,
        }

    }

    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues,
        watch,
        reset,
        setValue
    } = useForm();
    const onFinish = (params) => {
        if (params.validity_period === 'good_till_cancel') {
            params.validity_period_date_to_send = ""
            params.validity_period_date = ""
        } else if (params.validity_period === 'good_till_date') {
            const _validity_period_date = new Date(params.validity_period_date)
            _validity_period_date.setDate(_validity_period_date.getDate() + 1)
            params.validity_period_date_to_send = dateFormat(_validity_period_date.toISOString())
            params.validity_period_date = dateFormat(params.validity_period_date)
        } else {
            params.validity_period_date_to_send = endOfDayFormat(aft_data?.square?.closing_time)
            params.validity_period_date = todayDate
        }
        params.type = type
        setSubmitData(params)
        show()
    };

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === 'stop_loss') {
                setShowStopLossOption(value.stop_loss === true && parseInt(value.limit_price) > 1)
            }
            if (name === 'profit_taker') {
                setShowProfitTakerOption(value.profit_taker === true)
            }
            if (name === 'validity_period') {
                setShowValidityPeriodDate(value.validity_period === 'good_till_date')
            }
            if (name === 'limit_price') {
                setProfitTakerMinPrice(parseInt(value.limit_price) + 1)
                setStopLossMaxPrice(parseInt(value.limit_price) - 1)
                setStopLossDisabled(parseInt(value.limit_price) <= 1 || isNaN(parseInt(value.limit_price)))
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (is_reloaded) {
            reset()
            setValue('quantity', aft_data?.min_token_quantity)
            setValue('limit_price', 1)
        }
    }, [is_reloaded])

    useEffect(() => {
        if (!getValues().quantity) {
            setValue('quantity', aft_data?.min_token_quantity)
        }
    }, [aft_data?.min_token_quantity])

    const hideAttachOrders = true

    return (
        <>
            <Box className={`trade-tab-form trade-tab-${type}`} component={"form"}
                noValidate
                 onSubmit={handleSubmit(onFinish)}>
                <TextField className='action-type' type={"hidden"} value={type} id={"action-type"}/>
                <FormControl className="trade-tab-form-row">
                    <Box className='trade-tab-form-row-title' component={"label"}>Units Quantity</Box>
                    <Controller
                        name="quantity"
                        control={control}
                        // defaultValue={aft_data?.min_token_quantity}
                        rules={{
                            required: {value: true, message: "required field"},
                            min: {
                                value: aft_data?.min_token_quantity,
                                message: "minimum " + aft_data?.min_token_quantity
                            },
                            validate: (value) => {
                                if (value % (aft_data?.min_token_increment ?? aft_data?.min_token_quantity) != 0) {
                                    const modulo = value % (aft_data?.min_token_increment ?? aft_data?.min_token_quantity)
                                    const error = `Please insert a valid value. The closest correct values are ${value - modulo} and ${value - modulo + (aft_data?.min_token_increment ?? aft_data?.min_token_quantity)}`
                                    return error;
                                }
                            },
                            ...maxQuantitySell.rule
                        }}
                        render={({field}) => {
                            console.log(errors)
                            let error = (errors.quantity) ? {
                                error: true,
                            } : {}
                            console.log(error)
                            return (<ErrorTooltip title={errors?.quantity?.message?.toString()}>
                                <TextField className='trade-tab-form-row-input' fullWidth
                                           id={`${type}-quantity`}
                                           variant="outlined"
                                           type={"number"}
                                           inputProps={{
                                               type: 'number',
                                               pattern: '[0-9]*',
                                               min: aft_data?.min_token_quantity,
                                            //    step: aft_data?.min_token_increment ?? aft_data?.min_token_quantity,
                                               defaultValue: aft_data?.min_token_quantity,
                                            //    ...maxQuantitySell.max
                                           }}
                                           {...field}
                                           {...error}
                                />
                            </ErrorTooltip>
                            )
                        }}
                    />
                    {/* {errors?.quantity ? <FormHelperText error>{errors.quantity.message?.toString()}</FormHelperText> : null} */}
                </FormControl>

                <FormControl className="trade-tab-form-row">
                    <Box className='trade-tab-form-row-title' component={"label"}>Unit Limit Price</Box>
                    <Controller
                        name="limit_price"
                        control={control}
                        rules={{
                            required: {value: true, message: "required field"},
                            min: {value: 1, message: "minimum 1"}
                        }}
                        render={({field}) => {
                            let error = (errors.limit_price) ? {error: true} : {}
                            return (<ErrorTooltip title={errors.limit_price?.message?.toString()}>
                                <TextField className='trade-tab-form-row-input' fullWidth
                                           id={`${type}-limit_price`}
                                           variant="outlined"
                                           type={"number"}
                                           inputProps={{
                                               type: 'number',
                                               pattern: '[0-9]*',
                                               min: 1
                                           }}
                                           {...field}
                                           {...error}
                                />
                            </ErrorTooltip>)
                        }}
                    />
                    {/* {errors?.limit_price ? <FormHelperText error>{errors.limit_price.message?.toString()}</FormHelperText> : null} */}
                </FormControl>
                <FormControl className="trade-tab-form-row">
                    <Box className='trade-tab-form-row-title' component={"label"}>Validity Period</Box>
                    <Controller
                        name="validity_period"
                        control={control}
                        rules={{ required: { value: true, message: 'required field' } }}
                        defaultValue={""}
                        render={({field}) => {
                            console.log('errrorrrr', errors.validity_period)
                            let error = (errors.validity_period) ? {
                                error: true
                            } : {}
                            return (<><ErrorTooltip title={errors.validity_period?.message?.toString()}>
                                <Select className='trade-tab-form-row-select' fullWidth
                                        id={`${type}-validity_period`}
                                        variant="outlined"
                                        {...field}
                                        {...error}
                                >
                                    <MenuItem value={""}>---</MenuItem>
                                    <MenuItem value={"day"}>Day</MenuItem>
                                    <MenuItem value={"good_till_cancel"}>Good till cancelled</MenuItem>
                                    <MenuItem value={"good_till_date"}>Good till date</MenuItem>
                                </Select>
                            </ErrorTooltip></>
                            )
                        }}
                    />
                    {/* {errors?.validity_period ? <FormHelperText error>{errors.validity_period.message?.toString()}</FormHelperText> : null} */}
                </FormControl>
                {showValidityPeriodDate && <FormControl className="trade-tab-form-row">
                    <Box className='trade-tab-form-row-title' component={"label"}>Validity Period Date</Box>
                    <Controller
                        name="validity_period_date"
                        control={control}
                        rules={{
                            required: { value: true, message: "required field" },
                            min: {value: tomorrowFormat, message: ""}
                        }}
                        render={({field}) => {
                            let error = (errors.validity_period_date) ? {error: true} : {}
                            return (
                                <TextField className='trade-tab-form-row-input' fullWidth
                                           id={`${type}-validity_period_date`}
                                           variant="outlined"
                                           type={"date"}
                                           inputProps={{min: tomorrowFormat}}
                                           {...field}
                                           {...error}
                                />
                            )
                        }}
                    />
                </FormControl>}

                {!hideAttachOrders && type === 'buy' && <Box className="trade-tab-form-row">
                    <Typography className='trade-tab-form-row-middle-title'>Attach Orders</Typography>
                </Box>}
                {!hideAttachOrders && type === 'buy' && <FormControl className="trade-tab-form-row">
                    <Box className='trade-tab-form-row-title' component={"label"}>Profit Taker</Box>
                    <Controller
                        name="profit_taker"
                        control={control}
                        defaultValue={false}
                        render={({field}) => {
                            return (
                                <Switch
                                    color='info'
                                    id={`${type}-profit_taker`}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                />
                            )
                        }}
                    />
                </FormControl>}
                {!hideAttachOrders && showProfitTakerOption && type === 'buy' &&
                    <FormControl className="trade-tab-form-row">
                        <Box className='trade-tab-form-row-title' component={"label"}>Profit Taker<br />Unit Limit Price</Box>
                        <Controller
                            name="pt_limit_price"
                            control={control}
                            rules={{
                                required: {value: true, message: "required field"},
                                min: {value: profitTakerMinPrice, message: "minimum 1"}
                            }}
                            defaultValue={profitTakerMinPrice}
                            render={({field}) => {
                                let error = (errors.pt_limit_price) ? {error: true} : {}
                                return (
                                    <TextField className='trade-tab-form-row-input' fullWidth
                                               id={`${type}-pt_limit_price`}
                                               variant="outlined"
                                               type={"number"}
                                               inputProps={{
                                                   type: 'number',
                                                   pattern: '[0-9]*',
                                                   min: profitTakerMinPrice,
                                                   defaultValue: profitTakerMinPrice
                                               }}
                                               {...field}
                                               {...error}
                                    />
                                )
                            }}
                        />
                    </FormControl>}
                {!hideAttachOrders && type === 'buy' && <FormControl className="trade-tab-form-row">
                    <Box className='trade-tab-form-row-title' component={"label"}>Stop Loss</Box>
                    <Controller
                        name="stop_loss"
                        control={control}
                        defaultValue={false}
                        render={({field}) => {
                            return (
                                <Switch
                                    color='info'
                                    id={`${type}-stop_loss`}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                    disabled={stopLossDisabled}
                                />
                            )
                        }}
                    />
                </FormControl>}
                {!hideAttachOrders && showStopLossOption && type === 'buy' &&
                    <FormControl className="trade-tab-form-row">
                        <Box className='trade-tab-form-row-title' component={"label"}>Stop Loss<br />Unit Limit Price</Box>
                        <Controller
                            name="sl_limit_price"
                            control={control}
                            rules={{
                                required: {value: true, message: "required field"},
                                min: {value: 1, message: ""},
                                max: {value: stopLossMaxPrice, message: ""}
                            }}
                            defaultValue={stopLossMaxPrice}
                            render={({field}) => {
                                let error = (errors.sl_limit_price) ? {error: true} : {}
                                return (
                                    <TextField className='trade-tab-form-row-input' fullWidth
                                               id={`${type}-sl_limit_price`}
                                               variant="outlined"
                                               type={"number"}
                                               inputProps={{
                                                   type: 'number',
                                                   pattern: '[0-9]*',
                                                   min: 1,
                                                   max: stopLossMaxPrice,
                                                   defaultValue: stopLossMaxPrice
                                               }}
                                               {...field}
                                               {...error}
                                    />
                                )
                            }}
                        />
                    </FormControl>}
                {!hideAttachOrders && (showProfitTakerOption || showStopLossOption) && type === 'buy' &&
                    <FormControl className="trade-tab-form-row">
                        <Box className='trade-tab-form-row-title' component={"label"}>Trigger Validity<br/>Period
                            Date</Box>
                        <Controller
                            name="trigger_validity_period_date"
                            control={control}
                            rules={{
                                required: { value: true, message: "required field" },
                                min: {value: tomorrowFormat, message: ""}
                            }}
                            render={({field}) => {
                                let error = (errors.trigger_validity_period_date) ? {error: true} : {}
                                return (
                                    <TextField className='trade-tab-form-row-input' fullWidth
                                               id={`${type}-trigger_validity_period_date`}
                                               variant="outlined"
                                               type={"date"}
                                               inputProps={{min: tomorrowFormat}}
                                               {...field}
                                               {...error}
                                    />
                                )
                            }}
                        />
                    </FormControl>}



                <FormControl className="trade-tab-form-row trade-tab-form-row-button">
                    {isTradeOpen === false && <Alert severity="warning">The market is closed!</Alert>}
                    {isTradeOpen === true && <Button color='info' type="submit" disabled={!balance_data || !aft_data || !aft_data.is_tradable}>Submit</Button>}
                </FormControl>
                {aft_data.is_tradable == false ? <Box><Typography align='center' color={'red'}>Trading is closed</Typography></Box> : null}

            </Box>
            {visible && <TradeTabModal setValue={setValue} modal={{ visible: visible, close: close }} data={submitData} aft={aft_data}
                                       balance_data={balance_data} reloadPage={reloadPage}/>}
        </>

    )
}