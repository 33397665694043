import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AppBar, Avatar, Button, MenuItem, Select, SelectChangeEvent, Stack, Toolbar, Typography, toggleButtonClasses } from "@mui/material";
import { useGetIdentity, useNavigation } from "@refinedev/core";
import { Announcements } from 'components/announcements/list';
import { useCurrencyStore } from 'hooks/useCurrency';
import { useEffect, useState, useSyncExternalStore } from "react";
import { LocalStorageProvider } from 'shared-libs/src/providers';

export const Header: React.FC = () => {
    const { data: user } = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const { push } = useNavigation()
    const gotoProfile = () => {
        push('/customers/show')
    }

    const [bcid, setBcid] = useState<string>(LocalStorageProvider.getSessionData('bcid'));
    useEffect(() => {
        console.log('new ', user?.hlf[0].bcid)
        if (user?.hlf[0].bcid && !bcid) {
            setBcid(user?.hlf[0].bcid)
        }
    }, [user])


    const handleChange = (event: SelectChangeEvent) => {
        const newBcid = event.target.value
        setBcid(newBcid);
        if (LocalStorageProvider.getSessionData('bcid') != newBcid) {
            LocalStorageProvider.setSessionData('bcid', newBcid)
            window.location.reload()
        }
    };

    let wallet_name = '';
    if (user?.hlf && user?.hlf.length > 0) {
        wallet_name = user.hlf[0].display_name + ' - '
    }

    const isStaging = process.env.REACT_APP_API_URL?.toLowerCase()?.includes('nohup') || process.env.REACT_APP_API_URL?.toLowerCase()?.includes('localhost')
    const isQA = process.env.REACT_APP_API_URL?.toLowerCase()?.includes('-qa');
    const additionalStyle = isStaging || isQA ? { borderTop: "10px solid " + (isQA ? "rgb(237,173,0)" : "rgb(173, 1,0)") } : {}

    const { currency, toggleCurrency } = useCurrencyStore()
    return (
        <AppBar style={additionalStyle} color="default" position="static" elevation={1}>
            <Toolbar>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Announcements />
                    <Stack direction="row" alignItems="center">
                        <Stack
                            direction="row"
                            gap="4px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            {/* TODO: restore */}
                            {user?.id && <Button variant="text" color="info" onClick={toggleCurrency}>{currency}</Button>}

                            {user?.hlf.length > 1 && <Select value={`${bcid}`} style={{ textTransform: 'uppercase' }} onChange={handleChange} sx={{ color: '#000' }}>
                                {user?.hlf?.map(el => <MenuItem style={{ textTransform: 'uppercase' }} value={el.bcid} >{el.company_name || `${user.firstname} ${user.lastname}`}</MenuItem>)}
                            </Select>}
                            {user?.hlf.length == 1 && <Typography textTransform={'uppercase'}>{user.hlf[0].company_name || `${user.profile_data?.firstname} ${user.profile_data?.lastname}`}</Typography>}
                            {user?.avatar ?
                                <Avatar onClick={gotoProfile} src={user?.avatar} alt={user?.firstname} /> :
                                <AccountCircleIcon onClick={gotoProfile} sx={{ color: '#334155' }} />}

                        </Stack>
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};