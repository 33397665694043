import Download from '@mui/icons-material/Download';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Tooltip,
    Typography
} from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { ShowButton, useDataGrid } from "@refinedev/mui";
import React, { useEffect, useRef } from 'react';

import { IAft, IAftLite, IAftLiteExtended } from 'shared-libs/src/interfaces';

import {
    useCustomMutation,
    useGetIdentity,
    useModal,
    useNavigation,
    useNotification,
    useTranslate,
} from "@refinedev/core";

import { NumberField } from "../fields";
import './AftPreMarketClosed.scss';
import './AftTable.scss';
import { useCurrencyStore } from 'hooks/useCurrency';

export default function AftPreMarketClosed({ balance }) {

    const t = useTranslate();
    const { data: user } = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    const { push } = useNavigation()
    const { open } = useNotification()
    const { visible, show, close } = useModal();
    const [quantity, setQuantity] = React.useState(0)
    const [inputError, setInputError] = React.useState(false)
    const [inputErrorMessage, setInputErrorMessage] = React.useState("")
    const [buyLoading, setBuyLoading] = React.useState(false)
    const [buyDisabled, setBuyDisabled] = React.useState(true)
    const [currentRecord, setCurrentRecord] = React.useState<any>()
    const [currentAmountHeld, setCurrentAmountHeld] = React.useState(-1)
    const { mutate: custom } = useCustomMutation()
    const inputElement = useRef<any>()

    useEffect(() => {
        if (balance && balance?.data?.available >= 0) {
            setCurrentAmountHeld(balance?.data?.available)
        }
    }, [balance])
    useEffect(() => {
        if (currentRecord && quantity != currentRecord?.min_token_quantity) {
            setBuyDisabled(false)
            setQuantity(currentRecord?.min_token_quantity)
        }
    }, [currentRecord])

    const { getValue } = useCurrencyStore()


    const { dataGridProps } = useDataGrid<IAft>({
        resource: "premarket",
        syncWithLocation: false,
        queryOptions: {
            queryHash: 'premarket-closed'
        },
        liveMode: 'auto',
        filters: {
            initial: [
                { field: 'premarket_sold_tokens', operator: 'eq', value: 0 },
            ],
            permanent: [
                {
                    field: "is_premarket",
                    operator: "eq",
                    value: false
                },
                {
                    field: "show_artwork",
                    operator: 'eq',
                    value: true
                },
                {
                    field: 'is_tradable',
                    operator: 'eq',
                    value: false
                },
                // { field: 'show_premarket', operator:'eq',value:true},
                { field: 'show_originator', operator: 'eq', value: true },
                { field: 'is_originator', operator: 'eq', value: true },
                { field: 'premarket_sold_tokens', operator: 'eq', value: 0 },
                { field: 'show_last_orders', operator: 'eq', value: true },
                // { field: 'show_price_history', operator:'eq',value:true},
                { field: 'show_artwork', operator: 'eq', value: true },
                { field: 'show_average_purchase_price', operator: 'eq', value: true },
                { field: 'has_sold_tokens_in_premarket', operator: 'eq', value: false },
                // { field: 'show_user_orders', operator:'eq',value:true},
            ]
        }
    });

    const handleCancel = () => {
        setBuyDisabled(false)
        setInputErrorMessage('')
        setInputError(false);
        close()
    }

    const columns = React.useMemo<GridColumns<IAftLite & IAftLiteExtended>>(
        () => [
            {
                field: "aftcontract_id",
                headerName: t("aft_id"),
                description: t("aft_id_tooltip"),
                headerAlign: "center",
                align: 'left',
                minWidth: 160,
                flex: 3,
                valueGetter: ({ row: { aftcontract_id, artwork: { work_of_ingenuity } } }) => {
                    return work_of_ingenuity || aftcontract_id;
                },
                renderCell: ({ value }) => {
                    return <Tooltip title={value}><Typography style={{ textOverflow: 'ellipsis' }}>{value}</Typography></Tooltip>
                }

            },
            {
                field: "price",
                headerName: t("price_per_unit"),
                // description: t("price_per_unit_tooltip"), NOTE: tolto su richiesta del cliente
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? getValue(value) : ""
                }
            },
            {
                field: "number_of_tokens",
                headerName: t("total_units_deposited"),
                // description: t("total_units_deposited_tooltip"),  NOTE: tolto su richiesta del cliente
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens } } }) => number_of_tokens,
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "original_deposit_value",
                headerName: t("original_deposit_value"),
                // description: t("original_deposit_value_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens }, price } }) => {
                    return number_of_tokens * price
                },
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? getValue(value) : ""
                }
            },
            {
                field: "tokens_bought_by_mkt",
                headerName: t("total_qty_purchased_by_mkt"),
                description: t("total_qty_purchased_by_mkt_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens }, originator: { committed, available } } }) => {
                    return number_of_tokens - (committed + available)
                },
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "tokens_residual_available",
                headerName: t("residual_qty_available"),
                description: t("residual_qty_available"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { originator: { committed, available } } }) => {
                    return (committed + available)
                },
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "deposit_business_days_left",
                headerName: t("deposit_business_days_passed"),
                description: t("deposit_business_days_left_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { premarket_closing_date } }) => {
                    // calculate days from premarket_date_limit
                    const deadlineDate = new Date(premarket_closing_date.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/, "$1-$2-$3 $4:$5:$6"))
                    return (((new Date().getTime() - deadlineDate.getTime()) / 1000 / 86400) >> 0)


                },
                renderCell: function render({ value }) {
                    // premarket_date_limit
                    return Number.isInteger(value) ? <NumberField value={value} /> : ""
                },
            },
            {
                field: "client_number_of_tokens",
                headerName: t("own_balance"),
                headerClassName: 'table-header-bg',
                description: t("own_balance_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { client: { available } } }) => {
                    return available
                },
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "client_aft_pieces",
                headerName: t("own_units_value"),
                headerClassName: 'table-header-bg',
                description: t("own_units_value_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { client: { available }, price } }) => {
                    return (available || 0) * price
                },
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? getValue(value) : ""
                }
            },
            {
                field: "views",
                type: "actions",
                headerName: "VIEW MASTERPIECE DETAILS",
                headerClassName: "center",
                sortable: false,
                disableColumnMenu: true,
                minWidth: 80,
                renderCell: function ({ row }) {
                    return <ShowButton variant="actionTable" color="info" hideText recordItemId={row?.aftcontract_id} resource={'aft'} />;
                }
            },
            {
                field: "actions",
                type: "actions",
                minWidth: 80,
                renderCell: function render({ row }) {
                    const isOriginator = row?.client.account_id == row?.originator?.account_id

                    return (
                        <Button disabled={!isOriginator} variant={isOriginator ? 'outlined' : 'buy'} size="small" onClick={() => {
                            push('/trade/download/show/' + row.aftcontract_id);
                        }}><Download /></Button>
                    );
                },
            },
        ], []
    );

    return dataGridProps.rowCount ? <>
        <Card sx={{ mb: '30px' }}>
            <CardHeader className="panel-header" title="Expired Masterpieces with no traded units in Primary market" />
            <CardContent sx={{ pl: '20px', pr: '20px' }}>
                <DataGrid
                    {...dataGridProps}
                    getRowClassName={({ row }) => {
                        const isOriginator = row?.client.account_id == row?.originator?.account_id
                        return isOriginator ? 'originator-row-bg' : ''
                    }}
                    getRowId={(node) => node.aftcontract_id}
                    columns={columns}
                    disableColumnSelector={true}
                    sortingMode='client'
                    filterMode='client'
                    hideFooterPagination={true}
                    hideFooter={true}
                    className='buy'
                    headerHeight={80}
                    getRowSpacing={(params) => {
                        return { top: 4, bottom: 4 }
                    }}
                    rowHeight={52}
                    sx={{
                        height: '236px',
                        border: 'none',
                        '& .MuiDataGrid-cell:not([data-field="aftcontract_id"]), & .MuiDataGrid-cell:not([data-field="aftcontract_id"]) p': {
                            fontSize: '0.8rem',
                        }
                    }}
                />
            </CardContent>
        </Card>

    </> : null
}