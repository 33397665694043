import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { DateField, List, NumberField, useDataGrid } from "@refinedev/mui";
import React, { useEffect } from "react";

import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { User } from "shared-libs/src/interfaces";
import { useCurrencyStore } from "hooks/useCurrency";
import { renderSeedFromBcid } from "./logs-list";


const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.value} />;
}
const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}

export const BankBuyList = ({ movement_type }: { movement_type: 'buy' | 'sell' }) => {

    const { getValue } = useCurrencyStore()

    const columns = React.useMemo<GridColumns<{
        movement_id: number,
        is_executed: number,
        bcid_user_data?: User,
        created_by_user_data?: User,
        approved_by_user_data?: User,
        confirmed_by_user_data?: User,
        seed: string, bcid: number
    }>>(() => [
        { field: "buy_request_id", headerName: "Movement ID", flex: 1, type: "number", headerAlign: "left" },
        { field: "user", headerName: "User", flex: 1, renderCell: ({ row }) => `${row.bcid_user_data?.profile_data?.firstname} ${row.bcid_user_data?.profile_data?.lastname}` },
        { field: "bcid", headerName: "BCID", flex: 1 },
        { field: "seed", headerName: "Seed", flex: 1, valueGetter: ({ row: { seed, bcid } }) => renderSeedFromBcid(seed, `${bcid}`) },
        { field: "created", type: "date", headerName: "CREATION DATE & TIME (UTC)", flex: 1, renderCell: dateRender },
        { field: "wht_amount", headerName: "Amount", flex: 1, renderCell: ({ value }) => getValue(value) },
    ], []);



    useEffect(() => {
        document.title = 'Whtexch - Bank';
    }, []);
    const { dataGridProps, tableQueryResult: { refetch } } = useDataGrid({
        pagination: {
            mode: "off"
        },
        resource: 'user_bank',
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            refetchInterval: 5000,
            queryHash: `${movement_type}user_bank`
        },
        sorters: {
            initial: [
                { field: 'buy_request_id', order: 'desc' }
            ]
        },
        filters: {
            permanent: [
                { field: 'movement_type', operator: 'eq', value: movement_type }
            ]
        }
    });

    console.log('dataGridProps', dataGridProps.rows)
    return (
        <List title='Buy Request'>
            <DataGrid getRowId={({ buy_request_id }) => buy_request_id}  {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};

