import {useNavigation, useTranslate} from "@refinedev/core";
import {ShowButton, useDataGrid} from "@refinedev/mui";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import { Button, Card, CardContent, CardHeader, Tooltip, Typography } from "@mui/material";
import React from 'react';
import { IAft, IAftLite, IAftLiteExtended } from 'shared-libs/src/interfaces';
import {NumberField} from "../fields";
import moment from "moment";
import { useCurrencyStore } from "hooks/useCurrency";

export default function AftTrade() {
    const t = useTranslate();
    const {push, show} = useNavigation();
    const { getValue } = useCurrencyStore()


    const {dataGridProps} = useDataGrid<IAft>({
        resource: "onmarket",
        syncWithLocation: false,
        queryOptions: {
            queryHash: 'trading-open'
        },
        liveMode: 'auto',
        filters: {
            initial: [
                { field: 'premarket_sold_tokens', operator: 'gt', value: 0 },
            ],
            permanent: [
                {
                    field: "is_premarket",
                    operator: "eq",
                    value: false,
                },
                {
                    field: "show_artwork",
                    operator: 'eq',
                    value: true
                },
                // { field: 'show_premarket', operator:'eq',value:true},
                { field: 'show_originator', operator: 'eq', value: true },
                { field: 'show_last_orders', operator: 'eq', value: true },
                { field: 'show_square', operator: 'eq', value: true },
                { field: 'show_artwork', operator: 'eq', value: true },
                { field: 'show_average_purchase_price', operator: 'eq', value: true },
                { field: 'has_sold_tokens_in_premarket', operator: 'eq', value: true },
                // { field: 'show_user_orders', operator:'eq',value:true},


            ]
        }
    });

    const handleClick = (event, row) => {
        show('trade', row.aftcontract_id)
    }


    const columns = React.useMemo<GridColumns<IAftLite & IAftLiteExtended>>(
        () => [
            {
                field: "aftcontract_id",
                headerName: t("aft_id"),
                description: t("aft_id_tooltip"),
                headerAlign: "center",
                align: 'left',
                minWidth: 160,
                flex: 3,
                valueGetter: ({ row: { aftcontract_id, artwork: { work_of_ingenuity } } }) => {
                    return work_of_ingenuity || aftcontract_id;
                },
                renderCell: ({ value }) => {
                    return <Tooltip title={value}><Typography>{value}</Typography></Tooltip>
                }
            },
            {
                field: "price",
                headerName: t("last_price_per_unit"),
                // description: t("price_per_unit_tooltip"), NOTE: tolto su richiesta del cliente
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? getValue(value) : ""
                }
            },
            {
                field: "number_of_tokens",
                headerName: t("total_units_deposited_in_primary"),
                // description: t("total_units_deposited_tooltip"),  NOTE: tolto su richiesta del cliente
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens } } }) => number_of_tokens,
                renderCell: function render({value}) {
                    return Number.isInteger(value) ? <NumberField value={value}/> : ""
                }
            },
            {
                field: "tokens_bought_by_mkt",
                headerName: t("total_qty_purchased_by_mkt"),
                description: t("total_qty_purchased_by_mkt_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { artwork: { number_of_tokens }, originator: { committed, available } } }) => {
                    return number_of_tokens - (committed + available)
                },
                renderCell: function render({value}) {
                    return Number.isInteger(value) ? <NumberField value={value}/> : ""
                }
            },
            {
                field: "client_avg_price",
                headerName: t("client_avg_price"),
                headerClassName: 'table-header-bg',
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: function ({ row: { client: { avg_price } } }) {
                    return avg_price;
                },  
                renderCell: function render({ value }) {
                    return !isNaN(value) ? getValue(value) : ""
                }
            },
            {
                field: "client_wht_expected_profit_loss",
                headerName: t("exp_pl"),
                headerClassName: 'table-header-bg',
                description: t("exp_pl_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: function ({ row: { client: { wht_expected_profit_loss } } }) {
                    return wht_expected_profit_loss;
                },  
                renderCell: function render({value}) {
                    return !isNaN(value) ? getValue(value) : ""
                }
            },
            {
                field: "client_number_of_tokens",
                headerName: t("own_balance"),
                headerClassName: 'table-header-bg',
                description: t("own_balance_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { client } }) => {
                    return ((client.committed || 0) + (client.available || 0))
                },
                renderCell: function render({ value }) {
                    return Number.isInteger(value) ? <NumberField value={value} /> : ""
                }
            },
            {
                field: "client_aft_pieces",
                headerName: t("own_units_value"),
                headerClassName: 'table-header-bg',
                description: t("own_units_value_tooltip"),
                headerAlign: "center",
                align: 'center',
                type: 'number',
                minWidth: 50,
                flex: 1,
                valueGetter: ({ row: { client, price } }) => {
                    return ((client.committed || 0) + (client.available || 0)) * price
                },
                renderCell: function render({value}) {
                    return Number.isInteger(value) ? getValue(value) : ""
                }
            },
            {
                field: "views",
                type: "actions",
                headerName: "VIEW MASTERPIECE DETAILS",
                headerClassName: "center",
                sortable: false,
                disableColumnMenu: true,
                minWidth: 80,
                renderCell: function ({row}) {
                    
                    return <ShowButton variant={row.client?.orders?.filter(el => el.status == 'o').length ? 'actionTableGreen' : "actionTable"} color="info" hideText recordItemId={row?.aftcontract_id}
                                       resource={'aft'}/>;
                }
            },
            {
                field: "actions",
                type: "actions",
                sortable: false,
                disableColumnMenu: true,
                minWidth: 80,
                renderCell: function render({ row: { is_tradable, square: { closing_time, opening_time } }, row }) {

                    const currentUtcHours = moment.utc().hours()

                    const isTradeOpen = currentUtcHours > opening_time && currentUtcHours < closing_time;
                    const isTradable = is_tradable;

                    const btn = <Button variant={row.client?.orders?.filter(el => el.status == 'o').length ? 'actionTableGreen' : (
                        isTradable ? undefined : 'tradingclosed'

                    )} color='info' size="small" onClick={(event) => handleClick(event, row)}>TRADE</Button>;

                    if (!isTradeOpen) {
                        return (
                            <Tooltip title={`Trading is only open between ${opening_time.toString().padStart(2, '0')} and ${closing_time.toString().padStart(2, '0')} UTC`}>{btn}</Tooltip>
                        );
                    } if (!isTradable) {
                        return (
                            <Tooltip title={`Trading is closed for this Masterpiece`}>{btn}</Tooltip>
                        );
                    } else {
                        return (
                            btn
                        );
                    }
                },
            },
        ], []
    );

    return <>
        <Card sx={{mb: '30px'}}>
            <CardHeader className="panel-header" title="Secondary Market"/>
            <CardContent sx={{pl: '20px', pr: '20px'}}>
                <DataGrid
                    getRowClassName={({ row }) => {
                        const isOriginator = row?.client.account_id == row?.originator?.account_id
                        return isOriginator ? 'originator-row-bg' : ''
                    }}
                    {...dataGridProps}
                    columns={columns}
                    getRowId={(node) => node.aftcontract_id}
                    disableColumnSelector={true}
                    sortingMode={'client'}
                    filterMode={'client'}
                    hideFooterPagination={true}
                    hideFooter={true}
                    getRowSpacing={(params) => {
                        return {top: 4, bottom: 4}
                    }}
                    className='trade'
                    headerHeight={80}
                    rowHeight={52}
                    sx={{
                        height: '236px',
                        border: 'none',
                        '& .MuiDataGrid-cell:not([data-field="aftcontract_id"]), & .MuiDataGrid-cell:not([data-field="aftcontract_id"]) p': {
                            fontSize: '0.8rem',
                        }
                    }}
                />
            </CardContent>
        </Card>

    </>
}