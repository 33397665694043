import { Edit, TagField, DateField } from "@refinedev/mui";
import { Box, TextField, Typography, Grid, Divider, Button } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import React, {useEffect} from "react";
import {TicketStatus} from "shared-libs/src/models";
import './messages.scss'
import { useCustomMutation, useUpdate } from "@refinedev/core";
import { LoadingButton } from "@mui/lab";

export const MessagesEdit = () => {
    useEffect(() => {
        document.title = 'Whtexch - Messages';
    }, []);
    const {
        saveButtonProps,
        refineCore: {queryResult, mutationResult},
        register,
        formState: {errors},
        handleSubmit,
        resetField
    } = useForm();

    const messagesData = queryResult?.data?.data;
    const { mutate: update, isLoading } = useUpdate();
    const { mutate: custom, isLoading: isCustomLoading } = useCustomMutation()

    const onFinish = (params) => {
        //@ts-ignore
        update({
            resource: "messages",
            id: messagesData?.ticket_id,
            values: params,
            mutationMode: "pessimistic"
        }, {
            onSuccess: (data, variables, context) => {
                resetField('text')
            }
        })

    }

    const close = (params) => {
        custom({
            url: `/ticket/close`,
            method: "post",
            values: {
                ticket_id: messagesData?.ticket_id,
                ...params
            }
        }, {
            onSuccess: (data, variables, context) => {
                resetField('text')
                queryResult?.refetch();
            }
        })
    }

    const isButtonLoading = queryResult?.isRefetching || mutationResult.isLoading || isLoading || isCustomLoading

    let disabledOption = {},
        canEdit = true,
        ActionButton = <LoadingButton variant="contained" loading={isButtonLoading} disabled={isButtonLoading} onClick={handleSubmit(close)}>Close Ticket</LoadingButton>
    if (TicketStatus.Closed === parseInt(messagesData?.ticket_status)) {
        disabledOption = {disabled: true}
        canEdit = false
        ActionButton = <></>
    }

    return (
        <Edit  title={<Typography variant="h5">Back</Typography>} 
            headerButtons={({ defaultButtons }) => ( <> </>)} 
            saveButtonProps={{...saveButtonProps, variant: 'save',  ...disabledOption, onClick: handleSubmit(onFinish)}}
            footerButtons={({defaultButtons}) => (
                  <>
                      {
                          canEdit &&
                          <>
                              {ActionButton}
                              <Divider orientation="vertical" flexItem color="tertiary" variant="middle"/>
                          </>
                      }
                      {defaultButtons}
                  </>
              )}
        >
            <Grid container spacing={2}>
                <Grid item xs={2}> <Typography variant="body2" fontWeight="bold">Ticket Status</Typography> </Grid>
                <Grid item xs={10}><TagField value={TicketStatus.getLabel(messagesData?.ticket_status)}/> </Grid>

                <Grid item xs={2}> <Typography variant="body2" fontWeight="bold">Created</Typography> </Grid>
                <Grid item xs={10}><DateField  format="YYYY/MM/DD" value={messagesData?.created}/></Grid>

                <Grid item xs={2}> <Typography variant="body2" fontWeight="bold">Updated</Typography> </Grid>
                <Grid item xs={10}><DateField  format="YYYY/MM/DD" value={messagesData?.updated}/></Grid>

                <Grid item xs={12}> </Grid>

                <Grid item xs={2}> <Typography variant="body2" fontWeight="bold">Subject</Typography> </Grid>
                <Grid item xs={10}><Typography variant="body1">{messagesData?.subject}</Typography></Grid>

                <Grid item xs={2}> <Typography variant="body2" fontWeight="bold">Messages</Typography> </Grid>
                <Grid item xs={10}>
                    <Box className={"message-box"}>
                        {messagesData?.message.map((msg) => {
                            let messageTypeCssClass = (msg.user_id === messagesData?.user_id) ? 'client' : 'admin'
                            return <Box key={msg.tm_id} className={`message-item ${messageTypeCssClass}`}>
                                <Box className='message-time'>{msg.created}</Box>
                                <Box dangerouslySetInnerHTML={{__html: msg.message}}/>
                            </Box>
                        })}
                    </Box>
                </Grid>

                {
                    canEdit &&
                    <>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                            <Box
                                component="form"
                                sx={{display: "flex", flexDirection: "column"}}
                                autoComplete="off"
                            >
                                <TextField
                                    {...register("text", {
                                        required: "This field is required",
                                    })}
                                    error={!!(errors as any)?.text}
                                    helperText={(errors as any)?.text?.message}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    label="Reply"
                                    name="text"
                                    multiline={true}
                                    minRows={5}
                                />
                            </Box>
                        </Grid>
                    </>
                }
            </Grid>
        </Edit>
    );
};
