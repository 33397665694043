import { Box, Button, Link } from "@mui/material";
import React, {useEffect} from 'react'
import './IndexPage.scss'

import {FullPage} from '../layouts'
import Logo from "../assets/logo-whtexch.png";

export function IndexPage() {
    const [showVideo, setShowVideo] = React.useState(!!(localStorage.getItem('vh') ?? 1))
    const [videoClass, setVideoClass] = React.useState("")
    let video;
    const handleEndVideo = () => {
        localStorage.setItem('vh', "")
        setVideoClass('hide')
    }
    useEffect(() => {
        document.addEventListener('keydown', (evt) => {
            if (evt.key === 'Escape') {
                localStorage.setItem('vh', '')
                setShowVideo(false)
            }
        })
    })
    return (
        <FullPage>
            <Box className='home-buttons'>
                <Link underline='none' href={"/login"}>
                    <Button color='secondary' size='large'>LOGIN</Button>
                </Link>
                <Link underline='none' href={"/register"}>
                    <Button color='secondary' size='large'>REGISTER</Button>
                </Link>
            </Box>
        </FullPage>
    );
}