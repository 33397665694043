
export const tooltip = Tooltip => Typography => ({ text, tooltip }) => {
    return (
        <Tooltip title={tooltip} arrow>
            <Typography
                component="span"
                style={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'dotted',
                    cursor: 'help', // Cambia il cursore quando si passa sopra il testo
                }}
            >
                {text}
            </Typography>
        </Tooltip>
    );
};