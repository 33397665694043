import React, {useEffect, useState} from 'react'
import { Box, Button, FormControl, Grid, Skeleton, TextField, Typography } from "@mui/material";
import './Login.scss'
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { useNotification, LayoutWrapper, useNavigation } from "@refinedev/core";
import LogoWhtexch from '../assets/logo-whtexch.png'
import {RequestProvider} from 'shared-libs/src/providers';
import { Link } from "react-router-dom";
import {FullPage} from 'layouts/FullPage';


export function ForgotPassword() {
    useEffect(() => {
        document.title = 'Whtexch - Forgot Password';
    }, []);
    const {push} = useNavigation()
    const {open} = useNotification();
    const [step, setStep] = useState(1)
    const [buttonLabel, setButtonLabel] = useState('Continue')
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [qrImageUrl, setQrImageUrl] = useState("")
    const [qrCode, setQrCode] = useState("")
    const qrImageDimension = 200
    const dataToSend = {email: '', authentication_code: '', password: '', otp_secret: '', otp_now: ''}
    const {
        control,
        handleSubmit,
        formState: {errors},
        getValues,
        setError,
    } = useForm();
    const catchFunction = (response) => {
        if (open) {
            setButtonDisabled(false)
            open({
                message: response?.data?.message ?? response?.error ?? response?.message ?? 'An error has occurred. Please, contact our customer care',
                type: "error"
            });
        }
    }
    const onFinishEmail = () => {
        // console.log('finish')
        let values = getValues()
        RequestProvider.requestForChangeSignin(values.email).then((response) => {
            // console.log(response)
            if (response?.data?.result) {
                dataToSend.email = values.email
                RequestProvider.getOTP(dataToSend.email)
                    .then((response) => {
                        //console.log(response.data)
                        setQrImageUrl("data:image/png;base64, " + response.data.qrcode)
                        setQrCode(response.data.code)
                    }).catch(error => {
                    //console.log(error)
                })
                setButtonLabel('Change')
                setStep(2)
            } else {
                catchFunction(response)
            }
        }).catch(response => catchFunction(response))
    };
    const onFinish = () => {
        setButtonDisabled(true)
        const values = getValues()
        // console.log(values)
        RequestProvider.changeSignin(values.email, values.password, values.otp_now, qrCode, values.authentication_code).then((response) => {
            if (response?.data?.user?.user_id) {
                if (open) {
                    open({
                        message: 'password changed!',
                        type: "success"
                    });
                    setTimeout(() => {
                        push('/')
                    }, 5000)
                }
            } else {
                catchFunction(response)
            }
        }).catch(response => catchFunction(response))
    }
    return (
        <FullPage>
            <Box component={"form"} onSubmit={(e) => {
                if (step === 1) {
                    handleSubmit(onFinishEmail)(e).catch(catchFunction);
                } else if (step === 2) {
                    const values = getValues();
                    let hasErrors

                    if (values.password != values.confirm_password) {
                        setError('confirm_password', { message: 'Confirm password is not equal to Password' });
                        hasErrors = true;
                    }
                    handleSubmit(onFinish)(e).catch(catchFunction);
                }
            }}>
                <FormControl className='login-form'>
                    <Controller
                        name="email"
                        control={control}
                        rules={{required: true, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/}}
                        render={({field}) => {
                            let error = (errors.email) ? {error: true, helperText: "required field"} : {}
                            return (
                                <TextField fullWidth
                                           id="login-email"
                                           label="Email"
                                           variant="outlined"
                                           disabled={step > 1}
                                           {...error}
                                           {...field}
                                />
                            )
                        }}
                    />
                    {step >= 2 && <>
                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: true,
                                pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                            }}
                            render={({field}) => {
                                let error = (errors.password) ? {
                                    error: true,
                                    helperText: 'field must have at least two uppercase letters, one special case letter, two digits, three lowercase letter and at least a length of 8'
                                } : {}
                                return (
                                    <TextField fullWidth
                                               id="register-password"
                                               label="Password"
                                               type="password"
                                               variant="outlined"
                                               className='form-password-input'
                                               disabled={step > 2}
                                               {...error}
                                               {...field}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="confirm_password"
                            control={control}
                            rules={{
                                required: true,
                                pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                            }}
                            render={({ field }) => {
                                let error = (errors.confirm_password) ? {
                                    error: true,
                                    helperText: errors.confirm_password?.message ? errors.confirm_password?.message.toString() : 'field must have at least two uppercase letters, one special case letter, two digits, three lowercase letter and at least a length of 8'
                                } : {}
                                return (
                                    <TextField fullWidth
                                        id="register-confirm-password"
                                        label="Confirm Password"
                                        type="password"
                                        variant="outlined"
                                        className='form-password-input'
                                        disabled={step > 2}
                                        {...error}
                                        {...field}
                                    />
                                )
                            }}
                        />

                        <Typography marginBottom={'40px'}>To complete the reset it is necessary to activate the two-factor authentication via One Time Password (OTP). Please scan the below QR Code with the Google Authenticator Application
                        </Typography>
                        <Grid container spacing={5} textAlign={'center'}>
                            <Grid item xs={12}>
                                <Box className="otp-qr-placeholder">
                                    {qrImageUrl ?
                                        <img src={qrImageUrl} height={qrImageDimension} alt={"QR Code"}/> :
                                        <Skeleton height={qrImageDimension} width={qrImageDimension}
                                                  variant={"rectangular"}/>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {qrCode ? <Typography marginBottom={'40px'}>You can also set up manually the OTP by entering the following setup key in the Google Authenticator App: <strong>{qrCode}</strong></Typography> :
                                <Skeleton variant={"rectangular"} />}
                        </Grid>

                        <Typography marginBottom={'40px'}>Once activated, enter the OTP code:</Typography>
                        <Controller
                            name="otp_now"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                let error = (errors.otp_now) ? {error: true, helperText: "required field"} : {}
                                return (
                                    <TextField fullWidth
                                               id="register-otp_now"
                                               label="Insert OTP"
                                               variant="outlined"
                                               disabled={step > 2}
                                               {...error}
                                               {...field}
                                    />
                                )
                            }}
                        />
                        <Typography marginBottom={'40px'}>Insert the authentication code received by email</Typography>
                        <Controller
                            name="authentication_code"
                            control={control}
                            rules={{required: true}}
                            render={({field}) => {
                                let error = (errors.authentication_code) ? {
                                    error: true,
                                    helperText: "required field"
                                } : {}
                                return (
                                    <TextField fullWidth
                                               id="login-authentication_code"
                                               label="Authentication Code"
                                               variant="outlined"
                                               disabled={step > 2}
                                               {...error}
                                               {...field}
                                    />
                                )
                            }}
                        />
                    </>}
                    <FormControl>
                        <Button color='secondary' type="submit" disabled={buttonDisabled}>{buttonLabel}</Button>
                    </FormControl>
                    <FormControl className={"back-bottom-container"}>
                        <Button variant={"text"} className='button-link'>
                            <Link className="link" to={"/"}>back</Link>
                        </Button>
                    </FormControl>
                </FormControl>
            </Box>
        </FullPage>
    );
}