import React, { useEffect, useState } from "react";

import { Refine } from "@refinedev/core";
import { notificationProvider, RefineSnackbarProvider, Layout, ErrorComponent } from "@refinedev/mui";
import { ThemeProvider } from "@mui/material/styles";
import LocalFireDepartment from '@mui/icons-material/LocalFireDepartment';
import { CssBaseline, GlobalStyles, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

import routerProvider from "@refinedev/react-router-v6/legacy";
import dataProvider from "shared-libs/src/providers/DataProviderWhtexch";
import liveProvider from "shared-libs/src/providers/LiveProviderWhtexch";
import { Auth, LocalStorageProvider, RequestProvider } from "shared-libs/src/providers"
import { Login, IndexPage, Register, Dashboard } from "./pages";
import Logo from 'assets/logo-whtexch.png'
import LogoMini from 'assets/logo-whtexch-mini.png'
import { Header } from "./components";
import Trade from "./pages/trade/show";
import { overridedLightTheme } from './theme'
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { NewsShow, NewsList } from "./pages/news";
import ChatIcon from '@mui/icons-material/Chat';
import { MessagesEdit, MessagesList, MessagesCreate } from "./pages/messages";
import './App.scss'
import { AftList, AftShow } from "./pages/aft";
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import { CustomersEdit, CustomersShow } from "./pages/customer";
import { OrdersList } from "./pages/orders";
import { ForgotPassword } from "./pages/ForgotPassword";
import { HistoryList } from "pages/history/list";
import { TermsAndConditionsPage } from "components/terms_and_conditions";
import { Contacts } from "pages/Contacts";
import { Download } from "pages/download";
import { BankTabs } from "pages/bank";
import { BurnAudit } from "pages/burned/BurnedLogs";
import Decrypt from "pages/Decrypt";
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';
function App() {

    const { t, i18n } = useTranslation();
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    let token = LocalStorageProvider.getWsToken();
    const [_token, setToken] = useState(token)


    const refreshWsToken = () => {
        if (!token && !_token) {
            Auth.checkAuth().then(() => {
                if (RequestProvider.user?.hlf[0]?.bcid) {
                    RequestProvider.fetchWsToken().then(() => {
                        setToken(LocalStorageProvider.getWsToken());
                    })
                }
            }).catch(err => { })
        }
    }

    const onLoginComplete = () => {
        let token = LocalStorageProvider.getWsToken();
        setToken(token)
        refreshWsToken()
    }

    useEffect(() => {
        refreshWsToken()
    }, [RequestProvider.user?.hlf[0]?.bcid])
    useEffect(() => {
        if (!LocalStorageProvider.getSessionData('bcid')) {
            const bcid = LocalStorageProvider.getSessionData('user')?.hlf[0]?.bcid
            if (bcid) {
                LocalStorageProvider.setSessionData('bcid', bcid)
            }
        }
    }, [])

    return (
        <ThemeProvider theme={overridedLightTheme}>
            <CssBaseline />
            <GlobalStyles styles={{
                html: { WebkitFontSmoothing: "auto" },
                ':root': {
                    '--color-primary': overridedLightTheme.palette.info.main,
                    '--color-primary-text': overridedLightTheme.palette.info.contrastText,
                    '--color-secondary': overridedLightTheme.palette.secondary.main,
                    '--color-secondary-bg': overridedLightTheme.palette.secondary.contrastText,
                    '--color-secondary-text': overridedLightTheme.palette.text.secondary,
                    '--color-warning': overridedLightTheme.palette.warning.main,
                    '--color-warning-text': overridedLightTheme.palette.warning.contrastText,
                    '--breakpoint-xs': overridedLightTheme.breakpoints.values.xs,
                    '--breakpoint-sm': overridedLightTheme.breakpoints.values.sm,
                    '--breakpoint-md': overridedLightTheme.breakpoints.values.md,
                    '--breakpoint-lg': overridedLightTheme.breakpoints.values.lg,
                    '--breakpoint-xl': overridedLightTheme.breakpoints.values.xl,
                },
            }} />
            <RefineSnackbarProvider>
                <Refine
                    notificationProvider={notificationProvider}
                    Layout={Layout}
                    ReadyPage={IndexPage}
                    LoginPage={() => <Login onLoginComplete={onLoginComplete} />}
                    catchAll={<ErrorComponent />}
                    legacyAuthProvider={Auth}
                    dataProvider={dataProvider(process.env.REACT_APP_API_URL as string)}
                    liveProvider={_token ? liveProvider(process.env.REACT_APP_WS_URL as string) : undefined}
                    options={{ liveMode: "auto" }}
                    i18nProvider={i18nProvider}
                    resources={[
                        {
                            name: 'trade',
                            show: Trade,
                            meta: { hide: true }
                        }, 
                        {
                            name: 'download',
                            show: Download,
                            meta: { parent: 'trade' }
                        }, 
                        {
                            name: 'news',
                            list: NewsList,
                            show: NewsShow,
                            meta: { label: "News" },
                            icon: <NewspaperIcon />
                        }, 
                        {
                            name: 'messages',
                            list: MessagesList,
                            edit: MessagesEdit,
                            create: MessagesCreate,
                            meta: { label: "Tickets" },
                            icon: <ChatIcon />
                        }, 
                        {
                            name: 'download_burn',
                            list: BurnAudit,
                            meta: { label: "Download & Burn" },
                            icon: <LocalFireDepartment />
                        },
                        {
                            name: 'decrypt',
                            list: Decrypt,
                            meta: { label: "Decrypt" },
                            icon: <NoEncryptionGmailerrorredIcon />
                        },
                        {
                            name: 'terms',
                            list: TermsAndConditionsPage,
                            meta: { label: "Market Information" },
                            icon: <ChatIcon />
                        }, 
                        {
                            name: 'history',
                            list: HistoryList,
                            meta: { label: "History" },
                            icon: <ChatIcon />
                        }, 
                        {
                            name: 'aft',
                            list: AftList,
                            show: AftShow,
                            meta: { label: "Masterpieces" },
                            icon: <ArticleIcon />
                        },
                        {
                            name: 'customers',
                            show: CustomersShow,
                            edit: CustomersEdit,
                            meta: { hide: true, label: "Settings" },
                            icon: <SettingsIcon />
                        },
                        {
                            name: 'orders',
                            show: OrdersList,
                            meta: { hide: true }
                        },
                        {
                            name: 'bank',
                            list: BankTabs,
                            meta: { label: 'Bank' }
                        }
                    ]}
                    DashboardPage={Dashboard}
                    Header={Header}
                    Title={({ collapsed }) => (
                        <Link href="/">
                            {collapsed ?
                                (<img src={LogoMini} alt="Whtexch" className="logo" />) :
                                (<img src={Logo} alt="Whtexch" className="logo" width={180} />)
                            }
                        </Link>
                    )}
                    legacyRouterProvider={{
                        ...routerProvider,
                        routes: [
                            {
                                element: <IndexPage />,
                                path: "/",
                            }, {
                                element: <Login onLoginComplete={onLoginComplete} />,
                                path: "/login",
                            }, {
                                element: <ForgotPassword />,
                                path: "/forgot",
                            }, {
                                element: <Register />,
                                path: "/register",
                            }, {
                                element: <Register isEmployee={true} />,
                                path: "/register/employee/:email",
                            }, {
                                element: <Dashboard />,
                                path: "/dashboard",
                            }, {
                                element: <Contacts />,
                                path: '/contacts'
                            }
                        ]
                    }}
                />
            </RefineSnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
