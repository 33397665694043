import { Box, Typography } from '@mui/material';
import { OrdersTable } from 'pages/customer/OrdersTable';
import React from 'react'

export const HistoryList = ({ }) => {
    return (
        <React.Fragment>
            <Box sx={{ displax: 'flex' }} className="page-title-container">
                <Box>
                    <Typography variant="h1">Orders' history</Typography>
                </Box>
            </Box>
            <OrdersTable />
        </React.Fragment>
    );
}