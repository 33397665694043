import { useShow, useOne } from "@refinedev/core";
import { Show } from "@refinedev/mui";
import { Box, Typography } from "@mui/material";

export const NewsShow = () => {
    const {queryResult} = useShow();
    const {data, isLoading} = queryResult;

    const record = data?.data;

    const {isLoading: newsIdIsLoading} = useOne({
        resource: "news",
        id: record?.news_id || "",
        queryOptions: {
            enabled: !!record,
        },
    });

    return (
        <Show title={<Typography variant="h5">Back</Typography>} isLoading={isLoading} wrapperProps={{
            sx: {
                maxWidth: "1100px",
                margin: '0 auto'
            },
        }} breadcrumb={false} headerButtons={({defaultButtons}) => <></>}>
            {newsIdIsLoading ? "Loading..." :
                <Box dangerouslySetInnerHTML={{__html: record?.body}}/>
            }
        </Show>
    );
};
