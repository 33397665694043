import { toLocaleStringSupportsOptions } from 'components/fields';
import { useEffect, useState, useSyncExternalStore } from 'react';

export const CURRENCY_EUR = 'EUR';
export const CURRENCY_WHT = 'WHT';


export const MULTIPLIER = {
    [CURRENCY_EUR]: 0.01,
    [CURRENCY_WHT]: 1
}

export const LABELS = {
    [CURRENCY_EUR]: "€",
    [CURRENCY_WHT]: "WHT"
}

const LOCAL_STORAGE_KEY = 'WHT_CURRENT_CURRENCY'

let listeners: any = [];
let currency: 'EUR' | 'WHT' = localStorage.getItem(LOCAL_STORAGE_KEY) == CURRENCY_EUR ? CURRENCY_EUR : CURRENCY_WHT

const setCurrency = (_currency) => {
    currency = _currency
}
export const useCurrencyStore = () => {


    // const [currency, setCurrency] = useState(_currency)

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEY, currency)
    }, [currency])



    const store = {
        toggleCurrency() {
            setCurrency(currency == CURRENCY_EUR ? CURRENCY_WHT : CURRENCY_EUR)
            localStorage.setItem(LOCAL_STORAGE_KEY, currency == CURRENCY_EUR ? CURRENCY_WHT : CURRENCY_EUR)
            emitChange();
        },
        subscribe(listener) {
            listeners = [...listeners, listener];
            return () => {
                listeners = listeners.filter(l => l !== listener);
            };
        },
        getSnapshot() {
            return currency;
        },
        getValue(valueWht, locale = 'en-US', options = {}) {
            const number = valueWht * MULTIPLIER[currency]
            return (toLocaleStringSupportsOptions()
                ? clearNegativeZero(number.toLocaleString(locale, options))
                : number.toString()) + " " + LABELS[currency]
        },
        convert(valueWht, currency: 'EUR' | 'WHT' = 'EUR') {
            return ((valueWht * MULTIPLIER[currency] * 100) >> 0) / 100
        },
        convertFromEur(valueEur, toCurrency: 'EUR' | 'WHT' = 'EUR') {
            return valueEur * (1 / MULTIPLIER[toCurrency == 'EUR' ? 'WHT' : 'EUR'])
        }

    }

    const currencySynced = useSyncExternalStore(store.subscribe, store.getSnapshot)

    return { ...store, currency: currencySynced }
};

function clearNegativeZero(value) {
    if (value === "-0") {
        return "0"
    }
    return value;
}

function emitChange() {
    for (let listener of listeners) {
        listener();
    }
}
