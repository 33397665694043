import React, {useEffect} from "react";
import { useDataGrid, DateField, TagField, ShowButton } from "@refinedev/mui";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import './list.scss'
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { useList } from "@refinedev/core";

export const AftList = () => {
    useEffect(() => {
        document.title = 'Whtexch - AFT List';
    }, []);
    const {dataGridProps} = useDataGrid({
        pagination: {
            mode: "off"
        },
        syncWithLocation: false,

        resource: 'report_list',
        filters: {
            permanent: [
                { field: 'show_user_orders', operator: 'eq', value: true },
                { field: 'show_artwork', operator: 'eq', value: true },
            ]
        },
        queryOptions: {
            ...GlobalConfig.queryOptions
        }
    });

    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "aftcontract_id",
                headerName: "Masterpiece ID",
                valueGetter: ({ row: { artwork, aftcontract_id } }) => {
                    return artwork.work_of_ingenuity || aftcontract_id
                },
                flex: 1,
            },
            {
                field: "creation_date",
                headerName: "Date of upload",
                flex: 1,
                renderCell: ({value}) => <DateField format="YYYY/MM/DD" value={value}/>
            },
            {
                field: "is_premarket",
                headerName: "Market",
                flex: 1,
                renderCell: ({value}) => <TagField value={value ? 'primary' : 'secondary'}/>
            },
            {
                headerName: "Actions",
                field: "actions",
                flex: 1,
                disableColumnMenu: true,
                sortable:false,
                renderCell: function render({row}) {
                    return <ShowButton variant={row?.client?.active_orders?.length ? 'actionTableGreen' : "actionTable"} hideText recordItemId={row.aftcontract_id} />;
                },
            },

        ],
        [],
    );

    return (
        <React.Fragment>
            <Box sx={{ displax: 'flex' }} className="page-title-container">
                <Box>
                    <Typography variant="h1">Report</Typography>
                    {/* <Typography variant="h2">Sottotiolo</Typography> */}
                </Box>
            </Box>

            <Card sx={{mb: 2}}>
                <CardHeader className="panel-header" title="AFT List"></CardHeader>
                <CardContent className="card-whtex">
                    <DataGrid
                        getRowId={({ aftcontract_id }) => aftcontract_id}
                        {...dataGridProps}
                        columns={columns}
                        autoHeight
                        {...GlobalConfig.dataGridConfig}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    );
};
