import {createTheme} from "@mui/material";
import { LightTheme } from "@refinedev/mui";
import { margin, minWidth, textTransform } from "@mui/system";

declare module '@mui/material/styles' {
    interface TypographyVariants {
        inline: React.CSSProperties;
    }
    
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        inline?: React.CSSProperties;
    }

    interface Palette {
        tertiary: Palette['primary'];
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
    }

}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        buy: true;
        sell: true;
        dialogClose: true;
        cta: true;
        create: true;
        edit: true;
        save: true;
        actionTable: true;
        actionTableGreen: true;
        tradingclosed: true;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        inline: true;
    }
}

export const overridedLightTheme = createTheme({
    ...LightTheme,
    palette: {
        ...LightTheme.palette,
        primary: {
            main: '#f5f6fa',
            contrastText: '#000000',
        },
        info: {
            main: '#00aded',
            contrastText: '#000000',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#f6f7f9',
        },
        tertiary: {
            ...LightTheme.palette.secondary,
            main: '#ffffff',
            contrastText: '#f6f7f9'
        },
        background: {
            default: '#ebeef2'
        },
        text: {
            primary: '#334155',
            secondary: '#'
        },
    },
    components: {
        ...LightTheme.components,
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            variants: [
                {
                    props: { variant: 'create' },
                    style: {
                        color: '#334155',
                        padding: '6px 26px',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        backgroundColor: '#f6f7f9',
                        '&:hover': {
                            backgroundColor: '#f6f7f9'
                        }
                    },
                },
                {
                    props: { variant: 'save' },
                    style: {
                        color: '#fff',
                        padding: '4px 26px',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        backgroundColor: '#025002',
                        '&:hover': {
                            backgroundColor: '#025002'
                        }
                    },
                },
                {
                    props: { variant: 'edit' },
                    style: {
                        color: '#334155',
                        borderRadius: '100px',
                        width: '30px',
                        height: '30px',
                        '&:hover': {
                            color: '#fff',
                            backgroundColor: '#334155'
                        }
                    },
                },
                {
                    props: { variant: 'sell' },
                    style: {
                        color: '#fff',
                        padding: '4px 26px',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        backgroundColor: '#b90e31',
                        '&:hover': {
                            backgroundColor: '#950925',
                        }
                    },
                },
                {
                    props: { variant: 'buy' },
                    style: {
                        color: '#fff',
                        padding: '4px 26px',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        backgroundColor: '#008000',
                        '&:hover': {
                            backgroundColor: '#025002'
                        }
                    },
                },
                {
                    props: { variant: 'cta' },
                    style: {
                        color: '#29b0d1',
                        padding: '0',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        borderBottom: '1px solid #29b0d1',
                        borderRadius: 0,
                        '&:hover': {
                            backgroundColor: '#fff',
                        }
                    },
                },
                {
                    props: { variant: 'tradingclosed' },
                    style: {
                        color: '#fff',
                        padding: '4px 26px',
                        lineHeight: 'inherit',
                        letterSpacing: '1px',
                        backgroundColor: '#F89235',
                        '&:hover': {
                            backgroundColor: 'rgb(221, 129, 43)',
                        }
                    },
                },
                {
                    props: { variant: 'dialogClose' },
                    style: {
                        color: '#334155',
                        padding: '4px 26px',
                        lineHeight: 'inherit',
                        // letterSpacing: '1px',
                        '&:hover': {
                            backgroundColor: 'none'
                        }
                    },
                },
                {
                    props: { variant: 'actionTable' },
                    style: {
                        color: '#29b0d1',
                        lineHeight: 'inherit',
                        '&:hover': {
                            backgroundColor: '#fff',
                        }
                    },
                },
                {
                    props: { variant: 'actionTableGreen' },
                    style: {
                        color: '#29d16d',
                        lineHeight: 'inherit',
                        '&:hover': {
                            backgroundColor: '#fff',
                        }
                    },
                },
            ],
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...(ownerState.variant === 'contained' && {
                        color: '#fff',
                        padding: '4px 26px',
                        lineHeight: 'inherit',
                        backgroundColor: '#29b0d1',
                        letterSpacing: '1px'
                    }),
                })
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    top: '53px',
                    lineHeight: '1.3'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'center'
                }
            }
        },
        MuiCardHeader : {
            styleOverrides: {
                action: {
                    margin: 0
                }
            }
        },
        // @ts-ignore
        MuiDataGrid: {
            styleOverrides: {
                columnHeaderTitleContainer: {
                    justifyContent: 'center !important',
                },
                columnHeader: {
                    color: '#000',
                    backgroundColor: '#ffffff',
                    textAlign: 'center'
                },
                row: {
                    /* backgroundColor: '#f5f6fa', */
                    margin: '0 !important'
                },
                columnSeparator: {
                    color: 'transparent'
                },
                columnHeaderTitle: {
                    whiteSpace: 'break-spaces',
                    lineHeight: '1.3em',
                    textTransform: 'uppercase',
                    fontSize: '11px',
                    fontWeight: '700'
                },
                cell : {
                    textAlign: 'center',
                    justifyContent: 'center !important',
                    fontSize: '15px'
                },
                footerContainer: {
                    borderTop: 'none'
                },
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    marginBottom: '20px',
                    fontSize: '14px',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    backgroundColor: '#f6f7f9'
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px',
                    minHeight: '100px',
                    minWidth: '400px'
                }
            }
        },
        MuiDialogActions:  {
            styleOverrides: {
                root: {
                    padding: '30px 20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderTop: '1px solid #ebeef2',
                    gap: '20px'
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: '#00aded'
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "#1f2937 !important"
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: "#1f2937 !important"
                }
            }
        }

    },
    breakpoints: {
        ...LightTheme.breakpoints,
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: [
            "Nunito",
            "Arial",
            "sans-serif",
        ].join(","),
        h4: {
            fontSize: '20px',
            marginBottom: '10px'
        },
        inline: {
            lineHeight: 'inherit'
        },
        body2: {
            marginBottom: '15px',
            fontSize: '15px'
        }
    }
})