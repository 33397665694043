import React, { useEffect, useState } from 'react';
import { FullPage } from "../layouts";

import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useCustomMutation, useRegister } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { Controller, FieldValues } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { RequestProvider } from "shared-libs/src/providers/RequestProvider";
import { FullPageFooterLinks } from "../components";
import './Register.scss';

type RegisterVariables = {
    email: string;
    password: string;
    profile_data: {
        firstname: string;
        lastname: string;
        company_name: string;
        company_website: string;
        phone: string;
        tax_number: string;
        iban: string;
    }
    profile_model_id: number,
    comments: string;
    otp_now: string;
    otp_secret: string;
};

export const FormOTP: React.FC<{ values: FieldValues, showOTP: any, profileModelId: string }> = ({ values, showOTP, profileModelId }) => {
    const [qrImageUrl, setQrImageUrl] = useState("")
    const [qrCode, setQrCode] = useState("")
    const [messageType, setMessageType] = useState(0)
    const [message, setMessage] = useState("")
    const qrImageDimension = 200
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
        getValues
    } = useForm();

    const { mutate: userRegister } = useRegister<RegisterVariables>({
        v3LegacyAuthProviderCompatible: true
    });

    useEffect(() => {
        if (qrImageUrl === "") {
            RequestProvider.getOTP(values.email)
                .then((response) => {
                    //console.log(response.data)
                    setQrImageUrl("data:image/png;base64, " + response.data.qrcode)
                    setQrCode(response.data.code)
                }).catch(error => {
                    //console.log(error)
                })
        }
    }, [values.email, qrImageUrl])

    const onFinish = () => {
        const currentValues = getValues()
        userRegister({
            email: values.email,
            password: values.password,
            profile_data: {
                firstname: values.firstname,
                lastname: values.lastname,
                company_name: values.company_name,
                company_website: values.company_website,
                phone: values.phone,
                tax_number: values.tax_number,
                iban: values.iban
            },
            profile_model_id: parseInt(profileModelId),
            comments: values.comments,
            otp_now: currentValues.otp_now,
            otp_secret: qrCode,
        }, {
            onSuccess: (data) => {
                RequestProvider.requestEmailVerification().then((response) => {
                    // console.log(response)
                    setMessageType(1)
                }).catch((response) => {
                    // TODO: gestire il caso di OTP sbagliato? Rimandarlo al form di registrazione o mostrare un messagio per un nuovo tentativo?
                    setMessageType(5)
                    setMessage(response.data.message)
                })
            },
            onError: (response) => {
                setMessageType(5);
                setMessage(response.message)
            }
        });
    }
    return (
        <Box>
            {(!isSubmitSuccessful || messageType == 0) && <Box component="form" className='register-form' onSubmit={handleSubmit(onFinish)}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography>
                            To complete the registration it is necessary to activate the two-factor authentication via One Time Password (OTP). Kindly download the Google Autheticator App and scan the below QR code with Google Authenticator application.

                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {qrCode ? <Typography>You can also set up manually the OTP by entering the following setup key in the Google Authenticator App: {qrCode}</Typography> : <Skeleton variant={"rectangular"} />}
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="otp-qr-placeholder">
                            {qrImageUrl ?
                                <img src={qrImageUrl} height={qrImageDimension} alt={"QR Code"} /> :
                                <Skeleton height={qrImageDimension} width={qrImageDimension} variant={"rectangular"} />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Once activated, enter the OTP code to complete the registration:
                        </Typography>
                        <Controller
                            name="otp_now"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => {
                                let error = (errors.otp_now) ? { error: true, helperText: "required field" } : {}
                                return (
                                    <TextField fullWidth
                                        id="register-otp_now"
                                        label="Insert OTP"
                                        variant="outlined"
                                        {...error}
                                        {...field}
                                    />
                                )
                            }}
                        />
                    </Grid>
                    <Grid className='form-register-button' item xs={12}>
                        <Button color='info' size='medium' type="button" onClick={() => {
                            showOTP(false);
                        }}>Back</Button>
                        <Button color='info' size='medium' type="submit">Submit</Button>
                    </Grid>
                </Grid>
            </Box>}
            {messageType === 1 && <Card className='registration-card'>
                <CardContent>
                    <Typography>
                        Please check your email to verify your account. Once verified, our team will contact you shortly
                        to start the registration process.
                    </Typography>
                </CardContent>
            </Card>}
            {messageType === 5 && <Card className='registration-card warning'>
                <CardContent>
                    <Typography>
                        {message != "" ? message : "Unable to complete registration. Contact support for more information."}
                    </Typography>
                    <Button onClick={() => {
                        setMessageType(0);
                    }}>RETRY</Button>
                </CardContent>
            </Card>}
        </Box>
    )
}

export function Register({ isEmployee = false }) {

    const params = useParams()
    const email = params?.email

    const [showOTP, setShowOTP] = useState(false)
    useEffect(() => {
        document.title = 'Whtexch - Register';
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [profileModelId, setProfileModelId] = useState<string>(isEmployee ? "25" : "1")
    const [profileData, setProfileData] = useState<any>({})





    const { mutate, data } = useCustomMutation({});

    useEffect(() => {
        if (data) {
            setProfileData(data?.data)
        }
    }, [data])


    useEffect(() => {
        if (profileModelId) {
            mutate({
                url: '/user/get_profile_data_fields',
                values: { "profile_model_id": parseInt(profileModelId) },
                method: 'post'
            })
        }
    }, [profileModelId])


    const isFieldVisible = field => profileData?.required?.includes(field) || profileData?.optionals?.includes(field)
    const countEmptySpaces = (3 - ((profileData?.required?.length + profileData?.optionals?.length) % 3)) % 3
    console.log('countEmptySpaces', countEmptySpaces)
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitSuccessful },
        getValues,
        setError
    } = useForm();
    return (
        <FullPage Footer={FullPageFooterLinks}>
            {/* <Card>
                <ButtonGroup>
                    <Button variant={profileModelId == "1" ? 'contained' : 'create'} onClick={() => { setProfileModelId("1") }}>User</Button>
                    <Button variant={profileModelId == "25" ? 'contained' : 'create'} onClick={() => { setProfileModelId("25") }}>Company</Button>
                </ButtonGroup>
            </Card> */}
            {
                !showOTP && <Box style={{ marginTop: '20px', visibility: data ? 'visible' : 'hidden' }} component="form" className='register-form' onSubmit={handleSubmit(() => {
                    //console.log('handleSubmit')
                    //console.log(Object.keys(errors).length, errors, isSubmitSuccessful, isValid, isValidating, isSubmitting, isSubmitted, isDirty, dirtyFields)
                    // workaround: at first click of submit the form is valid but isSubmitSuccessful is false
                    const values = getValues();
                    if (email) {
                        values.email = email
                        values.confirm_email = email
                    }
                    let hasErrors = false;
                    if (values.password != values.confirm_password) {
                        setError('confirm_password', { message: 'Confirm password is not equal to Password' });
                        hasErrors = true;
                    }
                    if (values.email != values.confirm_email) {
                        setError('confirm_email', { message: 'Confirm email is not equal to Email' });
                        hasErrors = true;
                    }
                    if (!hasErrors) {
                        if (isSubmitSuccessful || Object.keys(errors).length <= 0) {
                            setShowOTP(true)
                        }
                    }
                })}>
                    <Grid container spacing={5}>
                        {isFieldVisible('name') && <Grid item xs={12} md={4}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    let error = (errors.name) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-company_name"
                                            label="Name of your Company *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}
                        {isFieldVisible('firstname') && <Grid item xs={12} md={4}>
                            <Controller
                                name="firstname"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    let error = (errors.firstname) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-name"
                                            label="Name *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}

                        {isFieldVisible('lastname') && <Grid item xs={12} md={4}>
                            <Controller
                                name="lastname"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    let error = (errors.lastname) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-lastname"
                                            label="Last Name *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}

                        {isFieldVisible('phone') && <Grid item xs={12} md={4}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    let error = (errors.phone) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-phone"
                                            label="Telephone Number *"
                                            type="tel"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}

                        {isFieldVisible('company_name') && <Grid item xs={12} md={4}>
                            <Controller
                                name="company_name"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    let error = (errors.company_name) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-company_name"
                                            label="Name of your Company *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}

                        {isFieldVisible('website') && <Grid item xs={12} md={4}>
                            <Controller
                                name="website"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField fullWidth
                                            id="register-company_website"
                                            label="Website of your Company"
                                            variant="outlined"
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}
                        {isFieldVisible('company_website') && <Grid item xs={12} md={4}>
                            <Controller
                                name="company_website"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField fullWidth
                                            id="register-company_website"
                                            label="Website of your Company"
                                            variant="outlined"
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}

                        {isFieldVisible('tax_number') && <Grid item xs={12} md={4}>
                            <Controller
                                name="tax_number"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    let error = (errors.tax_number) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-tax_number"
                                            label="Registration / Tax Number of your Company *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}

                        {isFieldVisible('iban') && <Grid item xs={12} md={4}>
                            <Controller
                                name="iban"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    let error = (errors.iban) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-iban"
                                            label="IBAN *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}

                        {(() => {
                            let i;
                            const toreturn: Array<any> = []
                            for (i = 0; i < countEmptySpaces; i++) {
                                toreturn.push(<Grid item xs={12} md={4}></Grid>)
                            }
                            return toreturn
                        })()}

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="email"
                                control={control}
                                /* rules={{required: true, validate: (params) => {console.log(params);return false;}, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}} */
                                rules={{ required: !isEmployee, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                                render={({ field }) => {
                                    let error = (errors.email) ? { error: true, helperText: "required field" } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-email"
                                            label="Email *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                            {...isEmployee ? { disabled: true, value: email } : {}}
                                        />
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="confirm_email"
                                control={control}
                                /* rules={{required: true, validate: (params) => {console.log(params);return false;}, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/}} */
                                rules={{ required: !isEmployee, pattern: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/ }}
                                render={({ field }) => {
                                    let error = (errors.confirm_email) ? {
                                        error: true,
                                        helperText: errors.confirm_email.message?.toString()
                                    } : {}
                                    return (
                                        <TextField fullWidth
                                            id="register-email"
                                            label="Confirm Email *"
                                            variant="outlined"
                                            {...error}
                                            {...field}
                                            {...isEmployee ? { disabled: true, value: email } : {}}
                                        />
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Controller
                                name="password"
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                                }}
                                render={({ field }) => {
                                    let error = (errors.password) ? {
                                        error: true,
                                        helperText: 'field must have at least two uppercase letters, one special case letter, two digits, three lowercase letter and at least a length of 8'
                                    } : {}
                                    return (
                                        <TextField fullWidth
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            id="register-password"
                                            label="Password *"
                                            type={showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            className='form-password-input'
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="confirm_password"
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8,}$/
                                }}
                                render={({ field }) => {
                                    let error = (errors.confirm_password) ? {
                                        error: true,
                                        helperText: errors.confirm_password.message?.toString()
                                    } : {}
                                    return (
                                        <TextField fullWidth
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            id="register-confirm_password"
                                            label="Confirm Password *"
                                            type={showPassword ? 'text' : 'password'}
                                            variant="outlined"
                                            className='form-password-input'
                                            {...error}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>

                        {profileModelId == "1" && <Grid item xs={12}>
                            <Controller
                                name="comments"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <TextField fullWidth
                                            id="register-comments"
                                            label="Additional Comments"
                                            variant="outlined"
                                            multiline
                                            rows={3}
                                            {...field}
                                        />
                                    )
                                }}
                            />
                        </Grid>}
                        <Grid className='form-register-button' item xs={12}>
                            <Box></Box>
                            <Button color='info' size='medium' type="submit">Continue</Button>
                        </Grid>
                    </Grid>
                </Box>}
            {showOTP && <FormOTP profileModelId={profileModelId} values={{ ...getValues(), ...(isEmployee ? { email } : {}) }} showOTP={setShowOTP} />}
        </FullPage>
    );
}