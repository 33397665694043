import { DateField } from "@refinedev/mui";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import './TradeTabModal.scss'
import React, { useEffect, useState } from "react";
import { useCustomMutation, useInvalidate, useModal, useNavigation, useNotification } from "@refinedev/core";
import { NumberField } from "../fields";
import OTPModal from "components/OTPModal";
import { useCurrencyStore } from "hooks/useCurrency";

export default function TradeTabModal({ data, aft, modal, balance_data, reloadPage, setValue }) {
    const { mutate: custom } = useCustomMutation()
    const [disableButton, setDisableButton] = useState(false)
    const [showLoading, setshowLoading] = useState(false)
    const { open } = useNotification();
    const { push } = useNavigation();
    const commission = 0.2;
    const amount = data.quantity * data.limit_price
    const amount_commission = amount * commission
    const amount_held = balance_data?.available ?? 0
    const amount_held_total = amount_held + (amount * (data.type === 'buy' ? -1 : 1))
    const available_tokens = parseInt(aft?.client.available)
    const available_tokens_total = available_tokens + (parseInt(data.quantity) * (data.type === 'buy' ? 1 : -1))
    const invalidate = useInvalidate();
    const valueToSend = {
        aftcontract_id: aft.aftcontract_id,
        quantity: parseInt(data.quantity),
        price_for_unit: parseInt(data.limit_price)
    }
    if (data.profit_taker) {
        valueToSend['trigger_takeprofit_price'] = data.pt_limit_price
    }
    if (data.stop_loss) {
        valueToSend['trigger_stoploss_price'] = data.sl_limit_price
    }
    if (data.profit_taker || data.stop_loss) {
        valueToSend['trigger_expiration_date'] = data.trigger_expiration_date
    }
    if (data.type === 'sell') {
        valueToSend['expiration_date'] = data.validity_period_date_to_send
    } else {
        valueToSend['expiration_date'] = data.validity_period_date_to_send
    }
    const otpModal = useModal();
    const handlerProceed = (otp) => {
        setDisableButton(true)
        setshowLoading(true)
        valueToSend['otp'] = otp
        open?.({
            type: "success",
            message: "Processing and Waiting to be Settled"
        })
        custom({
            url: data.type === 'buy' ? `/trading/client/aftcontract/buy` : `/trading/client/aftcontract/sell`,
            method: "post",
            values: valueToSend
        }, {
            onSuccess: () => {
                open?.({
                    type: "success",
                    message: "Trasmitted"
                })
                reloadPage(true)
                modal.close()

                invalidate({
                    resource: 'trade',
                    id: aft.aftcontract_id,
                    invalidates: ["all"]
                })

                setValue('quantity', 0);
                setValue('limit_price', 0);
                setValue('validity_period', '');

            },
            onError: () => {
                setDisableButton(false)
                setshowLoading(false)
                modal.close()
            }
        })
    }
    useEffect(() => {
        setDisableButton(amount_held_total < 0)
    }, [])

    const { convert } = useCurrencyStore()
    return (
        <Dialog className='trade_tab_modal_container' open={modal.visible} onClose={modal.close} fullWidth={true}
            maxWidth={"lg"}>
            <DialogTitle
                className='trade_tab_modal_title trade_tab_modal_title_bold '>WHTEXCH PLATFORM  | {data.type?.toUpperCase()} REQUEST ON SECONDARY MARKET</DialogTitle>
            <DialogContent>
                {/* <Grid className='trade_tab_modal_grid' container spacing={2}>
                    <Grid className='trade_tab_modal_title_bold trade_tab_modal_title_grid' item xs={4}>AFT</Grid>
                    <Grid className='trade_tab_modal_title_bold trade_tab_modal_title_grid' item xs={4}>Bid</Grid>
                    <Grid className='trade_tab_modal_title_bold trade_tab_modal_title_grid' item xs={4}>Ask</Grid>
                    <Grid className='trade_tab_modal_grid_data' item xs={12}>
                        <Grid className='trade_tab_modal_grid_data_second_row' container spacing={2}>
                            <Grid className='trade_tab_modal_grid_data_item' item xs={4}>{aft.aftcontract_id}</Grid>
                            <Grid className='trade_tab_modal_grid_data_item' item xs={4}><NumberField
                                value={999}/></Grid>
                            <Grid className='trade_tab_modal_grid_data_item' item xs={4}><NumberField
                                value={1000}/></Grid>
                        </Grid>
                    </Grid>

                </Grid> */}

                <Box className='trade_tab_modal_order_description'>
                    <Typography className='trade_tab_modal_message_text' variant={"body2"}>
                        <p>Dear estimated Participant,</p>
                        <p></p>
                        <p>You are requiring to {data.type?.toLowerCase() == 'buy' ? 'buy' : 'sell'} on the secondary market the Masterpiece <strong>{aft?.artwork?.work_of_ingenuity || aft?.aftcontract_id}</strong></p>
                    </Typography>
                    <Typography className='trade_tab_modal_title_grid trade_tab_modal_title_bold'
                        variant={"body1"}>
                        <p>Transaction details:</p>
                    </Typography>

                    <Box>
                        <Box className='trade_tab_modal_order_description_container'>
                            <Box className='trade_tab_modal_od'><span style={{ width: '60%' }}>Unit Limit Price (WHTEXs)</span> <NumberField
                                value={data.limit_price} style={{ textAlign: 'right', width: '10%' }} /></Box>
                            <Box className='trade_tab_modal_od'><span style={{ width: '60%' }}>Units Quantity:</span>
                                <NumberField
                                    className='trade_tab_modal_od_qty' value={data.quantity} />
                            </Box>
                            <Box
                                className='trade_tab_modal_od'><span style={{ width: '60%' }}>Validity Period:</span> {data.validity_period_date != "" ?
                                    <DateField format="YYYY/MM/DD" value={data.validity_period_date} /> : "Good till cancelled"}</Box>
                            {data.profit_taker &&
                                <Box className='trade_tab_modal_od'><span style={{ width: '60%' }}>Profit Taker Limit Price:</span> <NumberField
                                    value={data.pt_limit_price} /></Box>}
                            {data.stop_loss &&
                                <Box className='trade_tab_modal_od'><span style={{ width: '60%' }}>Stop Loss Limit Price:</span> <NumberField
                                    value={data.sl_limit_price} /></Box>}
                            {(data.profit_taker || data.stop_loss) &&
                                <Box className='trade_tab_modal_od'><span>Trigger Period:</span> <DateField
                                    value={data.trigger_expiration_date} /></Box>}
                            <Box className='trade_tab_modal_od'><span style={{ width: '60%' }}>Unit Limit Price (EUR)</span>
                                <NumberField style={{ textAlign: 'right', width: '10%' }}
                                    className='trade_tab_modal_od_qty' value={convert(data.limit_price)} />
                            </Box>
                            {/* <Box className='trade_tab_modal_od trade_tab_modal_od_buy_qty '><span
                                className='trade_tab_modal_od_buy'>{data.type}</span>
                            </Box> */}
                        </Box>
                    </Box>
                </Box>

                <Box className='trade_tab_modal_last_grid'>
                    <Box className='trade_tab_modal_amount'>
                        <Typography className='trade_tab_modal_title_grid trade_tab_modal_title_bold'
                            variant={"body1"}>Amount</Typography>
                        <Grid item xs={3}></Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12}>&nbsp;</Grid>
                            <Grid item xs={6}>WHTEXs</Grid>
                            <Grid className='trade_tab_modal_amount_data' item xs={6}><NumberField value={amount}
                                display={"inline"} /></Grid>
                            <Grid item xs={6}>EUR</Grid>
                            <Grid className='trade_tab_modal_amount_data' item xs={6}><NumberField value={convert(amount)}
                                display={"inline"} /></Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Typography className='trade_tab_modal_title_grid trade_tab_modal_title_bold'
                            variant={"body1"}>Balances</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={3}></Grid>
                            <Grid className='trade_tab_modal_title_bold trade_tab_modal_amount_data' item xs={3}>Currently
                                Available</Grid>
                            <Grid className='trade_tab_modal_title_bold trade_tab_modal_amount_data' item
                                xs={3}>Post-Order</Grid>
                            <Grid className='trade_tab_modal_title_bold trade_tab_modal_amount_data' item
                                xs={3}>Post-Execution</Grid>

                            <Grid item xs={3}>WHTEXs</Grid>
                            <Grid className='trade_tab_modal_amount_data' item xs={3}><NumberField value={amount_held} /></Grid>
                            <Grid item xs={3}
                                className={'trade_tab_modal_amount_data ' + ((amount_held_total < 0) ? 'trade_tab_modal_field_error' : '')}>
                                <NumberField variant={"inherit"} value={data.type === 'buy' ? amount_held_total : amount_held} /></Grid>
                            <Grid item xs={3}
                                className={'trade_tab_modal_amount_data ' + ((amount_held_total < 0) ? 'trade_tab_modal_field_error' : '')}>
                                <NumberField variant={"inherit"} value={amount_held_total} /></Grid>

                            <Grid item xs={3}>AFT&nbsp;{aft?.artwork?.work_of_ingenuity || aft?.aftcontract_id}</Grid>
                            <Grid className='trade_tab_modal_amount_data' item xs={3}><NumberField
                                value={available_tokens} /></Grid>
                            <Grid className='trade_tab_modal_amount_data' item xs={3}><NumberField
                                value={data.type === 'sell' ? available_tokens_total : available_tokens} /></Grid>
                            <Grid className='trade_tab_modal_amount_data' item xs={3}><NumberField
                                value={available_tokens_total} /></Grid>
                        </Grid>
                    </Box>

                </Box>
            </DialogContent>
            <DialogActions className='space_between_button'>
                <Button color='info' onClick={modal.close}>Cancel</Button>

                <OTPModal modal={otpModal} confirmText="SUBMIT" message={<><p>To ensure the security of your account, please enter the 6-digit Two-Factor Authentication (2FA) One-Time Password (OTP) code available on the Google Authenticator App<br /></p>

                </>} onConfirm={async (otp) => {
                        handlerProceed(otp)
                    }} title="OTP" />
                <Button color='info' disabled={disableButton}
                    onClick={otpModal.show}>{!showLoading ? "Transmit Order" :
                        <CircularProgress />}</Button>
            </DialogActions>
        </Dialog>
    )
}