import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { DateField, List, useDataGrid } from "@refinedev/mui";
import React, { useEffect, useState } from "react";

import { useNotification } from "@refinedev/core";
import { GlobalConfig } from "shared-libs/src/GlobalConfig";
import { User, IUser } from "shared-libs/src/interfaces";
// import { useCurrencyStore } from "hooks/useCurrency";
import { useGetIdentity } from "@refinedev/core";
import { LocalStorageProvider } from "shared-libs/src/providers";
import { useCurrencyStore } from "hooks/useCurrency";

const dateRender = function render(params) {
    return <DateField format="DD/MM/YYYY HH:mm:ss" value={params.value} style={{marginBottom: 0}}/>;
}

const dataGridCommonProps = {
    disableColumnSelector: true,
    autoHeight: true,
    ...GlobalConfig.dataGridConfig
}


export const BurnAudit = () => {

    const { data: user } = useGetIdentity({
        v3LegacyAuthProviderCompatible: true
    });
    
    const [bcid, setBcid] = useState<string>(LocalStorageProvider.getSessionData('bcid'));
    
    useEffect(() => {
        console.log('new ', user?.hlf[0].bcid)
        if (user?.hlf[0].bcid && !bcid) {
            setBcid(user?.hlf[0].bcid)
        }
    }, [user])

    // const { getValue, init } = useCurrencyStore()

    const { open } = useNotification()

    useEffect(() => {
        document.title = 'Whtexch - Burn audit';
    }, []);

    const { dataGridProps, tableQueryResult: { refetch } } = useDataGrid({
        pagination: {
            mode: "off"
        },
        resource: 'burn_audit',
        queryOptions: {
            ...GlobalConfig.adminQueryOptions,
            
            queryKeyHashFn: () => `burned_audit`,
            refetchInterval: 5000,
        },
        filters: {
            permanent: [
                { field: 'bcid', operator: 'eq', value: bcid }
            ]
        }
    });

    const columns = React.useMemo<GridColumns<{
        user_data?: IUser,
        actor: User,
        profile_data: string, 
        confirmed_by: User,
        approved_by: User,
        action: any
        aftcontract_id: string,
        artwork?: {
            work_of_ingenuity: string
        }
    }>>(() => [
        {field: "id", headerName: "ID", flex: 1, headerAlign: "left" },
        { field: "timestamp", type: "date", headerName: "DATE & TIME (UTC)", flex: 2, renderCell: dateRender },
        {
            field: "aftcontract_id", headerName: "AFT ID", flex: 1, headerAlign: "left", valueGetter: ({ row: { aftcontract_id, artwork } }) => {
                return artwork?.work_of_ingenuity ? artwork.work_of_ingenuity : aftcontract_id
            }
        },
        {field: "status", headerName: "STATUS", flex: 2, valueGetter: ({row}) => {
            switch(row.action) {
                case "created":
                    return "Download Requested"
                case "approved_one":
                    return "Pending"
                case "approved_two":
                    return "Pending"
                case "burned":
                    return "Burned"
                case "marked_as_downloaded":
                    return "Marked as Downloaded"
                case "rejected":
                    return "Rejected"
                case "clicked":
                    return "Download Launched";
                case "backup_clicked":
                    return "Backup Download Launched";
                default: 
                    return row.action
            }
        }},
        {field: "bcid", headerName: "BCID", flex: 0.75 },
        {field: "user", headerName: "CUSTOMER", flex: 1, valueGetter: ({ row }) => {return `${row.user_data?.profile_data.firstname} ${row.user_data?.profile_data.lastname}`} }, // customer?
        {field: "company", headerName: "COMPANY", flex: 1, valueGetter: ({ row }) => {return row.user_data?.hlf[0].company_name} },
        {field: "market", headerName: "MARKET", flex: 0.80},
        {field: "action", headerName: "action type", flex: 2, valueGetter: ({row}) => {
            switch(row.action) {
                case "created":
                    return "Download Requested"
                case "approved_one":
                    return "Download Request Approved"
                case "approved_two":
                    return "Download Request Confirmed"
                case "burned":
                    return "Burn Approved"
                case "marked_as_downloaded":
                    return "Downloaded"
                case "rejected":
                    return "Rejected"
                case "clicked":
                    return "Clicked";
                case "backup_clicked":
                    return "Backup Clicked";
                default: 
                    return row.action
            }
        }},
        
    ], [dataGridProps]);


    return (
        <List title="Download & Burn">
            {/* {init} */}
            <DataGrid getRowId={({ aftcontract_id, timestamp }) => `k-${aftcontract_id}${timestamp}`} {...dataGridProps} {...dataGridCommonProps} columns={columns} />
        </List>
    );
};
