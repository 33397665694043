import { Box, Button, Grid, Typography } from "@mui/material";
import { useMany, useNavigation, useOne, useTranslate } from "@refinedev/core";
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { IAftTrade } from 'shared-libs/src/interfaces';
import AftSummary from "../../components/AftSummary";
import TotalWhtex from "../../components/TotalWhtex";
import TradeActiveOrders from "../../components/trade/TradeActiveOrders";
import TradeChart from "../../components/trade/TradeChart";
import TradeOrderBook from "../../components/trade/TradeOrderBook";
import TradeStats from "../../components/trade/TradeStats";
import TradeTab from "../../components/trade/TradeTab";
import './show.scss';
import Download from '@mui/icons-material/Download'


export default function Trade() {
    const t = useTranslate();
    useEffect(() => {
        document.title = 'Whtexch - Trade';
    }, []);

    const {id: aftcontractId} = useParams();
    const { data: dataClient, isSuccess: isSuccessClientData, refetch: refetchClientData } = useOne<IAftTrade & any>({
        resource: 'onmarket',
        id: aftcontractId || "",
        liveMode: "auto",
        queryOptions: {
            // refetchInterval: 10000
        },
        meta: {
            filters: [
                { field: "show_artwork", operator: 'eq', value: true },
                // { field: 'show_premarket', operator:'eq',value:true},
                { field: 'show_originator', operator: 'eq', value: true },
                { field: 'show_last_orders', operator: 'eq', value: true },
                { field: 'show_square', operator: 'eq', value: true },
                { field: 'show_orderbook', operator: 'eq', value: true },
                { field: 'show_price_history', operator: 'eq', value: true },
                { field: 'show_artwork', operator: 'eq', value: true },
                // { field: 'show_average_purchase_price', operator: 'eq', value: true },
                { field: 'show_daily_volume', operator: 'eq', value: true },
                { field: 'aftcontract_id', operator: 'eq', value: aftcontractId },
                { field: 'show_user_orders', operator: 'eq', value: true },


            ]

        }
    })
    const [dataWhtBalance, setDataWhtBalance] = useState<any>({});
    const [pageReload, setPageReload] = useState(false);

    const [pendingBuy, setPendingBuy] = useState(0)
    const [pendingSell, setPendingSell] = useState(0)
    const { push } = useNavigation()



    const {data: dataWht, isSuccess: isSuccessWht, refetch: refetchWht} = useOne({
        resource: "balance_wht",
        id: "",
        liveMode: "auto",
    })

    useEffect(() => {
        if (dataClient?.data?.client.orders) {


            const { buy, sell } = (dataClient?.data?.client.orders as { type, quantity, init_quantity, status }[]).filter(el => el.status == 'o').reduce((acc, curr, index) => {

                acc[curr.type] = (acc[curr.type] || 0) + curr.quantity;
                return acc;
            }, { buy: 0, sell: 0 })


            setPendingBuy(buy);
            setPendingSell(sell);

        }
    }, [dataClient?.data?.client.orders])

    useEffect(() => {
        setDataWhtBalance(dataWht)
    }, [dataWht, isSuccessWht])
    useEffect(() => {
        if (pageReload) {
            refetchClientData();
            refetchWht();
        }
        setPageReload(false)
    }, [pageReload])

    return (
        <React.Fragment>
            <Box sx={{displax: 'flex',}} className="page-title-container trade">
                <Box className="header-title-content">
                    <Typography variant="h1">{dataClient?.data?.artwork?.work_of_ingenuity || aftcontractId}</Typography>
                    <Typography variant="h2">Masterpiece ID</Typography>
                </Box>
                <Box className='aft-summary-container'>
                    <AftSummary data={{
                        max_units_purchasable: dataClient?.data?.artwork.number_of_tokens - (dataClient?.data?.client.available + dataClient?.data?.client.committed) - pendingBuy,
                        available: dataClient?.data?.client?.available,
                        day_volume: dataClient?.data?.daily_volume,
                        last_price: dataClient?.data?.price,
                        committed: dataClient?.data?.client?.committed,
                        pending_buy: pendingBuy,
                        pending_sell: pendingSell
                    }}
                                loading={!isSuccessClientData}/>
                </Box>
                <Box className="button-content">
                    <Button onClick={() => {
                        push('/trade/download/show/' + aftcontractId)
                    }} >DOWNLOAD <Download /></Button>
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                    <Box className='trade-container'>
                        <Box className='aft-tab-stats-chart-orderbook-container'>
                            <Box className='tab-chart-orderbook-container'>
                                <Box className='trade-tab-stats-container'>
                                    <TradeTab aft_data={{ ...dataClient?.data, pending_buy: pendingBuy, pending_sell: pendingSell }}
                                              balance_data={dataWhtBalance?.data}
                                              is_reloaded={pageReload}
                                              reloadPage={setPageReload}/>
                                </Box>
                                <Box className='trade-chart-orderbook-container' style={{ padding: '10px' }}>
                                    <TradeChart loading={!isSuccessClientData}
                                                data={dataClient?.data?.price_history}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} lg={3}>
                    <TotalWhtex flexAlignment="flex-column" fontSize="tot-whtx-12" fields={["owned", "committed"]} enableSell={false}
                                title={t("trade.whtex.your_account")} data={dataWht} isSuccess={isSuccessWht}/>

                    <TradeStats loading={!isSuccessClientData} data={{
                        average_daily_volume: dataClient?.data?.average_daily_volume,
                        daily_volume: dataClient?.data?.daily_volume,

                        last_ask_price: dataClient?.data?.last_order?.ask?.price_for_unit || 0,
                        last_ask_quantity: dataClient?.data?.last_order?.ask?.init_quantity || 0,
                        last_bid_price: dataClient?.data?.last_order?.bid?.price_for_unit || 0,
                        last_bid_quantity: dataClient?.data?.last_order?.bid?.init_quantity || 0,

                        highest_bid_price: dataClient?.data?.orderbook?.bid?.sort((a, b) => b.price - a.price)[0]?.price,
                        lowest_ask_price: dataClient?.data?.orderbook?.ask?.sort((a, b) => a.price - b.price)[0]?.price,
                        highest_bid_quantity: dataClient?.data?.orderbook?.bid?.sort((a, b) => b.price - a.price)[0]?.tokens,
                        lowest_ask_quantity: dataClient?.data?.orderbook?.ask?.sort((a, b) => a.price - b.price)[0]?.tokens,

                    }}/>
                </Grid>
            </Grid>

            <Box sx={{}}></Box>

            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <TradeOrderBook loading={!isSuccessClientData} data={normalizeOrderBook(dataClient?.data?.orderbook)} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box className='trade-container'>
                        <Box className='totalwhtex-activeorder-container'>
                            <TradeActiveOrders aftContractId={aftcontractId} loading={!isSuccessClientData}
                                data={dataClient?.data?.client.orders.filter(el => el.status == 'o')} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

type Ask = {
    outstanding_orders: number
    price: number
    tokens: number
}
type Bid = Ask

type NormalizedAsk = {
    data: Array<
        {
            "outstanding_orders": number
            "tokens": number
            "price": number
        }>
    ,
    totals: {
        outstanding_orders: number
        tokens: number
    }
}

type NormalizedBid = NormalizedAsk




const normalizeOrderBook: (data: { ask: Array<Ask>, bid: Array<Bid> }) => { ask: NormalizedAsk, bid: NormalizedBid } | undefined = (data) => {
    if (!data) {
        return undefined;
    }

    return {
        ask: {
            data: data.ask,
            totals: data.ask.reduce((acc, curr, index) => {
                acc.outstanding_orders += curr.outstanding_orders;
                acc.tokens += curr.tokens;
                return acc;
            }, { outstanding_orders: 0, tokens: 0 })
        },
        bid: {
            data: data.bid,
            totals: data.bid.reduce((acc, curr, index) => {
                acc.outstanding_orders += curr.outstanding_orders;
                acc.tokens += curr.tokens;
                return acc;
            }, { outstanding_orders: 0, tokens: 0 })
        }
    }
}