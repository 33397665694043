import React, {useEffect} from "react";
import {useDataGrid, EditButton, List, DateField, TagField, CreateButton} from "@refinedev/mui";
import {Box, Typography, Card, CardContent, CardHeader} from "@mui/material";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import {TicketStatus} from "shared-libs/src/models";
import {GlobalConfig} from "shared-libs/src/GlobalConfig";

export const MessagesList = () => {
    useEffect(() => {
        document.title = 'Whtexch - Messages';
    }, []);
    const {dataGridProps} = useDataGrid({
        pagination: {
            mode: "off"
        },
        queryOptions: {
            ...GlobalConfig.queryOptions,
            cacheTime: 0,
            staleTime: 0,
            refetchInterval: 30000,
        }
    });
    const columns = React.useMemo<GridColumns<any>>(
        () => [
            {
                field: "ticket_id",
                headerName: "Ticket ID",
                minWidth: 130
            },
            {
                field: "ticket_status",
                headerName: "Status",
                minWidth: 130,
                renderCell: function render({value}) {
                    return <TagField value={TicketStatus.getLabel(value)}/>
                },
            },
            {
                field: "subject",
                headerName: "Subject",
                flex: 1,
            },
            {
                field: "message",
                headerName: "# messages",
                type: "number",
                minWidth: 130,
                renderCell: function render({value}) {
                    return value.length;
                },
                sortable: false,
                filterable: false
            },
            {
                field: "created",
                headerName: "Created",
                flex: 1,
                renderCell: function render({value}) {
                    return <DateField format="YYYY/MM/DD" value={value}/>;
                },
            },
            {
                field: "updated",
                headerName: "Updated",
                flex: 1,
                renderCell: function render({value}) {
                    return <DateField format="YYYY/MM/DD" value={value}/>;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                align: "center",
                headerAlign: "center",
                minWidth: 100,
                renderCell: function render({row}) {
                    return <EditButton variant="edit" hideText recordItemId={row.id}/>;
                },
            },
        ],
        [],
    );
    return (
        <React.Fragment>
            <Box sx={{displax: 'flex'}} className="page-title-container">
                <Box>
                    <Typography variant="h1">Tickets</Typography>
                    {/* <Typography variant="h2">Sottotiolo</Typography> */}
                </Box>
            </Box>
            <Card sx={{mb: 2}}>
                <CardHeader className="panel-header" title="List"></CardHeader>
                <CardContent className="card-whtex">
                    <Box sx={{display: 'flex', justifyContent: 'end', margin: '20px 0'}}>
                        <CreateButton variant="create"/>
                    </Box>
                    <DataGrid
                        {...dataGridProps}
                        columns={columns}
                        autoHeight
                        {...GlobalConfig.dataGridConfig}
                    />
                </CardContent>
            </Card>
        </React.Fragment>
    );
};
