export default function useDateTime() {
    const _twoDigits = (v) => ("0" + v).slice(-2)
    const _stamp = (len = 14, dateString = "", dateSeparator = "", timeSeparator = "") => {
        const date = (dateString !== "") ? new Date(dateString) : new Date();
        const td = _twoDigits;
        const dateSeparatorPadding = dateSeparator.length * 2
        const timeSeparatorPadding = timeSeparator.length * 2
        const dateTimeSeparator = (dateSeparator.length > 0 || timeSeparator.length > 0) ? " " : ""

        return ([
                date.getUTCFullYear(),
                td(date.getUTCMonth() + 1),
                td(date.getUTCDate())
            ].join(dateSeparator).substring(0, len + dateSeparatorPadding) +
            dateTimeSeparator +
            [
                td(date.getUTCHours()),
                td(date.getUTCMinutes()),
                td(date.getUTCSeconds())
            ].join(timeSeparator).substring(0, len + timeSeparatorPadding - 8)).trim();
    }
    return {
        todayDateTime: _stamp(),
        todayDate: _stamp(8, ""),
        yesterdayDate: (dateSeparator = "", timeSeparator = "") => {
            const date = new Date()
            date.setDate(date.getDate() - 1)
            // date.setUTCHours(0,0,0)
            return _stamp(8, date.toISOString(), dateSeparator, timeSeparator)
        },
        tomorrowDateFormat: (dateSeparator = "", timeSeparator = "") => {
            const date = new Date()
            date.setDate(date.getDate() + 1)
            // date.setUTCHours(0,0,0)
            return _stamp(8, date.toISOString(), dateSeparator, timeSeparator)
        },
        endOfDayFormat: (closingHours = 18, dateSeparator = "", timeSeparator = "") => {
            const date = new Date()
            date.setMilliseconds(0)
            date.setHours(closingHours)
            date.setMinutes(0)
            date.setSeconds(0)
            // date.setUTCHours(0,0,0)
            return _stamp(14, date.toISOString(), dateSeparator, timeSeparator)
        },
        tomorrowDateTimeFormat: (dateSeparator = "", timeSeparator = "") => {
            const date = new Date()
            date.setDate(date.getDate() + 1)
            // date.setUTCHours(0,0,0)
            return _stamp(14, date.toISOString(), dateSeparator, timeSeparator)
        },
        todayDateFormat: (dateSeparator = "", timeSeparator = "") => {
            return _stamp(8, "", dateSeparator, timeSeparator)
        },
        todayDateTimeFormat: (dateSeparator = "", timeSeparator = "") => {
            return _stamp(14, "", dateSeparator, timeSeparator)
        },
        dateFormat: (dateString, dateSeparator = "", timeSeparator = "") => {
            return _stamp(8, dateString, dateSeparator, timeSeparator)
        },
        dateTimeFormat: (dateString, dateSeparator = "", timeSeparator = "") => {
            return _stamp(14, dateString, dateSeparator, timeSeparator)
        }

    }
}