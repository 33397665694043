import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import {AftOverview} from "./AftOverview";
import {AftHistory} from "./AftHistory";
import {AftReporting} from "./AftReporting";
import "./show.scss"
import {useParams} from "react-router-dom";
import {Show} from "@refinedev/mui";

export const AftShow = () => {
    const {id: aft_id} = useParams()
    return (<React.Fragment>
        <Box sx={{ displax: 'flex' }} className="page-title-container">
            <Box>
                <Typography variant="h1">Report</Typography>
                {/* <Typography variant="h2">Sottotiolo</Typography> */}
            </Box>
        </Box>

        <Show title={<Typography variant="h5">Back</Typography>} breadcrumb={""} headerButtons={<></>}>
            <Stack spacing={2}>
                <Grid container spacing={2} flexDirection={"column"}>
                    <Grid item display={"flex"} flexDirection={"row"}>
                        <AftOverview aft_id={aft_id} />
                        {/* <AftReporting aft_id={aft_id} /> */}
                    </Grid>
                    <Grid item>
                        <AftHistory key={aft_id} aftcontract_id={aft_id} />
                    </Grid>
                </Grid>
            </Stack>
        </Show>
    </React.Fragment>
    );

}