import { DateField } from "@refinedev/mui";

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Skeleton,
    Typography,
    Card,
    CardHeader,
    CardContent,
    Tooltip,
    Table,
    TableRow,
    TableCell,
} from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import React, { useCallback, useState } from "react";
import './TradeActiveOrders.scss'
import { useCustomMutation, useInvalidate, useModal, useNotification } from "@refinedev/core";
import {NumberField} from "../fields";


import { tooltip } from 'shared-libs/src/components/CustomTooltip'
import OTPModal from "components/OTPModal";
import { useCurrencyStore } from "hooks/useCurrency";
const CustomTooltip = tooltip(Tooltip)(Typography)

const ActiveOrders = ({data, disableDelete, setCurrentOrder, showSingleOrder}) => {
    const { getValue } = useCurrencyStore()
    return !data || data.length <= 0 ? <Box className='active-order-data-row'>no active orders</Box> :
        data.map((item) => {
            return (
                <Box key={item.order_id} className='active-order-data-row'>
                    <Box className='active-order-data'><DateField format="YYYY/MM/DD HH:mm:ss"
                                                                  value={item.creation_date}/>
                    </Box>
                    <Box className='active-order-data'>{item.type}</Box>
                    <Box className='active-order-data'>
                        {getValue(item.price_for_unit)}
                    </Box>
                    <Box className='active-order-data'>
                        <NumberField variant={"inherit"} value={item.quantity}/></Box>
                    <Box className='active-order-data'>
                        {item.expiration_date ? <DateField variant={"inherit"} format="YYYY/MM/DD" value={item.expiration_date} /> : <CustomTooltip tooltip={'Good Till Cancelled'} text={'GTC'} />}</Box>
                    {/*<Box className='active-order-data active-order-data-icon'>*/}
                    {/*    <EditIcon color='info' fontSize='small'/>*/}
                    {/*</Box>*/}
                    <Box className='active-order-data active-order-data-icon'>
                        <DeleteIcon color={disableDelete ? 'disabled' : 'info'}
                                    fontSize='small'
                                    onClick={() => {
                                        if (!disableDelete) {
                                            setCurrentOrder(item)
                                            showSingleOrder()
                                        }
                                    }}/>
                    </Box>
                </Box>
            )
        })
}

export default function TradeActiveOrders({ loading, data, aftContractId }: { loading: boolean, data: Array<{ order_id: string, aftcontract_id: string, type: string }>, aftContractId: string | undefined }) {
    const {mutate: custom} = useCustomMutation()
    const {visible, show, close} = useModal();
    const {visible: visibleSingleOrder, show: showSingleOrder, close: closeSingleOrder} = useModal();
    const {open} = useNotification();
    const [disableDelete, setDisableDelete] = useState(false)
    const [currentOrder, setCurrentOrder] = useState<any>()
    const invalidate = useInvalidate()
    const { getValue } = useCurrencyStore()

    const otpModal = useModal()
    const handlerCancel = otp => (order_ids, order_types, onDone = () => { }) => {
        custom({
            url: `/trading/client/aftcontract/order/cancel`,
            method: "post",
            values: {
                aftcontract_id: aftContractId,
                order_ids: order_ids,
                order_types: order_types,
                otp
            }
        }, {
            onSuccess: () => {
                setTimeout(() => {
                    invalidate({
                        resource: 'trade',
                        id: aftContractId,
                        invalidates: ["all"]
                    })

                    onDone();
                }, 500)
                setDisableDelete(false);
                closeSingleOrder()
                setCurrentOrder(null);
                close();
                otpModal.close()
            }
        })
    }


    return (
        <>
            <Card sx={{mb: 2}}>
                <CardHeader className="panel-header" title="Active order"/>
                <CardContent sx={{pl: '20px', pr: '20px'}}>
                    <Box className='active-order-container'>
                        <Box className='active-order-title-data'>
                            <Box className='active-order-tab'>
                                <Box className='active-order-data-row active-order-data-title-row'>
                                    <Box className='active-order-data active-order-data-title'>Date</Box>
                                    <Box className='active-order-data active-order-data-title'>Action</Box>
                                    <Box className='active-order-data active-order-data-title'>Limit Price</Box>
                                    <Box className='active-order-data active-order-data-title'>Qty</Box>
                                    <Box className='active-order-data active-order-data-title'>Exp date</Box>
                                    {/*<Box className='active-order-data active-order-data-title'>Edit</Box>*/}
                                    <Box className='active-order-data active-order-data-title'>Cancel</Box>
                                </Box>

                                {loading ? <Skeleton/> : <ActiveOrders data={data} disableDelete={disableDelete}
                                                                       setCurrentOrder={setCurrentOrder}
                                                                       showSingleOrder={showSingleOrder}/>}

                                {!loading && data && data.length > 0 &&
                                    <Box className='active-order-data-row active-order-data-row-button'>
                                        <Button color='info' disabled={disableDelete} onClick={show}>Cancel All</Button>
                                    </Box>
                                }

                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            {visible && <Dialog open={visible} onClose={close}>
                <DialogTitle>Cancel all orders</DialogTitle>
                <DialogContent>Do you want to cancel all orders?</DialogContent>
                <DialogActions>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Button color='info' disabled={disableDelete}
                            onClick={() => {
                                otpModal.show()
                            }}>{!disableDelete ? "Proceed" : <CircularProgress />}</Button>
                    </Box>
                    <Box>
                        <Button variant="dialogClose" onClick={close} disabled={disableDelete}>Close</Button>
                    </Box>
                </DialogActions>
            </Dialog>}
            {visibleSingleOrder && <Dialog open={visibleSingleOrder} onClose={closeSingleOrder}>
                <DialogTitle>Cancel order</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" variantMapping={{body1: "p"}}>
                        Do you want to cancel the following order?
                    </Typography>
                    <Table>
                        <TableRow>
                            <TableCell>Quantity</TableCell>
                            <TableCell>{currentOrder?.quantity}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Price Per Unit</TableCell>
                            <TableCell>{getValue(currentOrder.price_for_unit)}</TableCell>
                        </TableRow>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Button color='info' disabled={disableDelete}
                                onClick={() => {
                                    // setDisableDelete(true)
                                    // handlerCancel(currentOrder.order_id, currentOrder.type, false)
                                    otpModal.show()
                                }}>{!disableDelete ? "Proceed" :
                            <CircularProgress/>}</Button>
                    </Box>
                    <Box>
                        <Button variant="dialogClose" onClick={closeSingleOrder} disabled={disableDelete}>Close</Button>
                    </Box>
                </DialogActions>
            </Dialog>}
            <OTPModal modal={otpModal} confirmText="SUBMIT" message={<><p>To ensure the security of your account, please enter the 6-digit Two-Factor Authentication (2FA) One-Time Password (OTP) code available on the Google Authenticator App<br /></p>

            </>} onConfirm={async (otp) => {
                    setDisableDelete(true)

                    if (visibleSingleOrder && currentOrder) {
                        handlerCancel(otp)([currentOrder.order_id], [currentOrder.type], () => {
                            open?.({ message: 'Order cancelled', type: 'success' })
                        })
                    } else {

                        const orderIds = data.map(el => el.order_id)
                        const orderTypes = data.map(el => el.type)
                        handlerCancel(otp)(orderIds, orderTypes, () => {
                            open?.({ message: 'All orders cancelled', type: 'success' })
                        })
                    }
                }} title="OTP" />
        </>
    )
}