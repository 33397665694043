import React from 'react'
import { Box, Button, Link } from "@mui/material";
import './FullPageFooterLinks.scss'

export function FullPageFooterLinks() {
    return (
        <Box className='full-page-footer-link'>
            <Link underline="none" href="/assets/ENG_230926_Privacy Notice_FIN.pdf" target='_blank'><Button color='info' variant="text">PRIVACY (ENG)</Button></Link>
            <Link underline="none" href="/assets/230926_Informativa Privacy_FIN.pdf" target='_blank'><Button color='info' variant="text">PRIVACY (ITA)</Button></Link>
            {/* <Link underline="none" href="#"><Button color='info' variant="text">FAQ</Button></Link>
            <Link underline="none" href="#"><Button color='info' variant="text">PRESS RELEASE</Button></Link> */}
            <Link underline="none" href="/assets/230905_Disclaimer.pdf" target='_blank'><Button color='info' variant="text">DISCLAIMER</Button></Link>
            <Link underline="none" href="/contacts"><Button color='info' variant="text">CONTACTS</Button></Link>
        </Box>
    );
}