import React, {useEffect} from "react";
import { Typography, Box, Button } from "@mui/material";
import './list.scss'
import {INews} from "shared-libs/src/interfaces";
import { useList, useNavigation } from "@refinedev/core";


export const NewsList: React.FC = () => {
    useEffect(() => {
        document.title = 'Whtexch - News';
    }, []);
    const { show } = useNavigation();
        
    const { data, isLoading, isError } = useList<INews>({
        resource: "news",
    });

    const listData = data?.data ?? [];

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <React.Fragment>
            <Box sx={{displax: 'flex' }} className="page-title-container">
                <Box>
                    <Typography variant="h1">News</Typography>
                    {/* <Typography variant="h2">Sottotiolo</Typography> */}
                </Box>
            </Box>

            <Box id="news-list-container">
                {listData.map((news) => (
                    news.is_public == true ?  
                    <Box key={news.news_id}>
                            <Typography variant="h4">{news.title}</Typography>
                            <Typography variant="body2" variantMapping={{ body1: "p" }}>
                                <Box sx={{
                                    // whiteSpace: 'nowrap',
                                    overflow: 'hidden !important',
                                    maxHeight: 60, textOverflow: 'ellipsis'
                                }} dangerouslySetInnerHTML={{ __html: news?.body }} />
                            </Typography>
                            <Button sx={{float: 'right'}} variant="cta" onClick={(): void => show("news", news.news_id) }>Read More</Button>
                    </Box> : 
                    ''
                ))}                
            </Box>
        </React.Fragment>
    );
};