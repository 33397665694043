import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, TextField, Tooltip, Typography } from '@mui/material'
import { HttpError, useCustomMutation, useModal, useNotification, useOne, useSubscription } from '@refinedev/core'
import { UseCustomMutationReturnType } from '@refinedev/core/dist/hooks/data/useCustomMutation'
import { useEffect, useState } from 'react'
import { IAftOriginalData, IAftPreviewData, IProgressAftPreviewBoxProps, ITradeDownloadPreviewProps, OnlyOwner } from 'shared-libs/src/interfaces'

export const PREVIEW_STATUS_PENDING_USER_REQUEST = 0
export const PREVIEW_STATUS_PENDING = 10
export const PREVIEW_STATUS_WORKING = 20
export const PREVIEW_STATUS_ERROR = 99
export const PREVIEW_STATUS_AVAILABLE = 100


export const ORIGINAL_STATUS_PENDING_APPROVATION = 0
export const ORIGINAL_STATUS_APPROVED = 5
export const ORIGINAL_STATUS_PENDING_WORK = 10
export const ORIGINAL_STATUS_WORKING = 20
export const ORIGINAL_STATUS_ERROR = 99
export const ORIGINAL_STATUS_AVAILABLE = 100


export const useTradeDownloadButton = ({ aftcontractId, dataClient, pendingBuy }: ITradeDownloadPreviewProps) => {

    const { data: aftPreviewData, refetch: refetchPreviewData } = useOne<IAftPreviewData>({
        resource: 'aftpreview',
        id: aftcontractId,

        queryOptions: {
            refetchInterval: 5000,
            enabled: !!aftcontractId &&
                (dataClient?.data?.client_aft_available + dataClient?.data?.client_aft_committed) > 0
        }
    })
    const aftName = dataClient?.data.work_of_ingenuity ? dataClient?.data.work_of_ingenuity : aftcontractId

    const hasNoAvailableUnits = dataClient?.data?.client_aft_available == 0

    // controllo se è l'unico possessore totale
    const onlyOwner = useCustomMutation<OnlyOwner>({});
    const runOnlyOwner = () => onlyOwner.mutate({
        url: '/trading/client/is_only_owner',
        method: 'post',
        errorNotification: false,
        values: {
            aftcontract_id: aftcontractId
        },
    }, {
        onError: ({ message }) => {
            console.error(message)
        }
    });
    useEffect(() => {
        runOnlyOwner()
    }, [])


    const { data: aftOriginalData, refetch: refetchOriginalData } = useOne<IAftOriginalData>({
        resource: 'aftoriginal',
        id: aftcontractId,
        queryOptions: {
            refetchInterval: 5000,
            retry: false,
            enabled: (!!aftcontractId) && !!onlyOwner?.data?.data?.result && dataClient?.data?.client_aft_committed == 0,
            onError: ({ message }) => {
                console.error("testt", message)
            }
        },
    })

    const onlyOwnerWithCommitted = useCustomMutation<OnlyOwner>();
    const runOnlyOwnerWithCommitted = () => onlyOwnerWithCommitted.mutate({
        url: '/trading/client/is_only_owner_with_committed',
        method: 'post',
        errorNotification: false,
        values: {
            aftcontract_id: aftcontractId
        }
    }, {
        onError: ({ message }) => {
            console.error(message)

        }
    });
    useEffect(() => {
        runOnlyOwnerWithCommitted();
    }, [])


    useEffect(() => {
        runOnlyOwner();
        runOnlyOwnerWithCommitted();
    }, [dataClient?.data?.client_aft_available, dataClient?.data?.client_aft_committed])

    const hasPendingOrders = onlyOwner?.data?.data?.result != onlyOwnerWithCommitted?.data?.data?.result && onlyOwnerWithCommitted?.data?.data.result


    const { preview_status } = aftPreviewData?.data?.data || {};
    const { tokenizer_status_id } = aftOriginalData?.data || {};

    const previewButton = () => {


        const actualButton = () => {
            switch (preview_status) {
                case PREVIEW_STATUS_PENDING_USER_REQUEST: return (
                    hasNoAvailableUnits ? <DialogForWhenUserDoesNotHaveUnits /> :
                        <CreateAftPreviewButton onlyOwner={onlyOwner} refetchPreviewData={refetchPreviewData} aftcontractId={aftcontractId} />)
                case PREVIEW_STATUS_PENDING: return <PendingAftPreviewBox />;
                case PREVIEW_STATUS_WORKING: return <ProgressAftPreviewBox refetchPreviewData={refetchPreviewData} aftPreviewData={aftPreviewData} />
                case PREVIEW_STATUS_AVAILABLE: return <>{(aftPreviewData?.data as any)?.files.map(el => {
                    return <AvailableAftPreviewButton aftcontractId={aftcontractId} filename={el} />
                })}</>
                case PREVIEW_STATUS_ERROR: return <ErrorAftPreviewBox error={aftPreviewData?.data?.data.error_description} aftcontract_id={aftcontractId} />;
                default: return <></>
            }
        }
        return { button: actualButton() }

    }

    const originalButton = () => {
        if (hasPendingOrders) {
            return <>
                <Tooltip title="You might have some pending orders. In order to download you first need to cancel them all.">
                    <span>
                        <Button disabled>DOWNLOAD</Button>
                    </span>
                </Tooltip>
            </>
        }
        if (aftOriginalData && !aftOriginalData?.data) {
            return <CreateAFTOriginalButton aftName={aftName} aftcontractId={aftcontractId} refetchOriginalData={refetchOriginalData} onlyOwner={onlyOwner} />
        }
        switch (tokenizer_status_id) {
            case ORIGINAL_STATUS_PENDING_APPROVATION: return <PendingAftOriginalBox />
            case ORIGINAL_STATUS_APPROVED: return <Typography>Download Request Approved</Typography>
            case ORIGINAL_STATUS_PENDING_WORK: return <Typography>Pending Work</Typography>
            case ORIGINAL_STATUS_WORKING:
                return <Typography>{aftOriginalData?.data.tokenizer_status_name} {aftOriginalData?.data.tokenizer_status_percentage} %</Typography>
            case ORIGINAL_STATUS_ERROR: return <ErrorAftOriginaoBox error={aftOriginalData?.data.tokenizer_error_description} />;
            case ORIGINAL_STATUS_AVAILABLE: return <AvailableAftOriginalButton downloaded={!!aftOriginalData?.data.clicked} aftcontractId={aftcontractId} filename={''} aftName={aftName} />
            default: return <></>
        }
    }

    return { preview: { ...previewButton(), data: aftPreviewData }, original: { button: originalButton(), data: aftOriginalData } }
}

const DialogForWhenUserDoesNotHaveUnits = () => {
    const { close, show, visible } = useModal()

    return <>
        <Dialog open={visible} onClose={close}>
            <DialogTitle>Download</DialogTitle>
            <DialogContent>
                <Typography>The preview function is not available as all the units are committed on pending orders.</Typography>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    onClick={close}
                    variant='actionTable'

                >Close</LoadingButton>
            </DialogActions>
        </Dialog>
        <LoadingButton color='warning' onClick={show}>Preview</LoadingButton>
    </>
    return <></>
}


const PendingAftOriginalBox = () => {
    return <Typography>Approval pending. This process might take few days.</Typography>
}

const CreateAFTOriginalButton = ({ onlyOwner, aftcontractId, aftName, refetchOriginalData }) => {

    const [otp, setOtp] = useState('')
    const { mutate: createOriginal } = useCustomMutation({})

    const aftOriginalModal = useModal();

    const { open } = useNotification()
    return <><Dialog open={aftOriginalModal.visible} onClose={aftOriginalModal.close}>
        <DialogTitle>Download</DialogTitle>
        <DialogContent>
            <Typography>WHTEXCH will contact you to complete the procedure. Kindly enter the OTP to submit the request:</Typography>
            <Grid justifyContent={'center'} alignContent={'center'} className='' container>
                <Grid className='' item xs={2}>OTP</Grid>
                <Grid className='' item xs={2}>
                    <TextField variant={"outlined"} size={"small"}
                        inputProps={{
                            type: 'number',
                            pattern: '[0-9]*',
                        }}
                        sx={{
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none'
                            },
                            '& input[type=number]': {
                                MozAppearance: 'textfield'
                            },
                        }
                        }
                        onChange={(event) => {
                            event?.target?.value && setOtp(event?.target?.value)
                        }} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button disabled={onlyOwner.isLoading || false === onlyOwner.data?.data.result} onClick={() => {
                createOriginal({
                    url: '/aftoriginal/request_for_original',
                    method: 'post',
                    values: {
                        aftcontract_id: aftcontractId,
                        otp
                    }
                }, {
                    onSuccess: () => {
                        open?.({ message: 'Trading closed succesfully and request sent', type: 'success' })
                        refetchOriginalData()
                    },

                })
            }}>REQUEST ORIGINAL</Button>


        </DialogActions>
    </Dialog>
        {onlyOwner?.data?.data?.result ? <Button onClick={aftOriginalModal.show}>Original</Button> : null}
    </>
}

const CreateAftPreviewButton = ({ aftcontractId, refetchPreviewData, onlyOwner }: { aftcontractId?: string, refetchPreviewData, onlyOwner: UseCustomMutationReturnType<OnlyOwner, HttpError, {}> }) => {

    const { mutate: createPreview, isLoading: createPreviewIsLoading } = useCustomMutation({})

    const [otp, setOtp] = useState('')

    const aftPreviewModal = useModal({ defaultVisible: false })

    return (<>
        <Dialog open={aftPreviewModal.visible} onClose={aftPreviewModal.close}>
            <DialogTitle>Download</DialogTitle>
            <DialogContent>
                <Grid justifyContent={'center'} alignContent={'center'} className='' container>
                    <Grid className='' item xs={2}>OTP</Grid>
                    <Grid className='' item xs={3}>
                        <TextField variant={"outlined"} size={"small"}
                            inputProps={{
                                type: 'number',
                                pattern: '[0-9]*',
                            }}
                            sx={{
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    display: 'none'
                                },
                                '& input[type=number]': {
                                    MozAppearance: 'textfield'
                                },
                            }
                            }
                            onChange={(event) => {
                                event?.target?.value && setOtp(event?.target?.value)
                            }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant='actionTable'
                    loading={createPreviewIsLoading}
                    onClick={() => {
                        createPreview({
                            url: '/aftpreview/create_preview',
                            method: 'post',
                            values: {
                                aftcontract_id: aftcontractId,
                                otp
                            }
                        }, {
                            onSuccess: () => {
                                refetchPreviewData();
                            }
                        })
                    }}
                >Generate Proxy</LoadingButton>
            </DialogActions>
        </Dialog>

        <Button onClick={aftPreviewModal.show}>Preview</Button></>)
}
const PendingAftPreviewBox = () => {
    return <><Typography>Your preview is currently queued</Typography></>
}
const ProgressAftPreviewBox = (props: IProgressAftPreviewBoxProps) => {

    const { tokenizer_status_name, tokenizer_status_percentage } = props?.aftPreviewData?.data?.data || {}
    return <Typography>Status: {tokenizer_status_name}  progress: {tokenizer_status_percentage} %</Typography>
}
const AvailableAftPreviewButton = ({ aftcontractId, filename }) => {

    const { mutate: download } = useCustomMutation({})


    return <Button onClick={() => {
        download({
            url: `/aftpreview/get_contents`,
            method: "post",
            values: {
                aftcontract_id: aftcontractId,
                filename
            }
        }, {
            onSuccess: ({ data }) => {
                saveData(data, filename)
            }
        })
    }}>{filename}</Button>
}
const ErrorAftPreviewBox = ({ error, aftcontract_id }) => {

    const { mutate: invalidate, isLoading } = useCustomMutation()
    const { open } = useNotification()
    return <Typography>
        Error: {error}
        <Button disabled={isLoading} onClick={() => {
            invalidate({
                url: '/aftpreview/invalidate_preview',
                method: 'post',
                values: {
                    aftcontract_id
                }
            }, {
                onSuccess: () => {
                    open?.({ message: 'Preview restored', type: 'success' })
                }
            })
        }}>Restore and retry</Button>
    </Typography>
}


const ErrorAftOriginaoBox = ({ error }) => {

    return <Typography>Error: {error}</Typography>
}




const AvailableAftOriginalButton = ({ aftcontractId, filename, aftName, downloaded }) => {

    const { mutate: download, isLoading } = useCustomMutation({})
    const { mutate: markAsDownloaded, ...markAsDownloadedMutation } = useCustomMutation({})
    const { mutate: backupGetInfo, ...backupGetInfoMutation } = useCustomMutation({})
    const [backupLink, setBackupLink] = useState('');
    const [canMarkAsDownloaded, setCanMarkAsDownloaded] = useState(false)


    const [otp, setOtp] = useState('');
    const { open } = useNotification()
    const [isDownloaded, setDownloaded] = useState(downloaded);
    const [downloadedError, setDownloadedError] = useState(false);
    const markAsDownloadedModal = useModal();

    const clickedDownload = () => {
        markAsDownloaded({
            url: '/aftoriginal/clicked',
            method: 'post',
            values: {
                aftcontract_id: aftcontractId
            }
        }, {
            onSuccess: () => {
                setCanMarkAsDownloaded(true)
            }
        })
    }


    const markAsDownloadedButton = isDownloaded ? <><LoadingButton disabled={!canMarkAsDownloaded} loading={markAsDownloadedMutation.isLoading} variant='contained' onClick={() => {
        markAsDownloadedModal.show();
    }}>Mark as Downloaded</LoadingButton>
        <Dialog open={markAsDownloadedModal.visible} onClose={markAsDownloadedModal.close}>
            <DialogTitle>Mark as Downloaded</DialogTitle>
            <DialogContent>
                <Grid justifyContent={'center'} alignContent={'center'} className='' container>
                    <Grid>Kindly be aware that once you "Mark as Downloaded" you will not be able to further download the <strong>{aftName}</strong>. </Grid>
                    <Grid className='' item xs={2}>OTP</Grid>
                    <Grid className='' item xs={3}>
                        <TextField variant={"outlined"} size={"small"}
                            inputProps={{
                                type: 'number',
                                pattern: '[0-9]*',
                            }}
                            sx={{
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                    display: 'none'
                                },
                                '& input[type=number]': {
                                    MozAppearance: 'textfield'
                                },
                            }
                            }
                            onChange={(event) => {
                                event?.target?.value && setOtp(event?.target?.value)
                            }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant='actionTable'
                    loading={markAsDownloadedMutation.isLoading}
                    onClick={() => {
                        markAsDownloaded({
                            url: '/aftoriginal/mark_as_downloaded',
                            method: 'post',
                            values: {
                                aftcontract_id: aftcontractId,
                                otp
                            }
                        }, {
                            onSuccess: () => {
                                markAsDownloadedModal.close()
                            }
                        })
                    }}
                >Confirm</LoadingButton>
            </DialogActions>
        </Dialog></>
        : null


    return <>
        <Grid container direction={'column'}>
            <Grid className='' item xs={2}>
                <strong>{aftName}</strong> is now available:&nbsp;
                <p><LoadingButton loading={isLoading} variant='contained' onClick={() => {
            download({
                url: `/aftoriginal/download`,
                method: "post",

                values: {
                    aftcontract_id: aftcontractId,
                    filename: ''
                }
            }, {
                onSuccess: ({ data }) => {
                    setDownloaded(true);
                    saveData(data, aftcontractId + '.png')
                },
                onError: ({ message }) => {
                    setDownloadedError(true);
                    open?.({ message, type: 'error' })
                }
            })
                }}>DOWNLOAD</LoadingButton></p>

            </Grid>
            <Grid>
                <strong>Certificate of Ownership</strong>: To receive the certificate of ownership you are kindly request to collect the emergency backup
                <p><LoadingButton disabled={!!backupLink} loading={backupGetInfoMutation.isLoading} variant='contained' onClick={() => {
                    backupGetInfo({
                        url: '/aftoriginal/get_image_backup_info',
                        method: 'post',
                        values: {
                            aftcontract_id: aftcontractId
                        }
                    }, {
                        onSuccess: ({ data: { result } }) => {
                            setBackupLink(result)
                            setCanMarkAsDownloaded(true);
                        }
                    })
                }}>BACKUP</LoadingButton></p>
            </Grid>
            {backupLink ? <Grid>
                Please click <a href={backupLink} onClick={() => clickedDownload()} target='_blank'>HERE</a> to download the secure encrypted backup. Kindly follow the instruction received by email to decrypt this file.

                Once completed the decryption, please click {markAsDownloadedButton}.

                Kindly be aware that once you "Mark as Downloaded" you will not be able to further download the <strong>{aftName}</strong>

            </Grid> : null}
        </Grid>




        {/* {(downloaded || downloadedError) ? <>
            {backupLink ? <p>

                Please use this <a href={backupLink} onClick={() => clickedDownload()} target='_blank'>link</a> to download a secure encrypted backup we stored.
                An automatically generated password has been sent to your email and this is the only way to decrypt this file.
            </p> : null}<p>
                To receive the certificate of ownership you are kindly request to collect the emergency &nbsp;
                <LoadingButton disabled={!!backupLink} loading={backupGetInfoMutation.isLoading} variant='contained' onClick={() => {
                    backupGetInfo({
                        url: '/aftoriginal/get_image_backup_info',
                        method: 'post',
                        values: {
                            aftcontract_id: aftcontractId
                        }
                    }, {
                        onSuccess: ({ data: { result } }) => {
                            setBackupLink(result)
                        }
                    })
                }}>BACKUP</LoadingButton>&nbsp;
                and, upon completion of the procedures, to&nbsp; {markAsDownloadedButton}

                <Typography>
                    Kindly be aware that once you ""Mark as Downloaded"" you will not be able to further download the <strong>{aftName}</strong>.
                </Typography>
            </p>
        </> : null} */}

    </>
}


const strToBlob = (byteCharacters, contentType = '', sliceSize = 512) => {
    const byteArrays: Uint8Array[] = [];



    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}


const saveData = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    //@ts-ignore
    a.style = "display: none";
    return function (blob, fileName) {
        // const blob = strToBlob(data, 'image/png'),// new Blob([data], { type: 'image/jpeg' }),
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
}());